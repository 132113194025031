<template>
  <div class="font-Roboto bg-white w-full z-20 2xl:max-w-7xl 2xl:mx-auto">
    <!-- <div class="relative bg-indigo-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p class="font-medium text-white">
            <span class="md:hidden"> We announced a new product! </span>
            <span class="hidden md:inline">
              The Site is underconstruction you are not able to purchase.you can
              register, login and search you favourite games and consoles
            </span>
          </p>
        </div>
      </div>
    </div> -->
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 md:py-0">
      <div class="flex items-center justify-between md:justify-start">
        <!-- Menu Trigger -->
        <!-- <button
          type="button"
          class="w-10 h-10 rounded-lg -ml-2 flex justify-center items-center"
          @click="toggleSidebarFn"
        >
          <svg
            class="text-gray-500 w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button> -->
        <!-- ./ Menu Trigger -->

        <router-link
          href="#"
          id="headerBrandId"
          class="font-bold text-gray-700 text-xs"
          to="/"
        >
          <img
            :src="MainLogo"
            alt=""
            class="md:h-20 xl:h-24 w-auto object-cover h-16 sm:w-auto sm:h-24"
          />
        </router-link>

        <div class="md:flex space-x-3 flex-1 lg:ml-8">
          <div class="relative hidden md:block">
            <div>
              <!-- <label for="price" class="block text-sm font-medium text-gray-700">Price</label> -->
              <div class="relative mt-1 rounded-md shadow-sm">
                <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                      </div> -->
                <input
                  id="headerSearchInput"
                  type="text"
                  name="searchGlobal"
                  v-model="globalSearchText"
                  v-on:keyup.enter="handleGlobalSearch"
                  class="pl-10 pr-2 h-10 md:w-96 text-sm rounded-lg text-gray-600 border border-gray-200 focus:border-gray-300 focus:outline-none focus:shadow-inner leading-none"
                  :placeholder="HEADER_SEARCH_INPUT_PLACEHOLDER"
                />
              </div>
            </div>
            <svg
              class="h-6 w-6 text-gray-300 ml-2 mt-2 stroke-current absolute top-0 left-0"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>

        <div class="flex items-center space-x-6">
          <!-- <router-link
            href="#"
            class="flex h-10 items-center px-2 rounded-full focus:outline-none decoration-none hover:decoration-none text-black relative"
            :to="{ name: 'notifications', query: { userId: 1 } }"
          >
            <svg
              width="24"
              height="24"
              viewBox="-2 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6466 31.9999C16.1714 31.9999 18.2181 29.9532 18.2181 27.4285C18.2181 24.9038 16.1714 22.8571 13.6466 22.8571C11.1219 22.8571 9.0752 24.9038 9.0752 27.4285C9.0752 29.9532 11.1219 31.9999 13.6466 31.9999Z"
                fill="#FF7700"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.1892 6.17181C19.8652 4.84783 18.3669 3.94466 16.6944 3.46231V3.04762C16.6944 2.20605 16.3968 1.48771 15.8018 0.892626C15.2066 0.297542 14.4883 0 13.6467 0C12.8052 0 12.0868 0.297542 11.4917 0.892626C10.8967 1.48771 10.5991 2.20605 10.5991 3.04762V3.46231C8.92652 3.94466 7.42824 4.84783 6.10426 6.17181C4.02147 8.2546 2.98007 10.7688 2.98007 13.7143V21.9429L0.491411 24.9292C0.302152 25.1564 0.184215 25.4165 0.137601 25.7097C0.108531 26.0029 0.156694 26.2834 0.282088 26.5512C0.407483 26.8188 0.594914 27.032 0.844382 27.1906C1.09385 27.3492 1.3664 27.4286 1.66203 27.4286H25.6314C25.927 27.4286 26.1996 27.3492 26.4491 27.1906C26.6986 27.032 26.886 26.8188 27.0114 26.5512C27.1368 26.2834 27.1806 26.0029 27.1427 25.7097C27.1048 25.4165 26.9912 25.1564 26.802 24.9292L24.3134 21.9429V13.7143C24.3134 10.7688 23.272 8.2546 21.1892 6.17181Z"
                fill="url(#paint0_radial_103_1590)"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_103_1590"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(9.12995 7.54174) rotate(53.71) scale(21.2682 20.9501)"
                >
                  <stop stop-color="#FADF73" />
                  <stop offset="0.457142" stop-color="#FFD500" />
                  <stop offset="1" stop-color="#FC9900" />
                </radialGradient>
              </defs>
            </svg>
            <span
              class="pl-1 text-gray-500 text-md absolute top-0 -right-2 text-xs"
              >115</span
            >
          </router-link> -->
          <div
            class="ml-auto hidden sm:flex flex-col place-items-end p-4"
            v-if="companyDetails && companyDetails.customerSupportContact"
          >
            <dd class="flex text-base text-black items-center">
              <!-- Heroicon name: outline/phone -->
              <svg
                class="flex-shrink-0 w-8 h-8 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <div class="flex flex-col ml-2">
                <span class="text-sm">Customer Support</span>
                <span
                  class="ml-2 text-md font-semibold hover:animate-none font-roboto items-start tracking-widest animate__animated animate__bounce animate__infinite"
                  >{{ companyDetails.customerSupportContact }}</span
                >
              </div>
            </dd>
          </div>
          <div class="relative">
            <router-link
              :to="{ name: 'CART' }"
              href="#"
              class="group -m-2 p-2 flex items-center relative hover:cursor-pointer"
            >
              <!-- Heroicon name: outline/shopping-bag -->
              <svg
                class="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              <span
                class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800"
                >{{ itemCount }}</span
              >
              <span class="sr-only">items in cart, view bag</span>
            </router-link>
          </div>
          <!--<transition
                  enter-active-class="animate__animated animate__slideInDown animate__faster"
                  leave-active-class="animate__animated animate__slideOutUp animate__faster"
                > 
           </transition> 
           -->

          <button
            type="button"
            class="md:block w-8 h-8 flex justify-center items-center relative"
            id="navBarUserProfileBtn"
            :class="{ 'z-50': toggleProfileDropdown }"
            @click.prevent="toggleDropdownVisibility('profileDropdown')"
          >
            <div
              class=""
              v-if="
                !this.isUserLoggedIn ||
                (this.userdetails && !this.userdetails.profileImageUrl)
              "
            >
              <img :src="defaultProfileImage" alt="default profile image" />
              <!-- <svg
                class="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <img src="" alt="">
            --></div>

            <img
              v-else
              :src="this.userdetails.profileImageUrl"
              alt="bottts"
              class="rounded-full h-full w-full object-cover border"
              id="headerUserProfileImage"
            />
            <div class="mt-4" :class="{ 'z-50': toggleProfileDropdown }">
              <!-- Dropdown menu -->
              <transition
                enter-active-class="animated 
                bounceIn faster"
                leave-active-class="animate__animated animate__bounceOut animate__faster"
              >
                <UserDropdown
                  v-if="toggleProfileDropdown"
                  :toggleProfileDropdown="toggleProfileDropdown"
                />
              </transition>
            </div>
          </button>
        </div>
      </div>

      <!-- Search Mobile -->
      <div class="relative md:hidden">
        <input
          type="search"
          class="mt-1 w-full pl-10 pr-2 h-10 py-1 rounded-lg border border-gray-200 focus:border-gray-300 focus:outline-none focus:shadow-inner leading-none"
          placeholder="Search"
          v-on:keyup.enter="handleGlobalSearch"
          v-model="globalSearchText"
        />

        <svg
          class="h-6 w-6 text-gray-300 ml-2 mt-3 stroke-current absolute top-0 left-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>

      <appOverlay
        :isOverlayActive="isOverlayActive"
        @overlayClicked="handleOverlayClick"
      />
      <!-- ./ Search Mobile -->
    </div>
    <appHeaderDropdown />
  </div>
</template>

<script>
import MainLogo from "@/assets/icons/main.png";
import useCartStore from "@/store/cart.store";
import useProductStore from "@/store/products.store";
import useCompanyStore from "@/store/company.store";
import defaultProfileImage from "@/assets/img/defaultprofile.png";
import { mapActions, mapState, mapWritableState } from "pinia";
import useAuthStore from "@/store/auth.store";
import { HEADER_SEARCH_INPUT_PLACEHOLDER } from "@/assets/Constants";
import UserDropdown from "@/components/Commons/Dropdowns/User.header.dropdown.vue";
export default {
  computed: {
    ...mapWritableState(useAuthStore, [
      "isUserLoggedIn",
      "userdetails",
      "toggleSidebar",
    ]),
    ...mapState(useCompanyStore, ["companyDetails"]),
    ...mapState(useCartStore, ["itemCount"]),
    userProfileImage() {
      return this.isUserLoggedIn && this.userdetails.profileImageUrl
        ? this.userdetails.profileImageUrl
        : this.defaultProfileImage;
    },
  },
  data() {
    return {
      HEADER_SEARCH_INPUT_PLACEHOLDER,
      toggleProfileDropdown: false,
      defaultProfileImage,
      isOverlayActive: false,
      globalSearchText: "",
      MainLogo,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["signOutAction"]),
    ...mapActions(useProductStore, ["globalSearchResultAction"]),
    toggleDropdownVisibility(menuName) {
      if (menuName) {
        switch (menuName) {
          case "profileDropdown":
            this.isOverlayActive = !this.isOverlayActive;
            this.toggleProfileDropdown = !this.toggleProfileDropdown;
            break;
        }
      }
    },
    async handleGlobalSearch() {
      this.$router.push({
        name: "SEARCH_RESULTS",
        query: { searchText: this.globalSearchText },
      });
    },
    handleOverlayClick() {
      this.isOverlayActive = false;
      this.toggleProfileDropdown = false;
      this.toggleSidebar = false;
    },
    toggleSidebarFn() {
      this.toggleSidebar = !this.toggleSidebar;
      if (this.toggleSidebar) {
        this.isOverlayActive = true;
      }
    },
  },
  components: {
    UserDropdown,
  },
};
</script>

<style></style>
