<template>
  <div
    class="relative z-10 bg-white border-b border-gray-200 pb-4"
    :class="{ hidden: !isActive, 'z-50': isActive }"
  >
    <div
      class="max-w-7xl mx-auto px-4 flex items-center justify-between sm:px-6 lg:px-8"
    >
      <div class="relative inline-block text-left">
        <!--
                Dropdown menu, show/hide based on menu state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
        <div
          class="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <!--
                    Active: "bg-gray-100", Not Active: ""

                    Selected: "font-medium text-gray-900", Not Selected: "text-gray-500"
                  -->
            <a
              href="#"
              class="font-medium text-gray-900 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              Most Popular
            </a>

            <a
              href="#"
              class="text-gray-500 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
            >
              Best Rating
            </a>

            <a
              href="#"
              class="text-gray-500 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-2"
            >
              Newest
            </a>

            <router-link
              :to="{
                name: 'SEARCH_RESULTS',
                query: { sortBy: 'productSellingPrice', sortOrder: 'asc' },
              }"
              href="#"
              class="text-gray-500 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-3"
            >
              Price: Low to High
            </router-link>

            <a
              href="#"
              class="text-gray-500 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-4"
            >
              Price: High to Low
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  created() {},
};
</script>

<style></style>
