<template>
  <div class="space-y-6 bg-white py-4">
    <vee-form
      :validation-schema="productValidationSchema"
      @submit="saveNewProductDetails"
    >
      <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
        <h2 class="font-semibold text-3xl py-12 text-center font-archivo">
          Create New Product
        </h2>
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="mainImageUrl"
                  class="block text-sm font-medium text-gray-700"
                  >Product Main Cover Image</label
                >
                <vee-field
                  type="text"
                  name="mainImageUrl"
                  id="mainImageUrl"
                  autocomplete="family-name"
                  :placeholder="ADD_PRODUCT_FORM_IMAGE_URL"
                  v-model="product.mainImageUrl"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="mainImageUrl"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="productName"
                  class="block text-sm font-medium text-gray-700"
                  >Product Name</label
                >
                <vee-field
                  type="text"
                  name="productName"
                  id="productName"
                  autocomplete="family-name"
                  :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                  v-model="product.productName"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="productName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="productPrice"
                  class="block text-sm font-medium text-gray-700"
                  >Product Price
                </label>
                <vee-field
                  type="text"
                  name="productPrice"
                  id="productPrice"
                  v-model="product.productPrice"
                  :placeholder="ADD_PRODUCT_FORM_PRICE_PLACEHOLDER"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="productPrice"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="productSellingPrice"
                  class="block text-sm font-medium text-gray-700"
                  >Product Selling Price
                </label>
                <vee-field
                  type="text"
                  name="productSellingPrice"
                  id="productSellingPrice"
                  autocomplete="family-name"
                  v-model="product.productSellingPrice"
                  :placeholder="ADD_PRODUCT_FORM_PRICE_PLACEHOLDER"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="productSellingPrice"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="productPriceDiscount"
                  class="block text-sm font-medium text-gray-700"
                  >Quantity
                </label>
                <vee-field
                  type="text"
                  name="quantity"
                  id="quantity"
                  autocomplete="family-name"
                  v-model="product.quantity"
                  :placeholder="ADD_PRODUCT_FORM_PRICE_PLACEHOLDER"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="quantity" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="productType"
                  class="block text-sm font-medium text-gray-700"
                  >Product Type</label
                >
                <vee-field
                  as="select"
                  name="productType"
                  id="productType"
                  v-model="productType"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="Games">Games</option>
                  <option value="Consoles">Consoles</option>
                  <option value="Accessories">Accessories</option></vee-field
                >
                <ErrorMessage name="productType" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="categoryId"
                  class="block text-sm font-medium text-gray-700"
                  >Product Category</label
                >
                <vee-field
                  as="select"
                  id="categoryId"
                  name="categoryId"
                  v-model="product.categoryId"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="category in categories"
                    :key="category"
                    :value="category.categoryId"
                    v-show="category.categoryName.includes(productType)"
                  >
                    {{ category.categoryName }}
                  </option>
                  <ErrorMessage
                    name="categoryId"
                    class="text-red-500 text-xs"
                  />
                </vee-field>
              </div>
              <div class="col-span-6">
                <label
                  for="brandId"
                  class="block text-sm font-medium text-gray-700"
                  >Product Brand</label
                >
                <vee-field
                  as="select"
                  id="brandId"
                  name="brandId"
                  v-model="product.brandId"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="brand in brands"
                    :key="brand.brandId"
                    :value="brand.brandId"
                  >
                    {{ brand.brandName }}
                  </option>
                </vee-field>
                <ErrorMessage name="brandId" class="text-red-500 text-xs" />
              </div>
              <!-- <div class="col-span-6">
                <label
                  for="status"
                  class="block text-sm font-medium text-gray-700"
                  >Status</label
                >
                <vee-field
                  as="select"
                  id="status"
                  name="status"
                  v-model="product.status"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="stat in status"
                    :key="stat.statusId"
                    :value="stat.statusId"
                  >
                    {{ stat.statusName }}
                  </option>
                </vee-field>
                <ErrorMessage name="status" class="text-red-500 text-xs" />
              </div> -->
              <div class="col-span-6">
                <label
                  for="genre"
                  class="block text-sm font-medium text-gray-700"
                  >Genre</label
                >
                <vee-field
                  as="select"
                  id="genre"
                  name="genre"
                  v-model="product.genre"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="gen in genre"
                    :key="gen.genreId"
                    :value="gen.genreId"
                  >
                    {{ gen.genreName }}
                  </option>
                </vee-field>
                <ErrorMessage name="genre" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="condition"
                  class="block text-sm font-medium text-gray-700"
                  >Product Condition</label
                >
                <vee-field
                  as="select"
                  name="condition"
                  id="condition"
                  v-model="product.condition"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="NEW">New</option>
                  <option value="PREOWNED">Preowned</option>
                  <option value="MINT">Mint</option>
                  <option value="PREORDER">Preorder</option></vee-field
                >
                <ErrorMessage name="condition" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="shortDescription"
                  class="block text-sm font-medium text-gray-700"
                  >Short Description
                  <span class="ml-2 text-xs text-yellow-500">
                    You Have Entered
                    {{ product.shortDescription.length }} Characters.</span
                  ></label
                >
                <div class="mt-1">
                  <vee-field
                    as="textarea"
                    rows="4"
                    v-model="product.shortDescription"
                    name="shortDescription"
                    id="shortDescription"
                    class="shadow-sm border py-2 px-3 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md"
                  />

                  <ErrorMessage
                    name="shortDescription"
                    class="text-red-500 text-xs"
                  />
                </div>
              </div>

              <div class="col-span-6">
                <label
                  for="fullDescription"
                  class="block text-sm font-medium text-gray-700"
                  >Full Description<span class="ml-2 text-xs text-yellow-500">
                    You Have Entered
                    {{ product.fullDescription.length }} Characters.</span
                  ></label
                >
                <div class="mt-1">
                  <vee-field
                    as="textarea"
                    rows="4"
                    v-model="product.fullDescription"
                    name="fullDescription"
                    id="fullDescription"
                    class="shadow-sm border py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="fullDescription"
                    class="text-red-500 text-xs"
                  />
                </div>
              </div>
              <div class="col-span-6">
                <label
                  for="galleryImage1Url"
                  class="block text-sm font-medium text-gray-700"
                  >Gallery Image 1</label
                >
                <vee-field
                  type="text"
                  name="galleryImage1Url"
                  id="galleryImage1Url"
                  :placeholder="ADD_PRODUCT_FORM_IMAGE_URL"
                  v-model="product.galleryImage1Url"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="galleryImage1Url"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="galleryImage2Url"
                  class="block text-sm font-medium text-gray-700"
                  >Gallery Image 2</label
                >
                <vee-field
                  type="text"
                  name="galleryImage2Url"
                  id="galleryImage2Url"
                  :placeholder="ADD_PRODUCT_FORM_IMAGE_URL"
                  v-model="product.galleryImage2Url"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="galleryImage2Url"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="galleryImage3Url"
                  class="block text-sm font-medium text-gray-700"
                  >Gallery Image 3</label
                >
                <vee-field
                  type="text"
                  name="galleryImage3Url"
                  id="galleryImage3Url"
                  autocomplete="family-name"
                  :placeholder="ADD_PRODUCT_FORM_IMAGE_URL"
                  v-model="product.galleryImage3Url"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="galleryImage3Url"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="galleryImage4Url"
                  class="block text-sm font-medium text-gray-700"
                  >Gallery Image 4</label
                >
                <vee-field
                  type="text"
                  name="galleryImage4Url"
                  id="galleryImage4Url"
                  :placeholder="ADD_PRODUCT_FORM_IMAGE_URL"
                  v-model="product.galleryImage4Url"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="galleryImage4Url"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="releasedate"
                  class="block text-sm font-medium text-gray-700"
                  >Release Date</label
                >
                <vee-field
                  type="text"
                  name="releasedate"
                  id="releasedate"
                  :placeholder="PRODUCT_RELEASE_DATE"
                  v-model="product.releasedate"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="releasedate" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="warranty"
                  class="block text-sm font-medium text-gray-700"
                  >Warranty</label
                >
                <vee-field
                  as="select"
                  name="warranty"
                  id="warranty"
                  :placeholder="PRODUCT_WARRANTY"
                  v-model="product.warranty"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="SELLAR_WARRANTY">Sellar Warranty</option>
                  <option value="COMPANY_WARRANTY">Company Warranty</option>
                  <option value="NO_WARRANTY">No Warranty</option></vee-field
                >
                <ErrorMessage name="warranty" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="manufacturer"
                  class="block text-sm font-medium text-gray-700"
                  >Manufacturer</label
                >
                <vee-field
                  type="text"
                  name="manufacturer"
                  id="manufacturer"
                  :placeholder="PRODUCT_MANUFACTURERS"
                  v-model="product.manufacturer"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="manufacturer"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="language"
                  class="block text-sm font-medium text-gray-700"
                  >Language</label
                >
                <vee-field
                  type="text"
                  name="language"
                  id="language"
                  :placeholder="PRODUCT_LANGUAGE"
                  v-model="product.language"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="language" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="productdimensions"
                  class="block text-sm font-medium text-gray-700"
                  >Product Dimensions</label
                >
                <vee-field
                  type="text"
                  name="productdimensions"
                  id="productdimensions"
                  :placeholder="PRODUCT_DIMENSIONS"
                  v-model="product.productdimensions"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="productdimensions"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="tags"
                  class="block text-sm font-medium text-gray-700"
                  >Product Tags</label
                >
                <vee-field
                  type="text"
                  name="tags"
                  id="tags"
                  placeholder="eg: GTA5, GTA V,GTA V,Grand Theft Auto 5 which are useful while searching the products"
                  v-model="product.tags"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="tags" class="text-red-500 text-xs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          :disabled="isSubmitBtnDisabled"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import useCategoriesStore from "@/store/categories.store";
import useBrandStore from "@/store/brands.store";
import useProductsStore from "@/store/products.store";
import useStatusStore from "@/store/status.store";
import useGenreStore from "@/store/genre.store";
import useToastStore from "@/store/toast.store";
import { mapActions, mapState } from "pinia";
import {
  ADD_PRODUCT_NAME_PLACEHOLDER,
  ADD_PRODUCT_FORM_PRICE_PLACEHOLDER,
  ADD_PRODUCT_FORM_IMAGE_URL,
  PRODUCT_DIMENSIONS,
  PRODUCT_LANGUAGE,
  PRODUCT_MANUFACTURERS,
  PRODUCT_RELEASE_DATE,
  PRODUCT_WARRANTY,
} from "@/assets/Constants";
export default {
  computed: {
    ...mapState(useCategoriesStore, ["categories"]),
    ...mapState(useBrandStore, ["brands"]),
    ...mapState(useStatusStore, ["status"]),
    ...mapState(useGenreStore, ["genre"]),
  },
  methods: {
    ...mapActions(useProductsStore, [
      "saveNewProductDetailsActions",
      "fetchAllProductDetailsAction",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    async saveNewProductDetails(values) {
      try {
        this.isSubmitBtnDisabled = true;
        let response = await this.saveNewProductDetailsActions(this.product);
        this.success(
          `Successfully Saved New Product ${this.product.productName} `
        );
        this.product = {
          productCoverImageUrl: "",
          productName: "",
          productPrice: 0,
          genre: null,
          productSellingPrice: 0,
          fullDescription: "",
          shortDescription: "",
          mainImageUrl: "",
          categoryId: "",
          brandId: "",
          quantity: 0,
          galleryImage1Url: "",
          galleryImage2Url: "",
          galleryImage3Url: "",
          galleryImage4Url: "",
          releasedate: "",
          warranty: "",
          manufacturer: "",
          language: "",
          productdimensions: "",
          tags: "",
        };
        this.isSubmitBtnDisabled = false;
      } catch (err) {
        this.error(err.message);
        this.isSubmitBtnDisabled = false;
      }
    },
  },
  data() {
    return {
      isFilesDragged: false,
      isImageDragged: false,
      productType: "",
      productValidationSchema: {
        mainImageUrl: "required|min:1|url",
        productName: "required|min:5|max:200",
        productPrice: "required|minValue:1|maxValue:10000000",
        productSellingPrice: "required|numeric",
        fullDescription: "required|min:100|max:4096",
        shortDescription: "required|min:100|max:2048",
        quantity: "required|min:1|maxValue:100000000",
        categoryId: "required",
        condition: "required",
        type: "",
        brandId: "",
        galleryImage1Url: "required|min:1|url",
        galleryImage2Url: "required|min:1|url",
        galleryImage3Url: "required|min:1|url",
        galleryImage4Url: "required|min:1|url",
        releasedate: "required|min:5|max:100",
        warranty: "required|min:5|max:100",
        manufacturer: "required|min:5|max:100",
        language: "required|min:5|max:100",
        productdimensions: "required|min:5|max:100",
        tags: "required",
      },
      product: {
        productName: "",
        productPrice: 0,
        status: "",
        type: "",
        note: "",
        productSellingPrice: 0,
        fullDescription: "",
        shortDescription: "",
        mainImageUrl: "",
        categoryId: "",
        brandId: "",
        quantity: 0,
        galleryImages: [],
        condition: "",
        galleryImage1Url: "",
        galleryImage2Url: "",
        galleryImage3Url: "",
        galleryImage4Url: "",
        createdAt: "",
        updatedBy: "",
        updatedAt:
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null,
        createdAt: new Date(),
        releasedate: "",
        warranty: "",
        manufacturer: "",
        language: "",
        productdimensions: "",
        tags: "",
      },
      products: [],
      isSubmitBtnDisabled: false,
      ADD_PRODUCT_NAME_PLACEHOLDER,
      ADD_PRODUCT_FORM_PRICE_PLACEHOLDER,
      ADD_PRODUCT_FORM_IMAGE_URL,
      previewMainImageUrl: "",
      PRODUCT_DIMENSIONS,
      PRODUCT_LANGUAGE,
      PRODUCT_MANUFACTURERS,
      PRODUCT_RELEASE_DATE,
      PRODUCT_WARRANTY,
    };
  },
  watch: {
    productType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.product.categoryId = null;
        this.product.type = newVal;
      }
    },
  },
};
</script>

<style></style>
