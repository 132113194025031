import { invoiceCollection } from "@/firebase/firebase.config";
export const saveNewInvoiceAPI = async (invoice) => {
  return await invoiceCollection.doc(invoice.invoiceId).set(invoice);
};

export const getInvoiceByInvoiceIdAPI = async (invoiceId) => {
  return await invoiceCollection.doc(invoiceId).get();
};

export const getPaginatedInvoiceAPI = async (
  userId,
  lastDoc,
  sortBy = "createdAt",
  sortOrder = "asc",
  limit = 10,
  startDate,
  endDate
) => {
  let query = invoiceCollection;
  if (userId) {
    query = query.where("createdBy", "==", userId);
  }
  if (sortBy && sortOrder) {
    query = query.orderBy(sortBy, sortOrder);
  }
  if (lastDoc) {
    query = query.startAfter(lastDoc);
  }
  if (limit) {
    query = query.limit(limit);
  }

  return await query.get();
};

export const getInvoiceByDateAPI = async (userId, startTime, endTime) => {
  let query = invoiceCollection;

  if (userId) {
    query = query.where("createdBy", "==", userId);
  }
  if (startTime && endTime) {
    query = query
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<", endTime);
  }
  return await query.get();
};

export const deleteInvoiceByInvoiceIdAPI = async (invoiceId) => {
  return await invoiceCollection.doc(invoiceId).delete();
};

export const invoiceByInvoiceIDAPI = async (invoice_id) => {
  return await invoiceCollection.doc(invoice_id).get();
};
