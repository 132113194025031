<template>
  <Transition
    enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
  >
    <div
      class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
    >
      <div class="pointer-events-auto w-screen max-w-md">
        <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
          <div class="px-4 py-6 sm:px-6">
            <div class="flex items-start justify-between">
              <h2
                id="slide-over-heading"
                class="text-lg font-medium text-gray-900"
              >
                Profile
              </h2>
              <div class="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close panel</span>
                  <!-- Heroicon name: outline/x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <!-- Main -->
          <div>
            <div class="pb-1 sm:pb-6">
              <div>
                <div class="relative h-40 sm:h-56">
                  <img
                    class="absolute h-full w-full object-cover"
                    :src="userdetails.profileImageUrl"
                    alt=""
                  />
                </div>
                <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                  <div class="sm:flex-1">
                    <div>
                      <div class="flex items-center">
                        <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">
                          {{ userdetails.username }}
                        </h3>
                        <span
                          class="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400"
                        >
                          <span class="sr-only">Online</span>
                        </span>
                      </div>
                      <p class="text-sm text-gray-500">
                        {{ userdetails.userId }}
                      </p>
                    </div>
                    <div
                      class="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3"
                    >
                      <button
                        type="button"
                        class="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-red-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:flex-1"
                      >
                        Block
                      </button>
                      <button
                        type="button"
                        class="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-green-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:flex-1"
                      >
                        Unblock
                      </button>
                      <button
                        type="button"
                        class="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Edit
                      </button>
                      <span class="ml-3 inline-flex sm:ml-0">
                        <div class="relative inline-block text-left">
                          <button
                            type="button"
                            class="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            id="options-menu-button"
                            aria-expanded="false"
                            aria-haspopup="true"
                          >
                            <span class="sr-only">Open options menu</span>
                            <!-- Heroicon name: solid/dots-vertical -->
                            <svg
                              class="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                              />
                            </svg>
                          </button>

                          <!--
                      Dropdown panel, show/hide based on dropdown state.

                      Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    -->
                          <div
                            class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu-button"
                            tabindex="-1"
                          >
                            <div class="py-1" role="none">
                              <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                              <a
                                href="#"
                                class="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem"
                                tabindex="-1"
                                id="options-menu-item-0"
                                >View profile</a
                              >
                              <a
                                href="#"
                                class="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem"
                                tabindex="-1"
                                id="options-menu-item-1"
                                >Copy profile link</a
                              >
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
              <dl class="space-y-8 px-4 sm:space-y-6 sm:px-6">
                <div>
                  <dt
                    class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                  >
                    About
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                    <p>{{ userdetails.about }}</p>
                  </dd>
                </div>
                <div>
                  <dt
                    class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                  >
                    Location
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                    {{ userdetails.meta.creationTime }}
                  </dd>
                </div>
                <div>
                  <dt
                    class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                  >
                    Website
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                    {{ userdetails.emailId }}
                  </dd>
                </div>
                <div>
                  <dt
                    class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                  >
                    Birthday
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                    <time datetime="1988-06-23"> June 23, 1988 </time>
                  </dd>
                </div>
                <div>
                  <dt
                    class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
                  >
                    Role
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                    {{ userdetails.meta.lastSignInTime }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div></Transition
  >
</template>

<script>
export default {
  props: {
    userdetails: {
      type: Object,
      default: {
        profileImageUrl: "",
      },
    },
  },
};
</script>

<style></style>
