<template>
  <div class="border p-6 bg-white shadow-lg">
    <div>
      <a href="javascript:void(0)" class="inline-block">
        <span
          class="inline-flex items-center px-3 py-0.5 rounded-sm text-xs font-medium"
          :class="{
            'bg-green-100 text-green-800': address.type == 'HOME',
            'bg-rose-200 text-rose-600': address.type == 'OTHER',
            'bg-indigo-100 text-indigo-800': address.type == 'WORK',
          }"
        >
          {{ address.type }}
        </span>
      </a>
    </div>
    <div class="block mt-4 font-merriweather">
      <p class="text-xl font-semibold text-gray-900 font-lato">
        {{ address.firstName }} {{ address.lastName }}
      </p>
      <p class="mt-1 text-xs text-gray-900">
        {{ address.addressLine1 }} {{ address.addressLine2 }}
      </p>
      <p class="mt-1 text-xs text-gray-900">
        {{ address.city }}
        {{ getStateNameByStateCode(address.state, address.country.isoCode) }}
        {{ address.country.name }}
        {{ address.pincode }}
      </p>
      <p
        class="text-xs mt-3 text-rose-600"
        v-if="address.note || address.note.trim().length > 0"
      >
        <span class="mr-1">Note:</span>{{ address.note }}
      </p>
      <p class="text-xs mt-3" v-if="address.contact">
        <span class="mr-1">Contact:</span>{{ address.contact }}
      </p>
    </div>
    <div class="mt-6 flex items-center">
      <div class="flex-shrink-0">
        <button
          type="button"
          @click.prevent="editUserAddress"
          class="inline-flex items-center px-2.5 py-1.5 border border-yellow-600 shadow-sm text-xs font-medium rounded text-white bg-yellow-500 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 hover:text-black focus:ring-yellow-500"
        >
          Update
        </button>
      </div>
      <div class="ml-3">
        <button
          type="button"
          @click.prevent="deleteAddress"
          class="inline-flex items-center px-2.5 py-1.5 border bg-rose-600 shadow-sm text-xs font-medium rounded text-white hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-600 hover:text-black"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { State, City, Country } from "country-state-city";
import useToastStore from "@/store/toast.store";
import useAuthStore from "@/store/auth.store";
import { mapActions, mapState } from "pinia";
import Error from "@/components/Commons/Errors/Error.vue";
export default {
  data() {
    return {
      State,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
  },
  methods: {
    ...mapActions(useAuthStore, [
      "updateUserAddressDetailsAction",
      "getUsertDetailsByUserIdAction",
      "mergeLoggedInUserDetailsToLocalState",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    async deleteAddress() {
      let action = confirm("Are you Sure you want delete the address ?");
      if (!action) {
        return;
      }
      let position = this.address.position;
      let userdata =
        this.userdetails && this.userdetails.addresses
          ? this.userdetails
          : null;
      if (userdata && position > -1) {
        let addresses = this.userdetails.addresses;
        addresses.splice(position, 1);
        this.userdetails.addresses = addresses;
        this.userdetails = userdata;
        await this.updateUserAddressDetailsAction(addresses);
        await this.getUsertDetailsByUserIdAction(userdata.userId);
        this.success(`Successfully Deleted Address Details`);
      }
    },
    getStateNameByStateCode(stateCode = "KA", countryCode = "IN") {
      return State.getStateByCodeAndCountry(stateCode, countryCode).name;
    },
    async editUserAddress() {
      if (this.address.position > -1) {
        this.$router.push({
          name: "USER_EDIT_ADDRESS_FORM",
          query: { position: this.address.position, tab: "address" },
        });
      }
    },
  },
  components: {
    Error,
  },
  props: {
    address: {
      type: Object,
      default: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: {
          name: "",
          isoCode: "",
        },
        country: "",
        pincode: "",
        note: "",
        position: -1,
      },
    },
  },
};
</script>

<style></style>
