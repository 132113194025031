import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
let toast = useToast();

export default defineStore("toasts", {
  state() {},
  actions: {
    success(message) {
      toast.success(message);
    },
    warning(message) {
      toast.success(message);
    },
    error(message) {
      toast.error(message);
    },
    info(message) {
      toast.info(message);
    },
    defaultToast(message) {
      toast.defaultToast(message);
    },
  },
});
