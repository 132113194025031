import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export default defineStore("cart", {
  state() {
    return {
      totalPrice: useStorage("totalPrice", 0),
      itemCount: useStorage("itemCount", 0),
      cartItems: useStorage("cartItems", []),
    };
  },
  actions: {
    cartTotalPriceAction() {
      let cartTotal = 0;
      this.cartItems.forEach((item) => {
        cartTotal += item.productSellingPrice * item.cartQuantity;
      });
      this.totalPrice = cartTotal;
    },
    cartTotalItemsAction() {
      let totalItemCount = 0;
      this.cartItems.forEach((item) => {
        totalItemCount += item.cartQuantity;
      });
      this.itemCount = totalItemCount;
    },
    isItemPresentInCartAction(productId) {
      return this.cartItems.find((product) => product.productId === productId);
    },
    addOrUpdateProductToCartAction(product) {
      let sum = 0;
      let items = 0;
      if (product && product.productId) {
        let productIndex = this.cartItems.findIndex(
          (cartItem) => cartItem.productId === product.productId
        );
        if (productIndex === -1) {
          this.cartItems.push({ ...product, cartQuantity: 1 });
        } else {
          if (this.cartItems[productIndex].cartQuantity < product.quantity) {
            this.cartItems[productIndex].cartQuantity++;
          }
        }
        this.cartTotalPriceAction();
        this.cartTotalItemsAction();
        localStorage.setItem(
          "reygamescartcache",
          JSON.stringify(this.cartItems)
        );
      }
    },
    removeProductFromCartAction(product) {
      if (product && product.productId) {
        let productIndex = this.cartItems.findIndex(
          (item) => item.productId === product.productId
        );
        if (
          productIndex >= 0 &&
          this.cartItems[productIndex].cartQuantity > 1
        ) {
          this.cartItems[productIndex].cartQuantity--;
        } else {
          this.cartItems.splice(productIndex, 1);
        }
      }
      this.cartTotalPriceAction();
      this.cartTotalItemsAction();
      localStorage.setItem("reygamescartcache", JSON.stringify(this.cartItems));
    },
    loadCartItemsFromLocalStorageAction() {
      let cachedCartItems = JSON.parse(
        localStorage.getItem("reygamescartcache")
      );
      if (cachedCartItems) {
        cachedCartItems.forEach((product) => {
          this.cartItems.push(product);
        });
        this.cartTotalItemsAction();
        this.cartTotalPriceAction();
      }
    },
    clearCartItemsAction() {
      if (this.cartItems.length) {
        this.cartItems = [];
        this.cartTotalItemsAction();
        this.cartTotalPriceAction();
        localStorage.removeItem("reygamescartcache");
      }
    },
  },
});
