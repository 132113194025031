import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDmZ7nJ3_DbOXL9yGmV8SvuvDxiZ6ptpfY",
  authDomain: "reygamesstore.firebaseapp.com",
  projectId: "reygamesstore",
  storageBucket: "reygamesstore.appspot.com",
  messagingSenderId: "199426997734",
  appId: "1:199426997734:web:92be78a38bbd1e34dbaa27",
  measurementId: "G-L3RRGDWBW4",
};

firebase.initializeApp(firebaseConfig);
let timestamp = firebase.firestore().timestamp;
let db = firebase.firestore();
let auth = firebase.auth();
let storage = firebase.storage();

let ordersCollection = db.collection("orders");
let userCollection = db.collection("users");
let productCollection = db.collection("products");
let categoryCollection = db.collection("categories");
let roleCollection = db.collection("roles");
let brandsCollection = db.collection("brands");
let statusCollection = db.collection("status");
let genreCollection = db.collection("genre");
let galleryCollection = db.collection("gallery");
let companyDetailsCollection = db.collection("companyDetails");
let paymentConfigCollection = db.collection("payments_config");
let invoiceCollection = db.collection("invoices");
let productStorageRootRef = storage.ref();

export {
  userCollection,
  productCollection,
  categoryCollection,
  roleCollection,
  brandsCollection,
  genreCollection,
  auth,
  storage,
  statusCollection,
  productStorageRootRef,
  galleryCollection,
  companyDetailsCollection,
  paymentConfigCollection,
  ordersCollection,
  invoiceCollection,
  timestamp,
};
