import { defineStore } from "pinia";
import {
  saveNewStatusDetails,
  fetchAllStatus,
  getStatusByStatusId,
  deleteStatusByStatusIdApi,
  updateStatusByStatusIdApi,
} from "@/api/status.api";

export default defineStore("status", {
  state() {
    return {
      status: [],
    };
  },
  actions: {
    async saveNewStatusDetailsAction(status) {
      return await saveNewStatusDetails(status);
    },
    async fetchAllStatusAction() {
      (await fetchAllStatus()).forEach((doc) => {
        let status = { statusId: doc.id, ...doc.data() };
        this.status.push(status);
      });
    },
    async getStatusDetailsByStatusIdAction(statusId) {
      return await getStatusByStatusId(statusId);
    },
    async deleteStatusDetailsByStatusIdAction(statusId) {
      return await deleteStatusByStatusIdApi(statusId);
    },
    async updateStatusDetailByStatusIdAction(statusId, status) {
      return await updateStatusByStatusIdApi(statusId, status);
    },
  },
  getters: {},
});
