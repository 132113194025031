import { createApp, watch } from "vue";
import "animate.css";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Overlay from "@/components/Commons/Overlays/Overlay.active";
import { auth } from "@/firebase/firebase.config";
import FoldingSpinner from "@/components/Commons/Spinners/Spinner.folding.vue";
import "./assets/main.css";
import BrandDeleteModal from "@/components/Commons/Modals/Brand.delete.modal.vue";
import VeeValidateCustomPlugin from "@/plugins/validation.plugin";
import moment from "moment";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
// import Footer from "@/components/Homepage/Footer.vue";
import Footer from "@/components/Homepage/Footer.new.vue";
import ProductSortMenu from "@/components/Commons/Dropdowns/Product.sort.dropdown.vue";
import ErrorComponent from "@/components/Commons/Errors/Error";
import ProductHomepageOfferCarousel from "@/views/Layouts/Product.offers.carousel.vue";
import UserPasswordResetModal from "@/components/Commons/Modals/User.password.verify.modal.vue";
var InfiniteScroll = require("vue-infinite-scroll");
import TextTrimmerDirective from "@/components/Directives/Text.trimmer.directive";
import UserResetPasswordModal from "@/components/Users/User.reset.password.modal.vue";
import UnderscoreReplacerDirective from "@/components/Directives/UnderscoreToSpaceReplacer.directive";
import Pagination from "@/components/Commons/Paginations/Pagination.vue";
import HeaderDropdown from "@/components/Header/Header.dropdown.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "vue3-datepicker";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import UserCardSlideBar from "@/components/Users/User.card.slidebar.vue";
import CustomOverLay from "@/components/Commons/Overlays/Overlay.vue";
import TestModal from "@/components/Commons/Modals/Test.modal.vue";

let app;
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(pinia);
    app.config.globalProperties.moment = moment;

    //components
    app.component("appOverlay", Overlay);
    app.component("appSpinner", FoldingSpinner);
    app.component("appBrandDeleteModal", BrandDeleteModal);
    app.component("appSidebar", Sidebar);
    app.component("appFooter", Footer);
    app.component("appProductSortMenu", ProductSortMenu);
    app.component("appError", ErrorComponent);
    app.component("appHeaderDropdown", HeaderDropdown);
    app.component(
      "appProductHomepageOfferCarousel",
      ProductHomepageOfferCarousel
    );
    app.component("appResetPasswordModal", UserPasswordResetModal);
    app.component("VueDatePicker", VueDatePicker);
    app.component("Datepicker", Datepicker);
    app.component("UserCardSlideBarInfo", UserCardSlideBar);
    app.component("SlotOverLay", CustomOverLay);
    app.component("TestModal", TestModal);
    app.component("appUserResetPasswordModal", UserResetPasswordModal);

    app
      .directive("textTrim", TextTrimmerDirective)
      .directive("_replacedirective", UnderscoreReplacerDirective)
      .directive("replaceCharDirective", UnderscoreReplacerDirective)
      .use(InfiniteScroll)
      .use(Toast, { position: POSITION.TOP_RIGHT })

      .use(router)
      .use(VeeValidateCustomPlugin)
      .mount("#app");
  }
});
watch(pinia.state, (state) => {
  localStorage.setItem("currentReyGamesUser", JSON.stringify(state));
});
