<template>
  <div class="">
    <div
      class="w-56 mt-20 h-screen fixed inset-0"
      :class="{ hidden: !toggleSidebar, 'z-50': toggleSidebar }"
    >
      <div
        class="flex h-full flex-grow flex-col rounded-br-lg font-roboto bg-white border border-slate-200 transition-all duration-100 ease-in-out"
      >
        <div class="flex flex-1 flex-col">
          <div class="">
            <nav class="flex-1">
              <ul>
                <li
                  class="relative border shadow-sm"
                  v-for="item in sidebarCategories"
                  :key="item.categoryId"
                  :submenu="item.submenu"
                  :label="item.categoryName"
                  :menu="item"
                >
                  <a
                    href="#"
                    class="flex cursor-pointer items-center border-l-slate-600 py-2 px-4 text-xs font-medium text-gray-600 outline-none transition-all duration-100 ease-in-out hover:border-l-4 hover:border-l-slate-600 hover:text-slate-600 focus:border-l-4"
                  >
                    <img
                      :src="GamingPNG"
                      v-if="item.icon === 'Games'"
                      class="h-5 w-5 mr-2"
                      alt=""
                    />
                    <img
                      :src="ConsolesPNG"
                      v-if="item.icon === 'Consoles'"
                      class="h-5 w-5 mr-2"
                      alt=""
                    />
                    <img
                      :src="HeadphonesPNG"
                      v-if="item.icon === 'Accessories'"
                      class="h-5 w-5 mr-2"
                      alt=""
                    />

                    {{ item.categoryName }}
                  </a>
                  <SidebarItem :items="item.subCategories" />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NintendoPNG from "@/assets/icons/nintendo.png";
import GamingPNG from "@/assets/icons/gaming.png";
import ConsolesPNG from "@/assets/icons/consoles.png";
import BrandPNG from "@/assets/icons/brands.png";
import CategoryPNG from "@/assets/icons/categories.png";
import GamesPNG from "@/assets/icons/games.png";
import HeadphonesPNG from "@/assets/icons/headphones.png";
import StatusPNG from "@/assets/icons/status.png";
import useCartegoryStore from "@/store/categories.store";
import useAuthStore from "@/store/auth.store";
import { mapState } from "pinia";
import SidebarItem from "./Sidebar.item.vue";
export default {
  components: {
    SidebarItem,
  },
  computed: {
    ...mapState(useCartegoryStore, ["categories", "sidebarCategories"]),
    ...mapState(useAuthStore, ["toggleSidebar"]),
  },
  methods: {
    ...mapState(useCartegoryStore, [
      "getCategoriesGetter",
      "sidebarMenuGetter",
    ]),
    toggleOverlay() {},
  },

  data() {
    return {
      appOverlay: false,
      sidebarItems: [],
      BrandPNG,
      CategoryPNG,
      GamesPNG,
      StatusPNG,
      HeadphonesPNG,
      ConsolesPNG,
      GamingPNG,
      NintendoPNG,
    };
  },
};
</script>

<style>
li:hover > ul {
  display: block;
}
</style>
