<template>
  <div class="">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-3xl font-semibold text-gray-900">My Orders</h1>
          <p class="mt-2 text-sm text-gray-700">
            Listing all the orders that has been placed previously as well as
            orders that has been place recently.
          </p>

          <p class="mt-2 text-sm text-gray-700">
            Refresh to get updates on your orders
          </p>
          <div class="mt-4">
            <DropdownSelect
              :label="(selectedStatus = 'Sort By Status')"
              @handleSelectedOptionValue="handleSelectedOrderStatus"
              :options="[
                { label: 'Placed', value: 'ORDER_PLACED' },
                { label: 'Shipped', value: 'ORDER_SHIPPED' },
              ]"
            />
            <div class="max-w-sm mt-4">
              <label class="text-sm">Search By Order ID</label>
              <input
                type="search"
                class="mt-1 w-full pl-5 pr-2 h-10 py-1 rounded-lg border border-gray-200 focus:border-gray-300 focus:outline-none focus:shadow-inner leading-none"
                placeholder="Search By Order Id eg: ORDER_V4iIU7 "
                v-on:keyup.enter="handleOrderSearch"
                v-model="orderSearchText"
              />
            </div>
          </div>
        </div>
        <div class="">
          <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <label class="text-sm">Search By Date</label>
            <Datepicker
              class="pl-2 pr-2 h-10 md:w-48 text-sm rounded-lg text-gray-600 border border-gray-200 focus:border-gray-300 focus:outline-none focus:shadow-inner leading-none"
              v-model="daterange"
              :clearable="true"
            />
          </div>
        </div>
      </div>
      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Order
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Order Placed On
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      v-if="hasPermissionToDelete(CREATE_ORDERS)"
                    >
                      Ordered By
                    </th>

                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>

                <TableRowSkeletonVue v-if="isLoading" />

                <tbody v-else class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="(order, idx) in orders"
                    :key="order.orderId"
                    :id="order.orderId"
                  >
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                    >
                      <div class="flex items-center">
                        <div class="ml-4">
                          <span class="mr-2">{{ idx + 1 }}.</span>
                          <router-link
                            :to="{
                              name: 'ORDER_DETAILS',
                              query: { orderid: order.orderId },
                            }"
                            :order="order"
                            class="font-medium text-gray-900"
                          >
                            {{ order.orderId }}
                          </router-link>
                          <div
                            class="text-xs py-1 px-1 text-white uppercase text-center max-w-sm"
                            v-if="order.orderStatus && order.orderStatus.length"
                            :class="{
                              'bg-yellow-500':
                                order.orderStatus[order.orderStatus.length - 1]
                                  .status === 'ORDER_PLACED',
                              'bg-blue-500':
                                order.orderStatus[order.orderStatus.length - 1]
                                  .status === 'ORDER_SHIPPED',
                              'bg-orange-500':
                                order.orderStatus[order.orderStatus.length - 1]
                                  .status === 'ORDER_OUT_OF_DELIVERY',
                              'bg-green-500':
                                order.orderStatus[order.orderStatus.length - 1]
                                  .status === 'ORDER_DELIVERED',
                            }"
                          >
                            <span
                              v-if="
                                order.orderStatus[order.orderStatus.length - 1]
                                  .status === 'ORDER_PLACED'
                              "
                              >Placed</span
                            >
                            <span v-else>Shipped & Delivered</span>
                            <!-- {{
                            order.orderStatus && order.orderStatus.length
                              ? order.orderStatus[
                                  order.orderStatus.length - 1
                                ].status.split("_")[1]
                              : null
                          }} -->
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      <div class="text-gray-900">₹ {{ order.amount }}</div>
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      <span
                        class="inline-flex rounded-full px-2 text-xs font-semibold leading-5"
                        ><p class="text-xs">
                          {{
                            moment(
                              order.orderStatus[0].createdAt.toDate()
                            ).format(`MMMM Do YYYY, h:mm:ss a`)
                          }}
                        </p></span
                      >
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4"
                      v-if="hasPermissionToDelete(CREATE_ORDERS)"
                    >
                      <p class="text-sm">{{ order.orderedBy }}</p>
                      <p
                        class="text-xs text-gray-500 hover:underline active:underline hover:cursor-pointer active:cursor-pointer"
                        @click.prevent="
                          toggleUserInfoSidebarAction(order.createdBy)
                        "
                      >
                        {{ order.createdBy }}
                      </p>
                    </td>
                    <td
                      class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                    >
                      <button
                        class="hidden items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>

                  <!-- More people... -->
                </tbody>
              </table>
              <div
                v-if="!isLoading && orders.length == 0 && !orderSearchText"
                class="p-4 font-robotoSlab border-t-2"
              >
                You Havent Ordered anything yet.....
              </div>
              <div
                v-if="!isLoading && orders.length == 0 && orderSearchText"
                class="p-4 font-robotoSlab border-t-2"
              >
                Orders Found by Id {{ orderSearchText }}
              </div>
            </div>
            <div
              v-if="
                hasUserPermissions(CREATE_ORDERS) ||
                hasUserPermissions(CREATE_ONLY_ORDERS)
              "
            >
              <dl
                class="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-2 md:divide-y-0 md:divide-x"
              >
                <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">
                    Total Orders Amount
                  </dt>
                  <dd
                    class="mt-1 flex justify-between items-baseline md:block lg:flex"
                  >
                    <div
                      class="flex items-baseline text-2xl font-semibold text-indigo-600"
                    >
                      {{ calculateOrdersTotal }} ₹
                      <span class="ml-2 text-sm font-medium text-gray-500">
                        amount genereated from {{ orders.length }} orders
                        <p class="text-sm font-medium truncate capitalize">
                          {{ numTowords(calculateOrdersTotal) }}
                        </p>
                      </span>
                    </div>
                  </dd>
                </div>

                <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">
                    Total Number Of Orders
                  </dt>
                  <dd
                    class="mt-1 flex justify-between items-baseline md:block lg:flex"
                  >
                    <div
                      class="flex items-baseline text-2xl font-semibold text-indigo-600"
                    >
                      {{ orders.length }}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoItemsAlert from "../Commons/Alerts/NoItems.alert.vue";
import TableRowSkeletonVue from "@/components/Skeletons/Table.row.skeleton.vue";
import DropdownSelect from "../Commons/Dropdowns/Dropdown.select.vue";
import { CREATE_ORDERS, CREATE_ONLY_ORDERS } from "@/assets/Permissions";
import { hasUserPermissions } from "@/utils/auth.util";
import useAuthStore from "@/store/auth.store";
import moment from "moment";
import useOrderStore from "@/store/orders.store";
import useToastStore from "@/store/toast.store";
import { mapState, mapActions, mapWritableState } from "pinia";
import numTowords from "num-words";
export default {
  computed: {
    ...mapWritableState(useOrderStore, ["orders"]),
    ...mapState(useAuthStore, ["userdetails"]),
    calculateOrdersTotal() {
      if (this.orders && this.orders.length) {
        let orders_total = 0;
        this.orders.forEach((order) => {
          orders_total += order.amount;
        });
        return orders_total;
      }
      return 0;
    },
  },
  watch: {
    async daterange(newVal, oldVal) {
      if (newVal != oldVal) {
        this.isFiltersApplied = true;
        let startDate = new Date(
          newVal.getFullYear(),
          newVal.getMonth(),
          newVal.getDate()
        );
        let endDate = new Date(
          newVal.getFullYear(),
          newVal.getMonth(),
          newVal.getDate()
        );

        endDate.setDate(endDate.getDate() + 1);

        let userId =
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null;
        this.requestInProgress = true;
        if (
          hasUserPermissions(CREATE_ONLY_ORDERS) ||
          hasUserPermissions(CREATE_ORDERS)
        ) {
          await this.getOrderseByDateAction(null, startDate, endDate);
        } else {
          await this.getOrderseByDateAction(userId, startDate, endDate);
        }

        this.requestInProgress = false;
      }
    },
  },
  methods: {
    ...mapActions(useOrderStore, [
      "fetchAllOrdersAction",
      "fetchUserOrdersAction",
      "searchOrderByOrderTextAction",
      "deleteOrderByOrderIdAction",
      "searchOrderByStatusAction",
      "getOrderseByDateAction",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useAuthStore, ["toggleUserInfoSidebarAction"]),
    hasPermissionToDelete(permission) {
      return hasUserPermissions(permission);
    },
    async deleteOrderByOrderId(orderId) {
      try {
        let dialog = confirm(
          "Are you sure you want to delete Order " + orderId + " ? "
        );
        if (!dialog) {
          return;
        }
        await this.deleteOrderByOrderIdAction(orderId);
        if (!this.requestInProgress) {
          this.requestInProgress = true;
          this.orders = [];
          if (
            hasUserPermissions(CREATE_ORDERS) ||
            hasUserPermissions(CREATE_ONLY_ORDERS)
          ) {
            await this.fetchUserOrdersAction(
              null,
              "createdAt",
              "desc",
              10,
              this.selectedStatus
            );
          } else {
            let userId =
              this.userdetails && this.userdetails.userId
                ? this.userdetails.userId
                : null;
            if (userId) {
              await this.fetchUserOrdersAction(
                userId,
                "createdAt",
                "desc",
                10,
                this.selectedStatus
              );
            }
          }
          this.requestInProgress = false;
        }
        this.success(`Successfully Deleted Order by order id ${orderId}`);
      } catch (err) {
        this.error(`${err.message}`);
      }
    },
    async handleOrderSearch() {
      if (this.orderSearchText) {
        this.isFiltersApplied = true;
        if (hasUserPermissions(CREATE_ORDERS)) {
          await this.searchOrderByOrderTextAction(
            this.orderSearchText,
            true,
            undefined
          );
        } else {
          await this.searchOrderByOrderTextAction(
            this.orderSearchText,
            false,
            this.userdetails.userId
          );
        }
      }
    },
    async handleSelectedOrderStatus(selectedStatus) {
      this.selectedStatus = selectedStatus.value;
      this.isFiltersApplied = true;
      if (
        hasUserPermissions(CREATE_ORDERS) ||
        hasUserPermissions(CREATE_ONLY_ORDERS)
      ) {
        await this.searchOrderByStatusAction(
          null,
          this.selectedStatus,
          null,
          "createdAt",
          "desc",
          10
        );
      } else {
        let userId =
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null;
        if (userId) {
          await this.searchOrderByStatusAction(
            userId,
            this.selectedStatus,
            null,
            "createdAt",
            "desc",
            10
          );
        }
      }
    },
    async handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;

      let ele = document.getElementById("footerSection");
      let bottom =
        Math.round(scrollTop) + innerHeight >= offsetHeight - ele.offsetHeight;

      if (bottom && !this.isFiltersApplied) {
        if (!this.requestInProgress) {
          this.requestInProgress = true;
          if (
            hasUserPermissions(CREATE_ORDERS) ||
            hasUserPermissions(CREATE_ONLY_ORDERS)
          ) {
            await this.fetchUserOrdersAction(
              null,
              "createdAt",
              "desc",
              10,
              this.selectedStatus
            );
          } else {
            let userId =
              this.userdetails && this.userdetails.userId
                ? this.userdetails.userId
                : null;
            if (userId) {
              await this.fetchUserOrdersAction(
                userId,
                "createdAt",
                "desc",
                10,
                this.selectedStatus
              );
            }
          }
          this.requestInProgress = false;
        }
      }
    },
  },
  async created() {
    this.orders = [];
    this.isLoading = true;
    if (hasUserPermissions(CREATE_ORDERS)) {
      await this.fetchUserOrdersAction(
        null,
        "createdAt",
        "desc",
        10,
        this.selectedStatus
      );
    } else {
      let userId =
        this.userdetails && this.userdetails.userId
          ? this.userdetails.userId
          : null;
      if (userId) {
        await this.fetchUserOrdersAction(
          userId,
          "createdAt",
          "desc",
          10,
          this.selectedStatus
        );
      }
    }
    this.isLoading = false;
    this.orders.forEach((order) => {
      this.orderTotal += order.amount;
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      isLoading: false,
      moment,
      CREATE_ORDERS,
      requestInProgress: false,
      selectedStatus: null,
      orderSearchText: null,
      searchDate: null,
      isFiltersApplied: false,
      daterange: null,
      isFilterOn: false,
      orderTotal: 0,
      hasUserPermissions,
      numTowords,
      CREATE_ONLY_ORDERS,
    };
  },
  components: { DropdownSelect, TableRowSkeletonVue, NoItemsAlert },
};
</script>

<style></style>
