<template>
  <div class="pt-6">
    <dt class="text-lg">
      <!-- Expand/collapse question button -->
      <button
        type="button"
        class="text-left w-full flex justify-between items-start text-gray-400"
        aria-controls="faq-0"
        aria-expanded="false"
      >
        <span class="text-gray-900 tex-sm font-poppins">
          {{ faqData.key }}?
        </span>
        <span class="ml-6 h-4 flex items-center">
          <!--
          Expand/collapse icon, toggle classes based on question open state.

          Heroicon name: outline/chevron-down

          Open: "-rotate-180", Closed: "rotate-0"
        -->
          <slot name="svgIcon"> </slot>
        </span>
      </button>
    </dt>
    <dd class="mt-2 pr-12" id="faq-0">
      <p class="text-base text-gray-500 tex-xs font-archivo font-thin">
        {{ faqData.value }}
      </p>
    </dd>
  </div>
</template>

<script>
export default {
  props: {
    faqData: {
      type: Object,
      required: true,
      default: {
        key: "No Key Provided",
        value: "No Value Provided",
      },
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>

<style></style>
