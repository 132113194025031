import { genreCollection, productCollection } from "@/firebase/firebase.config";

export const createNewGenreApi = async (genre) => {
  let genreAlreadyExist = await genreCollection
    .where("genreName", "==", genre.genreName)

    .get();
  if (genreAlreadyExist.empty) {
    genre.genreId = genre.genreName.split(" ").join("_");
    return (await genreCollection.doc(genre.genreId)).set(genre);
  } else {
    throw new Error("Genre Name Already Taken");
  }
};

export const fetchAllGenreApi = async (genre) => {
  return await genreCollection.orderBy("genreName", "asc").get();
};

export const getGenreByGenreIdAPI = async (genreId) => {
  return await genreCollection.doc(genreId).get();
};

export const updateGenreByGenreIdAPI = async (genre) => {
  let genres = await genreCollection
    .where("genreName", "==", genre.genreName)
    .get();
  let isGenreNameTakenByOtherGenre = false;
  if (!genres.empty) {
    genres.forEach((genre) => {
      let genreDetails = { genreId: genre.id, ...genre.data() };
      if (genreDetails.genreId !== genre.genreId) {
        isGenreNameTakenByOtherGenre = true;
      }
    });
  }
  if (isGenreNameTakenByOtherGenre) {
    throw new Error(
      `Genre named ${genre.genreName} is used by other genre. choose different name`
    );
  } else {
    return await genreCollection.doc(genre.genreId).set(genre);
  }
};

export const deleteStatusDetailsByStatusIdAPI = async (genreId, genreName) => {
  let products = await productCollection.where("genre", "==", genreId).get();
  if (!products.empty) {
    let productNames = "";
    products.forEach((product) => {
      let prod = { productId: product.id, ...product.data() };
      productNames += " , " + prod.productName;
    });
    throw new Error(
      `Cannot delete genre ${genreName} as its used by products ${productNames}`
    );
  } else {
    return await genreCollection.doc(genreId).delete();
  }
};
