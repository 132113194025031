import useAuthStore from "@/store/auth.store";

export const hasUserPermissions = (permissonName) => {
  const store = useAuthStore();
  if (
    store.userdetails &&
    store.userdetails.roleId &&
    store.userdetails.permissions
  ) {
    return store.userdetails.permissions.toString().includes(permissonName)
      ? true
      : false;
  }
  return false;
};
