<template>
  <div class="space-y-6">
    <vee-form :validation-schema="addressSchema" @submit="updateAddress">
      <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6 border shadow-md">
        <h2 class="text-center text-xl">Edit Address</h2>
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="firstName"
                  class="block text-sm font-medium text-gray-700"
                  >First Name</label
                >
                <vee-field
                  type="text"
                  name="firstName"
                  id="firstName"
                  v-model="editedAddress.firstName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="firstName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="lastName"
                  class="block text-sm font-medium text-gray-700"
                  >Last Name</label
                >
                <vee-field
                  type="text"
                  name="lastName"
                  id="lastName"
                  v-model="editedAddress.lastName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="lastName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="addressLine1"
                  class="block text-sm font-medium text-gray-700"
                  >Address Line 1</label
                >
                <vee-field
                  type="text"
                  name="addressLine1"
                  id="addressLine1"
                  v-model="editedAddress.addressLine1"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="addressLine1"
                  class="text-red-500 text-xs"
                />
              </div>

              <div class="col-span-6">
                <label
                  for="addressLine2"
                  class="block text-sm font-medium text-gray-700"
                  >Address Line 2</label
                >
                <vee-field
                  type="text"
                  name="addressLine2"
                  id="addressLine2"
                  v-model="editedAddress.addressLine2"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="addressLine2"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700"
                  >City</label
                >
                <vee-field
                  type="text"
                  name="city"
                  id="city"
                  v-model="editedAddress.city"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="addressLine2"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                  >State</label
                >
                <vee-field
                  id="state"
                  name="state"
                  as="select"
                  autocomplete="country-name"
                  v-model="editedAddress.state"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="state in states"
                    :key="state.isoCode"
                    :value="state.isoCode"
                  >
                    {{ state.name }}
                  </option>
                </vee-field>
                <ErrorMessage name="state" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                  >Country</label
                >
                <vee-field
                  id="country"
                  name="country"
                  as="select"
                  autocomplete="country-name"
                  v-model="editedAddress.country.isoCode"
                  @change="getAllStatesByCountryCode"
                  :value="editedAddress.country.isoCode"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="IN">India</option>
                </vee-field>
                <ErrorMessage name="country" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="pinCode"
                  class="block text-sm font-medium text-gray-700"
                  >Pincode / ZipCode</label
                >
                <vee-field
                  type="text"
                  name="pincode"
                  id="pincode"
                  v-model="editedAddress.pincode"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="pincode" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="type"
                  class="block text-sm font-medium text-gray-700"
                  >Type</label
                >
                <vee-field
                  name="type"
                  id="type"
                  as="select"
                  v-model="editedAddress.type"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="HOME">Home</option>
                  <option value="WORK">Work</option>
                  <option value="OTHER">Other</option>
                </vee-field>
                <ErrorMessage name="type" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="note"
                  class="block text-sm font-medium text-gray-700"
                  >Note</label
                >
                <vee-field
                  type="text"
                  name="note"
                  id="note"
                  v-model="editedAddress.note"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="note" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="note"
                  class="block text-sm font-medium text-gray-700"
                  >Contact / Mobile Number</label
                >
                <vee-field
                  type="text"
                  name="contact"
                  id="contact"
                  v-model="editedAddress.contact"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="contact" class="text-red-500 text-xs" />
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <button
              type="submit"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Update
            </button>
            <button
              type="button"
              @click.prevent="handleCancelSaveBrand"
              class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </vee-form>
  </div>
</template>

<script>
import { State, City } from "country-state-city";
import useAuthStore from "@/store/auth.store";
import useToastStore from "@/store/toast.store";
import { mapActions, mapState } from "pinia";
export default {
  computed: {
    ...mapState(useAuthStore, ["countries", "userdetails"]),
  },
  data() {
    return {
      index: -1,
      props: {
        address: {
          type: Object,
          default: {
            firstName: "",
            lastName: "",
            addressLine1: "",
            addressLine2: "",
            state: "",
            city: "",
            contact: "",
            country: {
              name: "India",
              isoCode: "IN",
            },
            pincode: "",
            note: "",
          },
        },
        index: {
          type: Number,
          default: -1,
        },
      },
      states: [],
      cities: [],
      addressSchema: {
        firstName: "required|min:3|max:50",
        lastName: "required|min:3|max:50",
        addressLine1: "required|min:5|max:150",
        addressLine2: "required|min:5|max:150",
        city: "required|min:5|max:50",
        state: "required",
        country: "required",
        pincode: "required|numeric|min:6|max:6",
        type: "required",
        contact: "required|numeric|min:10|max:10",
      },
      fullCountryName: "",
      fullStateName: "",
      editedAddress: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        country: "IN",
        pincode: "",
        note: "",
        type: "",
        contact: "",
      },
    };
  },
  methods: {
    ...mapActions(useAuthStore, [
      "updateUserAddressDetails",
      "updateUserAddressDetailsAction",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),

    async updateAddress() {
      try {
        if (this.index > -1) {
          let userdata =
            this.userdetails && this.userdetails.addresses
              ? this.userdetails
              : null;
          if (userdata) {
            let addresses = [...this.userdetails.addresses];
            addresses[this.index] = this.editedAddress;

            await this.updateUserAddressDetailsAction(addresses);
            this.success(`Successfully Updated Address Details`);
            this.$router.push({
              name: "USER_PROFILE",
              params: { profileId: this.userdetails.userId },
              query: { tab: "address" },
            });
          }
        }
      } catch (error) {
        this.error(`${error.message}`);
        this.$router.push({
          name: "USER_PROFILE",
          params: { profileId: this.userdetails.userId },
          query: { tab: "address" },
        });
      }
    },
    getAllStatesByCountryCode() {
      this.states = State.getStatesOfCountry(this.address.country);
    },
    getAllCitiesOfState() {
      this.cities = City.getCitiesOfState(
        this.address.country,
        this.address.state
      );
    },
    handleCancelSaveBrand() {
      this.$router.push({
        name: "USER_PROFILE",
        query: { tab: "address" },
        params: { profileId: this.userdetails.userId },
      });
    },
  },
  watch: {
    "this.address.country"() {},
  },
  created() {
    let { position } = this.$route.query;
    if (Number(position) > -1) {
      let addresses =
        this.userdetails && this.userdetails.addresses
          ? this.userdetails.addresses
          : [];
      let address = addresses[position];

      if (address) {
        this.index = position;
        this.editedAddress = address;
        this.states = State.getStatesOfCountry(address.country.isoCode);
      }
    }
  },
};
</script>

<style></style>
