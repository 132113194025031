import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { hasUserPermissions } from "@/utils/auth.util";
import { CREATE_USER } from "@/assets/Permissions";
import {
  registerUser,
  processNewUserDetailsOfRegistration,
  loginUser,
  uploadUserProfile,
  updateUserAddress,
  registerNewUserApi,
  saveNewRegisterUserProfileDetailsApi,
  getUserDetailsByUserId,
  logoutUser,
  sendPasswordResetEmailApi,
} from "@/api/auth.api";
import {
  auth,
  roleCollection,
  userCollection,
  storage,
} from "@/firebase/firebase.config";

export default defineStore(
  "auth",
  {
    state() {
      return {
        isUserLoggedIn: useStorage("isUserLoggedIn", false),
        isLogginOrSignupInProgress: useStorage(
          "isLogginOrSignupInProgress",
          false
        ),
        // isUserLoggedIn: false,
        // isLogginOrSignupInProgress: false,
        userdetails: useStorage("userdetails", {
          userId: null,
          emailId: null,
          isVerified: false,
          meta: {},
          username: null,
          roleId: null,
          profileImageUrl: null,
          about: null,
          addresses: [],
          permissions: [],
        }),
        toggleUserInfoSidebar: false,
        toggleUserInfoSidebarObject: null,
        userId: null,
        toggleSidebar: false,
        countries: [],
        state: [],
      };
    },
    getters: {
      getUserDetails() {
        return this.userdetails;
      },
    },
    actions: {
      setUserLoggedInToTrueAction() {
        // this.isUserLoggedIn = true;
      },
      setUserLoggedInToFalseAction() {
        this.isUserLoggedIn = false;
      },
      async preLogginUserProcess() {
        const {
          uid,
          email,
          emailVerified,
          metadata,
          displayName,
          sendEmailVerification,
        } = { ...auth.currentUser };
        this.userdetails = {
          userId: uid,
          emailId: email,
          isVerified: emailVerified,
          meta: { ...metadata },
          username: null,
          roleId: "ROLE_CUSTOMER",
          profileImageUrl: null,
          about: null,
          addresses: [],
        };
        let userpermissions = await roleCollection
          .doc(this.userdetails.roleId)
          .get();
        let response = await processNewUserDetailsOfRegistration(
          this.userdetails
        );
        this.userdetails = {
          ...this.userdetails,
          permissions: userpermissions.data().permissions.toString(),
        };

        this.userId = auth.currentUser.uid;
        this.setUserLoggedInToTrueAction();
      },
      async newUserRegistrationAction(user) {
        return await registerUser(user);
      },
      async processUserLogoutAction() {
        await auth.signOut();
        this.isUserLoggedIn = false;
        this.userdetails = {};
        this.userId = null;
        return;
      },

      async uploadUserProfileImage(profileImageUrl = "") {
        let rootRef = await storage.ref();
        let savedProfileImage = await rootRef.child(profileImageUrl);
        await savedProfileImage.put();
      },

      async addUserAdditionalDetails(additionalDetails) {
        let uid = auth.currentUser.uid;
        if (uid) {
          let details = {
            ...this.userdetails,
            username: additionalDetails.username,
            about: additionalDetails.about,
            contact: additionalDetails.contact,
          };

          if (details.permissions) {
            delete details.permissions;
          }
          if (additionalDetails.file) {
            if (additionalDetails.file.size) {
              let file_size = additionalDetails.file.size / 1024 / 1024;
              if (file_size > 2) {
                throw new Error(`Profile Image Size Should be less than 2mb.`);
              }
            }
            let profileImageUploadRef = uploadUserProfile(
              additionalDetails.file,
              uid
            );

            let imageURL = profileImageUploadRef
              .then(async (snapshot) => {
                let url = await snapshot.ref.getDownloadURL();
                details.profileImageUrl = url;
                await userCollection.doc(uid).set(details);
                await this.mergeLoggedInUserDetailsToLocalState();
              })
              .catch((error) => {
                throw new Error(error.message);
              });
          } else {
            await userCollection.doc(uid).set(details);
          }
        }
      },

      async updateUserAddressDetails(address, userId) {
        await updateUserAddress(address, userId);
      },

      async signUser(user) {
        this.isLogginOrSignupInProgress = true;
        user = { email: user.emailId, password: user.password };
        let isUserLoggedIn = await loginUser(user);
        this.updateUserDetailsToLocalStore();
        this.userdetails = {
          userId: null,
          emailId: "",
          isVerified: false,
          meta: {},
          username: null,
          roleId: null,
          profileImageUrl: null,
          about: null,
          addresses: [],
          permissions: [],
          contact: "",
        };
        this.isLogginOrSignupInProgress = false;
        // return isUserLoggedIn;
      },
      async updateUserDetailsToLocalStore() {
        if (auth.currentUser) {
          this.isLogginOrSignupInProgress = true;

          await userCollection
            .doc(auth.currentUser.userId)
            .onSnapshot(async (usersnapshot) => {
              if (usersnapshot.exists) {
                let loggedInUser = {
                  userId: usersnapshot.id,
                  ...usersnapshot.data(),
                };
                let listOfUsers = await userCollection.get();
                let permissions = await roleCollection
                  .doc(loggedInUser.data().roleId)
                  .get();
                this.userdetails = {
                  ...loggedInUser.data(),
                  permissions: permissions.toString(),
                };
                this.userdetails = loggedInUser.data();
                this.userdetails.profileImageUrl =
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwHvEGamFasFcudDxAlTzNzd2ZsSRKsmf4Uw&usqp=CAU";
                this.isLogginOrSignupInProgress = false;
              }
            });
        }
      },
      async handleUserDetailsAfterSuccessfullLogin() {
        if (auth.currentUser) {
          let loggedInUser = await userCollection
            .doc(auth.currentUser.uid)
            .get();
          let permissions = await roleCollection
            .doc(loggedInUser.data().roleId)
            .get();
          this.userdetails = {
            ...loggedInUser.data(),
            permissions,
            profileImage: null,
          };
          this.isUserLoggedIn = true;
          this.userId = auth.currentUser.uid;
        }
      },
      async getUsertDetailsByUserIdAction(userId) {
        return await userCollection.doc(userId).get();
      },

      async updateUserAddressDetailsAction(addresses = []) {
        let userdata = { ...this.userdetails };
        userdata.addresses = addresses;
        return await userCollection.doc(userdata.userId).set(userdata);
      },

      async handleCustomerRegistrationAction(emailId, password) {
        return await registerUser(emailId, password);
      },

      addCountriesDataToStoreAction(countries) {
        this.countries = countries;
      },

      addStateDataToStoreAction(states) {
        this.states = states;
      },

      async handleUserProfileDetailsActions(
        username = null,
        about = null,
        profileImageUrl = null
      ) {
        return await updateUserProfileDetails(username, about, profileImageUrl);
      },
      async registerNewUserAction(emailId, password) {
        return await registerNewUserApi(emailId, password);
      },

      async handleUserProfileDetailsAction() {
        let { uid, email, emailVerified, metadata } = { ...auth.currentUser };
        let userdetails = {
          userId: uid,
          emailId: email,
          isVerified: emailVerified,
          meta: { ...metadata },
          username: email && email.split("@")[0],
          roleId: null,
          profileImageUrl: null,
          about: null,
          addresses: [],
          contact: "",
        };
        return await saveNewRegisterUserProfileDetailsApi(
          userdetails,
          "ROLE_CUSTOMER"
        );
      },

      async mergeLoggedInUserDetailsToLocalState() {
        this.isLogginOrSignupInProgress = true;
        let loggedInUserId = auth.currentUser.uid;
        // let userdetails = await getUserDetailsByUserId(loggedInUserId);

        userCollection.doc(loggedInUserId).onSnapshot((userSnapshot) => {
          if (userSnapshot.exists) {
            roleCollection
              .doc(userSnapshot.data().roleId)
              .onSnapshot((roleSnapshot) => {
                let userdetails = {
                  userId: userSnapshot.id,
                  ...userSnapshot.data(),
                  permissions: roleSnapshot.data().permissions.toString(),
                };
                this.userdetails = userdetails;
                this.isUserLoggedIn = true;
                this.userId = userdetails.userId;
                this.isLogginOrSignupInProgress = false;
              });
          }
        });
      },

      async clearLoggedInUserDetails() {
        this.userId = null;
        this.isUserLoggedIn = false;
        this.userdetails = {
          userId: null,
          emailId: null,
          isVerified: null,
          meta: null,
          username: null,
          roleId: null,
          profileImageUrl: null,
          about: null,
          addresses: [],
        };
      },

      async signOutAction() {
        this.isLogginOrSignupInProgress = true;
        await logoutUser();
        this.clearLoggedInUserDetails();
        this.isLogginOrSignupInProgress = false;
      },

      async signInUser(email, password) {
        this.isLogginOrSignupInProgress = true;
        await auth.signInWithEmailAndPassword(email, password);
        await this.mergeLoggedInUserDetailsToLocalState();
        this.isLogginOrSignupInProgress = false;
      },

      async sendPasswordResetEmailAction(emailId) {
        return await sendPasswordResetEmailApi(emailId);
      },
      async toggleUserInfoSidebarAction(userId = null) {
        if (userId) {
          let val = await hasUserPermissions(CREATE_USER);
          if (val) {
            let responseData = await getUserDetailsByUserId(userId);
            let userData = { ...responseData.data() };
            this.toggleUserInfoSidebarObject = userData;
            this.toggleUserInfoSidebar = true;
          }
        }
      },
    },

    async handleUserAdditionalDetailsAction(userDetails) {
      if (userDetails.uid) {
      }
    },

    async fetchAllUserDetails() {
      auth
        .getUsers()
        .then((response) => {})
        .catch((error) => {});
    },
  },
  { persist: true }
);
