<template>
  <div
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        Previous
      </a>
      <a
        href="#"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        Next
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          <span class="font-medium">{{ startProductRecordIndex }}</span>
          to
          <span class="font-medium">{{ endProductRecordIndex }}</span>
          of
          <span class="font-medium">{{ totalRecords }}</span>
          results
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <a
            href="#"
            aria-current="page"
            v-for="(tab, idx) in itemsLength"
            :key="tab"
            @click.prevent="handlePaginationPage(idx)"
            :class="{
              'border-indigo-500 text-indigo-600 bg-indigo-50':
                idx + 1 === page,
            }"
            class="z-10 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          >
            {{ idx + 1 }}
          </a>

          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      startProductRecordIndex: 1,
      endProductRecordIndex: this.productsPerPage,
    };
  },
  methods: {
    handlePaginationPage(currentPageNumber) {
      this.page = currentPageNumber + 1;
    },
  },
  props: {
    productsPerPage: {
      type: Number,
      default: 10,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal === 1) {
          this.startProductRecordIndex = 1;
          this.endProductRecordIndex = this.productsPerPage;
        } else if (newVal > 1) {
          this.endProductRecordIndex = this.productsPerPage * newVal;
          this.startProductRecordIndex =
            this.endProductRecordIndex - this.productsPerPage + 1;
          if (this.endProductRecordIndex > this.totalRecords) {
            this.endProductRecordIndex = this.totalRecords;
          }
        } else {
        }

        this.$emit(
          "PaginationPageUpdated",
          this.startProductRecordIndex,
          this.endProductRecordIndex,
          this.totalRecords
        );
      }
    },
  },
};
</script>

<style></style>
