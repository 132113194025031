<template>
  <div class="px-4 sm:px-6 lg:px-8" ref="document">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <img :src="MainLogoPNG" class="h-24 w-auto" alt="main logo" />
        <p
          class="mt-2 text-sm text-gray-700 my-4 font-bold uppercase"
          v-if="order.createdAt"
        >
          <span class="mr-1">Invoice Date: </span
          >{{ moment(order.createdAt.toDate()).format(`MMMM Do YYYY`) }}
        </p>
        <div class="flex justify-between flex-col lg:flex-row font-archivo">
          <div class="mt-2 text-sm text-gray-700 max-w-md my-8">
            <span class="block mb-1 font-bold uppercase"
              >Invoice issued by</span
            >
            <span class="block text-md uppercase">{{
              companyDetails.companyName
            }}</span>
            {{ companyDetails.companyPrimaryAddress }}
          </div>
          <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <div class="mt-2 text-sm text-gray-700 max-w-md my-8">
              <span class="block mb-1 font-bold uppercase"
                >BILLING / SHIPPING ADDRESS,</span
              >
              <span class="block"
                >{{ order.deliveryAddress.firstName }}
                {{ order.deliveryAddress.lastName }}</span
              >
              <p>
                {{ order.deliveryAddress.addressLine1 }}
                {{ order.deliveryAddress.addressLine2 }}
              </p>
              <p></p>
              <p>
                {{ order.deliveryAddress.city }}
                {{ order.deliveryAddress.city }}
                {{
                  getStateNameByStateCode(
                    order.deliveryAddress.state.isoCode,
                    order.deliveryAddress.country.isoCode
                  )
                }}
              </p>
            </div>
          </div>
        </div>

        <p class="mt-2 text-sm text-gray-700 max-w-md my-4">
          <span class="font-bold">GSTIN :</span>
          {{ companyDetails.gstin }}
        </p>
        <p
          class="mt-2 text-sm text-gray-700 max-w-md my-4"
          v-if="isOrderDelivered"
        >
          <span class="font-bold uppercase">Order Id: </span>
          <span>{{ order.orderId }} </span>
        </p>
        <p
          class="mt-2 text-sm text-gray-700 max-w-md my-4"
          v-if="isOrderDelivered"
        >
          <span class="font-bold uppercase">Invoice Date: </span>
          <span
            >{{ moment(order.createdAt.toDate()).format(`MMMM Do YYYY`) }}
          </span>
        </p>
        <p
          class="mt-2 text-sm text-gray-700 max-w-md my-4 hover:cursor-pointer hover:underline"
          v-if="!editMode"
          @click.prevent="toggleUserDetails(order.createdBy)"
        >
          <span
            v-if="
              hasUserPermissions(CREATE_ORDERS) ||
              hasUserPermissions(CREATE_ONLY_ORDERS)
            "
          >
            <span class="font-bold">Created By :</span>
            {{ order.createdBy }}</span
          >
        </p>
      </div>
    </div>
    <div class="flex justify-center uppercase font-bold text-indigo-700 my-12">
      TAX INVOICE
    </div>
    <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-indigo-600">
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 max-w-2xl"
            >
              Product Name
            </th>
            <th
              scope="col"
              class="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
            >
              Unit Price
            </th>
            <th
              scope="col"
              class="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
            >
              Quantity
            </th>
            <th
              scope="col"
              class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-2"
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b border-gray-200"
            v-for="(item, idx) in order.products"
            :key="item.productId"
          >
            <td class="py-4 pl-4 pr-3 text-sm sm:pl-6">
              <router-link
                :to="{
                  name: 'PRODUCT_DETAILS',
                  params: { productId: item.productId },
                }"
                class="font-medium text-gray-900 max-w-xl flex"
              >
                <!-- <div class="h-10 w-10 flex-shrink-0">
                  <img class="h-10 w-10" :src="item.mainImageUrl" alt="" />
                </div> -->
                <span>{{ idx + 1 }}.</span>
                <span class="ml-2"> {{ item.productName }}</span>
              </router-link>
            </td>
            <td
              class="hidden py-4 px-3 text-right text-sm text-gray-900 sm:table-cell"
            >
              ₹ {{ item.price }}
            </td>
            <td
              class="hidden py-4 px-3 text-right text-sm text-gray-900 sm:table-cell"
            >
              {{ item.quantity }}
            </td>
            <td
              class="py-4 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0"
            >
              ₹ {{ item.quantity * item.price }}
            </td>
          </tr>

          <!-- More projects... -->
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="row"
              colspan="3"
              class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
            >
              Subtotal
            </th>
            <th
              scope="row"
              class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-800 sm:hidden"
            >
              Subtotal
            </th>
            <td
              class="pl-3 pr-4 pt-6 text-right text-sm text-gray-800 sm:pr-6 md:pr-0"
            >
              ₹ {{ order.amount }}
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              colspan="3"
              class="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
            >
              Tax
            </th>
            <th
              scope="row"
              class="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
            >
              Tax
            </th>
            <td
              class="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0"
            >
              0.0
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              colspan="3"
              class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
            >
              Total
            </th>
            <th
              scope="row"
              class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
            >
              Total
            </th>
            <td
              class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
            >
              ₹ {{ order.amount }}
            </td>
          </tr>
        </tfoot>
      </table>
      <div class="mb-24">
        <p
          class="uppercase my-12 inline-block p-2 text-black"
          v-if="order.amount >= 0"
        >
          PAID {{ numTowords(order.amount) }} only.
        </p>
        <p v-if="note">Note: {{ note }}</p>
        <p class="text-sm mb-6">
          Play and return the games and get upto 70% off while exchanging next
          games. this only applicable only for preowned games
        </p>
        <p class="text-sm">
          For Any Queries Contact
          {{ companyDetails.customerSupportContact }} or leave a email at
          {{ companyDetails.customerSupportEmail }}
        </p>
      </div>
      <button
        v-if="!editMode"
        @click.prevent="exportToPDF"
        class="w-1/4 bg-gray-600 hidden lg:flex border border-transparent rounded-md py-2 sm:text-xs px-1 md:py-3 md:px-8 items-center justify-center text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
      >
        Download PDF
      </button>
    </div>
  </div>
</template>

<script>
import MainLogoPNG from "@/assets/img/mainlogo.png";
import { State } from "country-state-city";
import numTowords from "num-words";
import html2pdf from "html2pdf.js";
import useOrderStore from "@/store/orders.store";
import useCompanyStore from "@/store/company.store";
import useCartStore from "@/store/cart.store";
import useAuthStore from "@/store/auth.store";
import { mapState, mapActions } from "pinia";
import { CREATE_ONLY_ORDERS, CREATE_ORDERS } from "@/assets/Permissions";
import { hasUserPermissions } from "@/utils/auth.util";
export default {
  computed: {
    ...mapState(useCartStore, ["cartItems"]),
    ...mapState(useCompanyStore, ["companyDetails"]),
    computeMarginBasedOnItemsInCart() {
      return this.order.products.length / 2 == 0 ? 0.25 : 0.35;
    },
    isOrderDelivered() {
      if (
        this.order &&
        this.order.orderStatus &&
        this.order.orderStatus.length
      ) {
        return this.order.orderStatus[this.order.orderStatus.length - 1]
          .status === "ORDER_DELIVERED"
          ? true
          : false;
      }
    },
  },
  data() {
    return {
      hasUserPermissions,
      CREATE_ONLY_ORDERS,
      CREATE_ORDERS,
      editMode: false,
      documentName: null,
      numTowords,
      MainLogoPNG,
      order: {
        orderId: null,
        products: [],
        totalPrice: 0,
        itemCount: 0,
        createdAt: null,
        updatedAt: null,

        quantity: 0,
        price: 0,
        amount: 0,
        discountedTotal: 0,
        deliveryAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: { isoCode: "IN", name: "India" },
          firstName: "",
          lastName: "",
          note: "",
          pincode: "",
          state: {
            name: "",
            isoCode: "",
          },
          type: "",
        },
      },
    };
  },
  methods: {
    ...mapActions(useOrderStore, ["getOrderDetailsByOrderIdAction"]),
    ...mapActions(useAuthStore, ["toggleUserInfoSidebarAction"]),

    getStateNameByStateCode(stateCode = "KA", countryCode = "IN") {
      if (State.getStateByCodeAndCountry(stateCode, countryCode)) {
        return State.getStateByCodeAndCountry(stateCode, countryCode).name;
      } else {
        return null;
      }
    },
    exportToPDF() {
      this.editMode = true;
      html2pdf(this.$refs.document, {
        margin: this.computeMarginBasedOnItemsInCart,
        filename: this.documentName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
        pagebreak: {},
      });
    },
    toggleUserDetails(userId) {
      this.toggleUserInfoSidebarAction(userId);
    },
  },
  async created() {
    let { orderId } = this.$route.params;
    this.isInvoiceBeingFetched = true;
    if (orderId) {
      let orderFromDB = await this.getOrderDetailsByOrderIdAction(orderId);
      let order = { orderId: orderFromDB.id, ...orderFromDB.data() };
      this.order = order;
      this.order.deliveryAddress.state = State.getStateByCode(
        this.order.deliveryAddress.state
      );
      this.documentName = order.orderId;
      // this.order.deliveryAddress.state = State.getStatesOfCountry(
      //   this.order.deliveryAddress.country
      // );
    }
    // if (this.invoice.products.length) {
    //   let subtotal = 0;
    //   this.invoice.products.forEach((product) => {
    //     subtotal += Number(product.cartQuantity) * Number(product.price);
    //   });
    //   this.invoiceSubTotal = subtotal;
    // }

    this.isInvoiceBeingFetched = false;
  },
};
</script>

<style></style>
