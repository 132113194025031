<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-3xl font-semibold text-gray-900">Product's Manager</h1>
        <p class="mt-2 text-sm text-gray-700">
          Manage all saved product like add, update or delete products
          {{ this.products.length }}
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <router-link
          :to="{ name: 'ADD_PRODUCT_FORM' }"
          type="button"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:w-auto"
        >
          Create Product
        </router-link>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Product
                    <span
                      class="ml-2 flex-none cursor-pointer rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 inline-block"
                      @click.prevent="sortProductByField('productName')"
                    >
                      <!-- Heroicon name: solid/chevron-down -->
                      <svg
                        class="h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Price
                    <span
                      @click.prevent="sortProductByField('price')"
                      class="ml-2 flex-none cursor-pointer rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 inline-block"
                    >
                      <!-- Heroicon name: solid/chevron-down -->
                      <svg
                        class="h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Selling Price
                    <span
                      @click.prevent="sortProductByField('discount')"
                      class="ml-2 cursor-pointer flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 inline-block"
                    >
                      <!-- Heroicon name: solid/chevron-down -->
                      <svg
                        class="h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Brand / Category
                    <span
                      class="ml-2 flex-none cursor-pointer rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300 inline-block"
                    >
                      <!-- Heroicon name: solid/chevron-down -->
                      <svg
                        class="h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Quantity
                    <span
                      class="ml-2 cursor-pointer flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
                    >
                      <svg
                        class="h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <TableRowLoadingSkeleton
                  v-if="products.length === 0 && requestInProgress == true"
                />
                <tr v-for="product in products" :key="product.productId" v-else>
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <!-- Selected row marker, only show when row is selected. -->
                    <div
                      class="absolute inset-y-0 left-0 w-0.5 bg-transparent focus:bg-gray-600"
                    ></div>

                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500 sm:left-6"
                    />
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img
                          class="h-10 w-10"
                          :src="product.mainImageUrl"
                          alt=""
                        />
                      </div>
                      <div class="ml-4">
                        <router-link
                          :to="{
                            name: 'PRODUCT_DETAILS',
                            params: { productId: product.productId },
                          }"
                          href="#"
                          class="font-medium text-gray-900 text-md"
                          :title="product.productName"
                          v-textTrim="40"
                        >
                          {{ product.productName }}
                        </router-link>
                        <div class="">
                          <div v-if="product.condition === 'PREOWNED'">
                            <div
                              class="text-xs bg-red-500 inline py-1 px-1 text-white"
                            >
                              {{ product.condition }}
                            </div>
                          </div>
                          <div v-if="product.condition === 'NEW'">
                            <div
                              class="text-xs bg-green-500 inline py-1 px-1 text-white"
                            >
                              {{ product.condition }}
                            </div>
                          </div>
                          <div v-if="product.condition === 'MINT'">
                            <div
                              class="text-xs bg-lime-500 inline py-1 px-1 text-white"
                            >
                              {{ product.condition }}
                            </div>
                          </div>
                          <div v-if="product.condition === 'PREORDER'">
                            <div
                              class="text-xs bg-yellow-500 inline py-1 px-1 text-white"
                            >
                              {{ product.condition }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ product.productPrice }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ product.productSellingPrice }}(<span
                      class="text-xs text-red-400"
                      >{{
                        Math.floor(
                          ((product.productPrice -
                            product.productSellingPrice) /
                            product.productPrice) *
                            100
                        )
                      }}% Off</span
                    >)
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div
                      @click.prevent="searchProduct('brandId', product.brandId)"
                      class="text-gray-900 cursor-pointer"
                    >
                      {{ getBrandName(product.brandId) }}
                    </div>
                    <div
                      class="text-gray-500 text-xs cursor-pointer"
                      @click.prevent="
                        searchProduct('categoryId', product.categoryId)
                      "
                    >
                      {{ getCategortName(product.categoryId) }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      @click.prevent="searchProduct('type', product.type)"
                      class="inline-flex bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 cursor-pointer"
                      >{{ product.type }}</span
                    >
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      class="inline-flex rounded-full px-2 text-xs font-semibold leading-5"
                      >{{ product.quantity }}</span
                    >
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <router-link
                      :to="{
                        name: 'EDIT_PRODUCT_FORM',
                        params: { productId: product.productId },
                      }"
                      href="#"
                      class="inline-flex mr-2 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Edit
                    </router-link>
                    <button
                      @click.prevent="
                        deleteProductByProductId(
                          product.productName,
                          product.productId
                        )
                      "
                      class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- <Pagination
        :productsPerPage="productsPerPage"
        :itemsLength="Math.ceil(totalProductsInDocuments / productsPerPage)"
        :totalRecords="totalProductsInDocuments"
        @PaginationPageUpdated="handlePaginationChanges"
      /> -->
    </div>
  </div>
</template>

<script>
import TableRowLoadingSkeleton from "@/components/Skeletons/Table.row.skeleton.vue";
import useCategoryStore from "@/store/categories.store";
import useBrandStore from "@/store/brands.store";
import useProductStore from "@/store/products.store";
import { mapActions, mapState, mapWritableState } from "pinia";
import useToastStore from "@/store/toast.store";
import { hasUserPermissions } from "@/utils/auth.util";
import Pagination from "@/components/Commons/Paginations/Pagination.vue";
export default {
  computed: {
    ...mapWritableState(useProductStore, [
      "products",
      "totalProductsInDocuments",
    ]),
  },
  data() {
    return {
      sortField: "",
      sortOrder: "asc",
      productsPerPage: 10,
      currentHeightOfTableBody: 0,
      requestInProgress: false,
    };
  },
  methods: {
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useCategoryStore, ["getCategoryNameByCategoryIdAction"]),
    ...mapActions(useBrandStore, ["getBrandNameByBrandIdAction"]),
    ...mapActions(useProductStore, [
      "sortProductsByProductProperyAction",
      "deleteProductDetailsByProductIdAction",
      "fetchAllProductDetailsAction",
      "fetchPaginatedProductsAction",
      "getProductDocByProductIdAction",
    ]),
    async handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;

      let ele = document.getElementById("footerSection");
      let bottom =
        Math.round(scrollTop) + innerHeight >= offsetHeight - ele.offsetHeight;

      if (bottom) {
        if (!this.requestInProgress) {
          this.requestInProgress = true;
          await this.fetchPaginatedProductsAction(
            this.productsPerPage,
            null,
            null,
            "quantity",
            "asc"
          );
          this.requestInProgress = false;
        }
      }
    },

    getCategortName(categoryId) {
      let cat = this.getCategoryNameByCategoryIdAction(categoryId);

      return cat ? cat.categoryName : null;
    },
    searchProduct(searchType, id) {
      if (searchType === "brandId") {
        this.$router.push({
          name: "SEARCH_RESULTS",
          query: { brandId: id },
        });
      } else if (searchType === "categoryId") {
        this.$router.push({
          name: "SEARCH_RESULTS",
          query: { categoryId: id },
        });
      } else if ((searchType === "type", id)) {
        this.$router.push({
          name: "SEARCH_RESULTS",
          query: { type: id },
        });
      } else {
      }
    },
    getBrandName(brandId) {
      let brand = this.getBrandNameByBrandIdAction(brandId);
      return brand ? brand.brandName : null;
    },
    sortProductByField(sortFieldName) {
      this.sortField = sortFieldName;
    },
    sortProducts(property, order) {
      this.sortProductsByProductProperyAction(property, order);
    },
    async deleteProductByProductId(productName, productId) {
      try {
        let action = confirm(
          "Are you sure you wanna delete product " + productName
        );
        if (action) {
          let response = await this.deleteProductDetailsByProductIdAction(
            productId
          );
          this.success("Successfully Delete Product By ID " + productName);
        }
      } catch (err) {
        this.error(err.message);
      }
    },
    hasPermission(permission) {
      return hasUserPermissions(permission);
    },
  },
  watch: {
    sortField(newVal, oldValue) {
      if (newVal != oldValue) {
      } else {
        if (this.sortOrder === "asc") {
          this.sortOrder = "desc";
        } else {
          this.sortOrder = "asc";
        }
      }
    },
  },
  components: {
    TableRowLoadingSkeleton,
    Pagination,
  },
  async created() {
    window.addEventListener("scroll", this.handleScroll);
    this.products = [];
    this.requestInProgress = true;
    await this.fetchPaginatedProductsAction(
      this.productsPerPage,
      null,
      null,
      "quantity",
      "asc"
    );
    this.requestInProgress = false;
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style></style>
