<template>
  <div>
    <SlotOverLay :isOverlayActive="isOverlayActive">
      <UsersidebarModal :userdetails="userdetails"
    /></SlotOverLay>
    <div class="px-4 sm:px-6 lg:px-8" ref="document">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <!-- <h1 class="text-xl font-semibold text-gray-900">Invoice</h1> -->
          <img :src="MainLogoPNG" class="h-24 w-auto" alt="" />
          <p
            class="mt-2 text-sm text-gray-700 my-4 font-bold uppercase"
            v-if="invoice.createdAt"
          >
            <span class="mr-1">Invoice Date: </span
            >{{ moment(invoice.createdAt.toDate()).format(`MMMM Do YYYY`) }}
          </p>
          <div class="flex justify-between flex-col lg:flex-row font-archivo">
            <div class="mt-2 text-sm text-gray-700 max-w-md my-8">
              <span class="block mb-1 font-bold uppercase"
                >Invoice issued by</span
              >
              <span class="block text-md uppercase">{{
                invoice.companyName
              }}</span>
              {{ invoice.companyAddress }}
            </div>
            <div class="mt-2 text-sm text-gray-700 max-w-md my-8">
              <span class="block mb-1 font-bold uppercase"
                >Billing / Shipping Address,</span
              >

              <span class="block">{{ invoice.customerAddress }}</span>
            </div>
          </div>

          <p class="mt-2 text-sm text-gray-700 max-w-md my-4">
            <span class="font-bold">GSTIN :</span>
            {{ invoice.gstin }}
          </p>
          <p
            class="mt-2 text-sm text-gray-700 max-w-md my-4 hover:cursor-pointer hover:underline"
            v-if="!editMode"
            @click.prevent="toggleUserDetails(invoice.createdBy)"
          >
            <span class="font-bold">Created By :</span>
            {{ invoice.createdBy }}
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <div
        class="flex justify-center uppercase font-bold text-indigo-700 my-12"
      >
        TAX INVOICE
      </div>
      <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="">
            <tr class="bg-indigo-600 px-16">
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 max-w-2xl"
              >
                Product Name
              </th>
              <th
                scope="col"
                class="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
              >
                Unit Price
              </th>
              <th
                scope="col"
                class="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
              >
                Quantity
              </th>
              <th
                scope="col"
                class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-2"
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-b border-gray-200"
              v-for="(item, idx) in invoice.products"
              :key="item.productId"
            >
              <td class="py-4 pl-4 pr-3 text-sm sm:pl-6">
                <router-link
                  :to="{
                    name: 'PRODUCT_DETAILS',
                    params: { productId: item.productId },
                  }"
                  class="font-medium text-gray-900 max-w-xl flex"
                >
                  <!-- <div class="h-10 w-10 flex-shrink-0">
                    <img class="h-10 w-10" :src="item.mainImageUrl" alt="" />
                  </div> -->
                  <span>{{ idx + 1 }}.</span>
                  <span class="inline-block ml-1">{{ item.productName }}</span>
                </router-link>
              </td>
              <td
                class="hidden py-4 px-3 text-right text-sm text-black sm:table-cell"
              >
                ₹{{ item.price }}
              </td>
              <td
                class="hidden py-4 px-3 text-right text-sm text-black sm:table-cell"
              >
                {{ item.cartQuantity }}
              </td>
              <td class="py-4 pl-3 pr-4 text-right text-sm text-black sm:pr-2">
                ₹{{ item.cartQuantity * Number(item.price) }}
              </td>
            </tr>

            <!-- More projects... -->
          </tbody>
          <tfoot class="mr-6">
            <tr>
              <th
                scope="row"
                colspan="3"
                class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-black sm:table-cell md:pl-0"
              >
                Subtotal
              </th>
              <th
                scope="row"
                class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-black sm:hidden"
              >
                Subtotal
              </th>
              <td
                class="pl-3 pr-4 pt-6 text-right text-sm text-black sm:pr-6 md:pr-0"
              >
                ₹{{ invoice.totalPrice }}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colspan="3"
                class="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-black sm:table-cell md:pl-0"
              >
                Tax
              </th>
              <th
                scope="row"
                class="pl-4 pr-3 pt-4 text-left text-sm font-normal text-black sm:hidden"
              >
                Tax
              </th>
              <td
                class="pl-3 pr-4 pt-4 text-right text-sm text-black sm:pr-6 md:pr-0"
              >
                0.0
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colspan="3"
                class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
              >
                Total
              </th>
              <th
                scope="row"
                class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                Total
              </th>
              <td
                v-if="invoice.discountTotal > 0"
                class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
              >
                ₹{{ invoice.discountTotal }}
              </td>
              <td
                v-else
                class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
              >
                ₹{{ invoice.totalPrice }}
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="mb-24 px-4 font-roboto font-medium">
          <p
            class="uppercase my-12 inline-block p-2 text-black"
            v-if="invoice.discountTotal <= 0"
          >
            PAID
            <span class="font-bold uppercase">{{
              numTowords(invoice.totalPrice)
            }}</span>
            only.
          </p>
          <p class="uppercase my-12" v-else>
            PAID {{ numTowords(invoice.discountTotal) }} only.
          </p>
          <p class="text-sm mb-6">
            Play and return the games and get upto 70% off while exchanging next
            games. this only applicable only for preowned games
          </p>
          <p class="text-sm xs:px-4">
            For Any Queries Contact
            {{ companyDetails.customerSupportContact }} or leave a email at
            {{ companyDetails.customerSupportEmail }}
          </p>
        </div>
        <button
          v-if="!editMode"
          @click.prevent="exportToPDF"
          class="w-1/4 bg-gray-600 hidden md:flex border border-transparent rounded-md py-2 sm:text-xs px-1 md:py-3 md:px-8 items-center justify-center text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
        >
          Download PDF
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogoPNG from "@/assets/img/mainlogo.png";
import UsersidebarModal from "../Commons/Modals/Usersidebar.modal.vue";
import useCompanyStore from "@/store/company.store";
import { hasUserPermissions } from "@/utils/auth.util";
import useCartStore from "@/store/cart.store";
import numTowords from "num-words";
import html2pdf from "html2pdf.js";
import useInvoiceStore from "@/store/invoice.store";
import { mapActions, mapState, mapWritableState } from "pinia";
import useAuthStore from "@/store/auth.store";
import { CREATE_INVOICE, CREATE_ONLY_INVOICE } from "@/assets/Permissions";
export default {
  components: {
    UsersidebarModal,
  },
  data() {
    return {
      MainLogoPNG,
      CREATE_ONLY_INVOICE,
      numTowords,
      documentName: "",
      isOverlayActive: false,
      editMode: false,
      invoice: {
        invoiceId: null,
      },
    };
  },
  methods: {
    ...mapActions(useInvoiceStore, ["getInvoiceDetailsByInvoiceIdAction"]),
    ...mapActions(useAuthStore, ["toggleUserInfoSidebarAction"]),

    exportToPDF() {
      this.editMode = true;
      html2pdf(this.$refs.document, {
        margin: this.computeMarginBasedOnItemsInCart,
        filename: this.documentName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
        pagebreak: {},
      });
    },
    toggleUserDetails(userId) {
      this.toggleUserInfoSidebarAction(userId);
    },
  },
  async created() {
    let { invoiceId } = this.$route.params;
    if (invoiceId) {
      let invoicedetails = await this.getInvoiceDetailsByInvoiceIdAction(
        invoiceId
      );
      let invoice_detail = {
        invoice: invoicedetails.id,
        ...invoicedetails.data(),
      };
      this.invoice = invoice_detail;
      this.documentName = invoice_detail.invoice;
    }
  },
  computed: {
    ...mapState(useCompanyStore, ["companyDetails"]),
    ...mapState(useAuthStore, ["userdetails"]),
    ...mapWritableState(useAuthStore, ["toggleUserInfoSidebar"]),
    computeMarginBasedOnItemsInCart() {
      return this.invoice.products.length / 2 == 0 ? 0.25 : 0.35;
    },
  },
};
</script>

<style></style>
