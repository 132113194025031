import { ordersCollection } from "@/firebase/firebase.config";

export const createNewOrderAPI = async (order) => {
  if (order && order.orderId) {
    return await ordersCollection.doc(order.orderId).set(order);
  }
};

export const fetchAllOrderAPI = async () => {
  return await ordersCollection.get();
};

export const getOrderByOrderIdAPI = async (orderId) => {
  return await ordersCollection.doc(orderId).get();
};

export const updateOrderByOrderIdAPI = async (orderId, orderdetails) => {
  return await ordersCollection.doc(orderId).set(orderdetails);
};

export const fetchAllUserCreatedOrdersAPI = async (
  userId,
  lastOrderDoc,
  sortBy = "createdAt",
  sortOrder = "desc",
  limit = 10
) => {
  let query = ordersCollection;

  if (userId) {
    query = query.where("createdBy", "==", userId);
  }
  if (sortBy && sortOrder) {
    query = query.orderBy(sortBy, sortOrder);
  }
  if (lastOrderDoc) {
    query = query.startAfter(lastOrderDoc);
  }

  query = query.limit(limit);
  return await query.get();
};

export const getOrdersByOrderIdByRoles = async (orderId, isAdmin, userId) => {
  let query = ordersCollection;
  if (!isAdmin && userId) {
    query = query.where("createdBy", "==", userId);
  }
  if (orderId) {
    query = query.where("orderId", "==", orderId);
  }
  return await query.get();
};

export const deleteOrderByOrderIdAPI = async (orderId) => {
  return await ordersCollection.doc(orderId).delete();
};

export const searchOrderByStatusAPI = async (
  userId,
  status,
  lastDoc,
  sortBy = "createdAt",
  sortOrder = "desc",
  limit = 10
) => {
  let query = ordersCollection;
  if (userId) {
    query = query.where("createdBy", "==", userId);
  }
  if (status) {
    query = query.where("currentStatus", "==", status);
    lastDoc = null;
  }
  if (sortBy && sortOrder) {
    query = query.orderBy(sortBy, sortOrder);
  }
  if (lastDoc) {
    query = query.startAfter(lastDoc);
  }
  if (limit) {
    query.limit(limit);
  }
  return await query.get();
};

export const getOrderByDateAPI = async (
  userId,
  sortBy = "createdAt",
  sortOrder = "desc",
  startdate,
  enddate,
  lastDoc
) => {
  let query = ordersCollection;

  if (userId) {
    query = query.where("createdBy", "==", userId);
  }

  if (sortBy && sortOrder) {
    query = query.orderBy(sortBy, sortOrder);
  }
  if (lastDoc) {
    query = query.startAfter(lastDoc);
  }
  if (startdate && enddate) {
    query = query
      .where("createdAt", ">=", startdate)
      .where("createdAt", "<", enddate);
  }
  return await query.get();
};

export const getOrdersByDateAPI = async (userId, startTime, endTime) => {
  let query = ordersCollection;
  if (userId) {
    query = query.where("createdBy", "==", userId);
  }
  if (startTime && endTime) {
    query = query
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<", endTime);
  }
  return await query.get();
};
