<template>
  <div
    class="px-4 pt-32 mx-auto max-w-full md:px-24 lg:px-16 bg-indigo-800 font-poppins mt-20 2xl:max-w-screen-xl"
    id="footerSection"
  >
    <!-- lg: -->
    <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
      <div class="sm:col-span-2">
        <router-link
          to="/"
          aria-label="Go home"
          title="Company"
          class="inline-flex items-center"
        >
          <img :src="MainLogoPNG" class="w-24" alt="Main Logo" />
          <span
            class="ml-2 text-xl font-bold tracking-wide text-white uppercase justify-self-end"
            >{{ companyDetails.companyName }}</span
          >
        </router-link>
        <div class="mt-6 lg:max-w-sm">
          <p class="text-sm text-white">
            {{ companyDetails.companyPrimaryAddress }}
          </p>
        </div>
      </div>
      <div class="space-y-2 text-sm">
        <p class="text-base font-bold tracking-wide text-white">Contacts</p>
        <div class="flex">
          <p class="mr-1 text-white">Phone:</p>
          <span
            aria-label="Our phone"
            title="Our phone"
            class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-white text-white"
            >{{ companyDetails.customerSupportContact }}</span
          >
        </div>
        <div class="flex">
          <p class="mr-1 text-white">Email:</p>
          <span
            title="Our email"
            class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-white text-white"
            >{{ companyDetails.customerSupportEmail }}</span
          >
        </div>
        <!-- <div class="flex">
          <p class="mr-1 text-white">Address:</p>
          <a
            href="https://www.google.com/maps"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Our address"
            title="Our address"
            class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            {{ companyDetails }}
          </a>
        </div> -->
      </div>
      <div>
        <span class="text-base font-bold tracking-wide text-white">Social</span>
        <div class="flex items-center mt-1 space-x-3">
          <a
            href="javascript:void(0)"
            @click.prevent="
              openSocialMediaLink(companyDetails.companyYoutubeLink)
            "
            class="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 461.001 461.001"
              xml:space="preserve"
              fill="#000000"
              class="h-6 w-6"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path
                    style="fill: #ffffff"
                    d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
          <a
            href="javascript:void(0)"
            @click.prevent="
              openSocialMediaLink(companyDetails.companyInstagramLink)
            "
            class="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            href="javascript:void(0)"
            @click.prevent="
              openSocialMediaLink(companyDetails.companyFacebookLink)
            "
            class="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>
        <p class="mt-4 text-sm text-white">
          You Can Connect with us and stay updated via social media.
        </p>
      </div>
    </div>
    <div
      class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row"
    >
      <p class="text-sm text-white">
        © Copyright 2023. All rights reserved on www.reygames.co.
      </p>
      <ul
        class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row"
      >
        <li>
          <router-link
            :to="{ name: 'HELP' }"
            class="text-sm text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >F.A.Q</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'HELP' }"
            class="text-sm text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >Privacy Policy</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'HELP' }"
            class="text-sm text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >Terms &amp; Conditions</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MainLogoPNG from "@/assets/img/mainlogo.png";
import useCompanyStore from "@/store/company.store";
import { mapState, mapActions } from "pinia";
export default {
  data() {
    return {
      MainLogoPNG,
    };
  },
  computed: {
    ...mapState(useCompanyStore, ["companyDetails"]),
  },
  methods: {
    ...mapActions(useCompanyStore, ["getCompanyDetailsAction"]),
    openSocialMediaLink(link = null) {
      if (link) {
        window.open(link);
      }
    },
  },
  async created() {
    await this.getCompanyDetailsAction();
  },
};
</script>

<style></style>
