import { defineStore } from "pinia";
import GalleryImageOne from "@/assets/img/gallery_img_1.jpg";
import GalleryImageTwo from "@/assets/img/gallery_img_2.jpg";
import GalleryImageThree from "@/assets/img/gallery_img_3.jpg";

export default defineStore("galleryOffers", {
  state() {
    return {
      offers: [
        {
          title: "Famous Titles On Sale",
          description:
            "Your favourite titles might be on the sale go ahead and grab them before it get sold out. Get upto 70% off on popular video game titles irrespective of platform.",
          filter: "",
          type: "Games",
          imageUrl: GalleryImageOne,
        },
        {
          title: "Gaming Consoles Sale!",
          filter: "",
          type: "Consoles",
          description:
            "Reygamescastle's latest console sales you may Get upto 40% off on popular gaming consoles like PS5, PS4, XBOX and wide range of other consoles go ahead buy now before the we run out of consoles",
          imageUrl: GalleryImageTwo,
        },
        {
          title: "Gaming Accessories Sale! ",
          filter: "",
          type: "Accessories",
          description:
            "Change your gaming experience by adding latest gaming accessories to your gameplay.get up to 60% off on Gaming accessories or any other accessories ",
          imageUrl: GalleryImageThree,
        },
      ],
    };
  },
  actions: {
    fetchOffersFromDatabaseAction() {},
  },
});
