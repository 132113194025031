import { defineStore } from "pinia";
import { brandsCollection } from "@/firebase/firebase.config";
import {
  updateBrandDetailsByBrandId,
  createNewBrandDetails,
  fetchAllSavedBrandDetails,
  deleteBrandByBrandId,
} from "@/api/brands.api";

export default defineStore("brands", {
  state() {
    return {
      brands: [],
    };
  },
  actions: {
    async fetchAllBrands() {
      let savedBrands = await fetchAllSavedBrandDetails().get();
      let allBrands = [];
      savedBrands.forEach((brand) => {
        allBrands.push({
          brandId: brand.id,
          ...brand.data(),
          id: brand.id,
          label: "",
        });
      });
      this.brands = allBrands;
    },
    async getBrandByBrandId(brandId) {
      if (brandId) {
        let brand = await brandsCollection.doc(brandId).get();
        return brand;
      } else {
        throw new Error("Invalid brand Id ");
      }
    },
    async updateBrandDetailsByBrandIdAction(brandId, brandDetails) {
      return await updateBrandDetailsByBrandId(brandId, brandDetails);
    },
    async saveNewBrandDetailsAction(brandDetails) {
      return await createNewBrandDetails(brandDetails);
    },

    async deleteBrandByBrandIdAction(brandId) {
      return await deleteBrandByBrandId(brandId);
    },

    async deleteBrandDocumentByDocumentId(brandId) {
      let brandRef = brandsCollection.doc(brandId).get();
      if (await brandRef.exists()) {
        await brandsCollection.doc(brandId).delete();
        await this.fetchAllBrands();
      } else {
        throw new Error("Unable to Find Brand By id ", brandId);
      }
    },
    getBrandNameByBrandIdAction(brandId) {
      return this.brands.find((brand) => {
        return brand.brandId === brandId;
      });
    },
    async searchBrandByBrandNameTextAction(brandName) {},
  },
});
