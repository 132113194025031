<template>
  <div class="space-y-6 bg-white px-4 py-5">
    <vee-form :validation-schema="schema" @submit="saveNewGenreDetails">
      <div class="sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="genreName"
                  class="block text-sm font-medium text-gray-700"
                  >Genre Name</label
                >
                <vee-field
                  type="text"
                  name="genreName"
                  id="genreName"
                  autocomplete="family-name"
                  v-model="genre.genreName"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="genreName" class="text-red-500 text-xs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          @click.prevent="handleCancelSaveStatus"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import useAuthStore from "@/store/auth.store";
import useGenreStore from "@/store/genre.store";
import useToastStore from "@/store/toast.store";
import { mapState, mapActions } from "pinia";
export default {
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
    ...mapState(useGenreStore, ["genre"]),
  },
  data() {
    return {
      schema: {
        genreName: "required|min:3|max:25",
      },
      genre: {
        genreName: "",
        createdAt: null,
        updatedAt: null,
        updatedBy: null,
        createdBy: null,
      },
    };
  },
  methods: {
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useGenreStore, [
      "createNewGenreAction",
      "fetchAllGenreAction",
    ]),
    async saveNewGenreDetails() {
      this.genre.createdAt = new Date();
      this.genre.createdBy = this.userdetails.userId;
      try {
        await this.createNewGenreAction(this.genre);
        this.genre = {
          genreName: "",
          createdAt: null,
          updatedAt: null,
          updatedBy: null,
          createdBy: null,
        };
        this.success(
          "Successfully Created New Genre " + this.genre.genreName + "."
        );
        this.$router.push("/genre");
      } catch (err) {
        this.error(err.message);
      }
    },
  },
};
</script>

<style></style>
