<template>
  <vee-form :validation-schema="schema" @submit="updateBrandDetails">
    <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
      <div class="md:grid md:grid-cols-1 md:gap-6">
        <div class="mt-5 md:mt-0 md:col-span-3">
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6">
              <label
                for="brandId"
                class="block text-sm font-medium text-gray-700"
                >Order Id</label
              >
              <input
                type="text"
                name="brandId"
                id="brandId"
                autocomplete="family-name"
                v-model="order.orderId"
                readonly="readonly"
                class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-6">
              <label
                for="createdDate"
                class="block text-sm font-medium text-gray-700"
                >Created Date</label
              >
              <input
                type="text"
                name="createdDate"
                id="createdDate"
                autocomplete="family-name"
                :value="
                  order.createdAt
                    ? moment(order.createdAt.toDate()).format(
                        `MMMM Do YYYY, h:mm:ss a`
                      )
                    : ''
                "
                readonly
                class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-6">
              <label
                for="createdDate"
                class="block text-sm font-medium text-gray-700"
                >Updated Date</label
              >
              <input
                type="text"
                name="createdDate"
                id="createdDate"
                autocomplete="family-name"
                :value="
                  order.updatedAt
                    ? moment(order.updatedAt.toDate()).format(
                        `MMMM Do YYYY, h:mm:ss a`
                      )
                    : ''
                "
                readonly
                class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-6">
              <label
                for="createdDate"
                class="block text-sm font-medium text-gray-700"
                >Updated By</label
              >
              <input
                type="text"
                name="updatedBy"
                id="updatedBy"
                autocomplete="family-name"
                v-model="order.updatedBy"
                readonly
                class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-6">
              <label
                for="createdBy"
                class="block text-sm font-medium text-gray-700"
                >Created By</label
              >
              <input
                type="text"
                name="createdBy"
                id="createdBy"
                autocomplete="family-name"
                v-model="order.createdBy"
                readonly
                class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <!-- <div class="col-span-6 sm:col-span-3">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
              >Status</label
            >
            <select
              id="country"
              name="country"
              autocomplete="country-name"
              v-model="brand.brandStatus"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option>United States</option>
              <option>Canada</option>
              <option>Mexico</option>
            </select>
          </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center">
      <button
        type="submit"
        :disabled="requestInProgress"
        :class="{ 'bg-red-500': requestInProgress }"
        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        Update
      </button>
      <button
        type="button"
        @click.prevent="this.$router.push('/brands')"
        class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Cancel
      </button>
    </div>
  </vee-form>
</template>

<script>
export default {
  props: {
    order: {},
  },
};
</script>

<style></style>
