import { defineStore } from "pinia";
import {
  saveNewInvoiceAPI,
  getPaginatedInvoiceAPI,
  getInvoiceByInvoiceIdAPI,
  getInvoiceByDateAPI,
  deleteInvoiceByInvoiceIdAPI,
  invoiceByInvoiceIDAPI,
} from "@/api/invoice.api";

export default defineStore("invoices", {
  state() {
    return {
      invoices: [],
      isFiltersApplied: false,
      filters: {
        startDate: null,
        endDate: null,
      },
    };
  },
  actions: {
    async saveNewInvoiceDetails(invoice) {
      return await saveNewInvoiceAPI(invoice);
    },
    async getInvoiceDetailsByInvoiceIdAction(invoiceId) {
      return await getInvoiceByInvoiceIdAPI(invoiceId);
    },
    async getPaginatedInvoicesAction(
      userId,
      sortBy,
      sortOrder,
      limit,
      startDate,
      endDate
    ) {
      let response;
      if (this.invoices.length) {
        let lastItem = this.invoices[this.invoices.length - 1].invoiceId;
        let lastDoc = await getInvoiceByInvoiceIdAPI(lastItem);
        response = await getPaginatedInvoiceAPI(
          userId,
          lastDoc,
          sortBy,
          sortOrder,
          limit,
          startDate,
          endDate
        );
      } else {
        response = await getPaginatedInvoiceAPI(
          userId,
          null,
          sortBy,
          sortOrder,
          limit,
          startDate,
          endDate
        );
      }
      let newInvoicesList = [];
      response.forEach((invoice) => {
        let newInvoice = { invoiceId: invoice.invoiceId, ...invoice.data() };
        newInvoicesList.push(newInvoice);
      });
      if (this.invoices.length == 0) {
        this.invoices = newInvoicesList;
      } else {
        this.invoices = [...this.invoices, ...newInvoicesList];
      }
    },
    async getInvoiceByDateAction(userId, startDate, endDate) {
      let response = await getInvoiceByDateAPI(userId, startDate, endDate);
      let invoices = [];
      response.forEach((invoice) => {
        let new_invoice = { invoiceId: invoice.id, ...invoice.data() };
        invoices.push(new_invoice);
      });
      this.invoices = invoices;
    },

    async searchInvoiceByInvoiceIDAction(invoiceId) {
      if (invoiceId) {
        let response = await invoiceByInvoiceIDAPI(invoiceId);
        let invoice = { invoiceId: response.id, ...response.data() };
        this.invoices = [];
        this.invoices.push(invoice);
      }
    },

    async applyInvoiceFilterDateAction(userId, startDate, endDate) {
      this.filters.startDate = startDate;
      this.filters.endDate = endDate;
      this.isFiltersApplied = true;
      await this.getInvoiceByDateAction(userId, startDate, endDate);
    },

    async deleteInvoiceByInvoiceIdAction(invoiceId) {
      return await deleteInvoiceByInvoiceIdAPI(invoiceId);
    },
  },
});
