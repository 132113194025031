import { Form, Field, defineRule, ErrorMessage, configure } from "vee-validate";
import {
  required,
  email,
  confirmed,
  alpha_num,
  alpha_spaces,
  max,
  min,
  max_value,
  min_value,
  numeric,
  url,
} from "@vee-validate/rules";
export default {
  install(app, options) {
    app.component("VeeForm", Form);
    app.component("VeeField", Field);
    app.component("ErrorMessage", ErrorMessage);

    defineRule("required", required);
    defineRule("email", email);
    defineRule("alphaNumeric", alpha_num);
    defineRule("alphaSpaces", alpha_spaces);
    defineRule("min", min);
    defineRule("max", max);
    defineRule("minValue", min_value);
    defineRule("maxValue", max_value);
    defineRule("confirmed", confirmed);
    defineRule("numeric", numeric);
    defineRule("url", url);

    configure({
      generateMessage: (ctx) => {
        ctx.field = ctx.field.replace(/([a-z])([A-Z])/g, "$1 $2");
        const messages = {
          required: `The field ${ctx.field} is required`,
          min: `The ${ctx.field} is too short. it should be atleast ${ctx.rule.params[0]} characters long`,
          max: `The ${ctx.field} is too long. it should be less than ${ctx.rule.params[0]} characters long`,
          maxValue: `The ${ctx.field} is Invalid. it should be less than ${ctx.rule.params[0]}`,
          minValue: `The ${ctx.field} is Invalid. it should not be less than ${ctx.rule.params[0]}`,
          numeric: `The ${ctx.field} is invalid. it should only numeric`,
        };
        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `the field ${ctx.field} is invalid`;
        return message;
      },
    });
  },
};
