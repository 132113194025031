<template>
  <Header />
  <!-- //lg:max-w-8xl xl:w-full xl:px-8 mx-auto -->
  <!-- mt-48 3xl:max-w-7xl xl:w-full 3xl:px-2 3xl:max-w-full 2xl:px-8 mx-auto bg-slate-50 -->
  <div class="my-6 max-w-7xl mx-auto">
    <router-view :key="$route.fullPath"></router-view>
  </div>
  <appSidebar></appSidebar>
  <SlotOverLay
    :isOverlayActive="toggleUserInfoSidebar"
    @OverlayOutsideClicked="isOverlayActive = false"
    :applyBackdrop="true"
  >
    <UserCardSlideBarInfo />
  </SlotOverLay>

  <keep-alive>
    <appFooter />
  </keep-alive>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import useAuthStore from "@/store/auth.store";
import { mapWritableState, mapActions } from "pinia";
export default {
  computed: {
    ...mapWritableState(useAuthStore, ["toggleUserInfoSidebar"]),
  },
  components: {
    Header,
  },
  data() {
    return {
      isOffline: false,
      isOverlayActive: true,
    };
  },
  methods: {},
};
</script>

<style></style>
