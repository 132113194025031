export default {
  beforeMount(el, binding) {
    if (binding.value) {
      let text = el.innerHTML;
      if (text.length && text.length > binding.value) {
        text = text.substring(0, binding.value);
        text += "...";
      }

      el.innerHTML = text;
    }
  },
};
