<template>
  <div class="">
    <!-- <FeatureBanner /> -->
    <div class="max-w-7xl mx-auto">
      <appProductHomepageOfferCarousel />
    </div>
    <NewBanner />
    <SmallBanner />

    <ProductHomepageCarousel
      title="Consoles"
      type="Consoles"
      :products="consoles"
      v-show="consoles.length || isProductsLoading"
    />

    <ProductHomepageCarousel
      title="PREORDER"
      :products="preorders"
      condition="PREORDER"
      v-show="preorders.length || isProductsLoading"
    />

    <ProductHomepageCarousel
      title="Playstation 5 Games"
      :products="ps5Games"
      categoryId="Playstation_5_(Games)"
      type="Games"
    />
    <!-- <AdvertBanner /> -->
    <ProductHomepageCarousel
      title="Accessories"
      type="Accessories"
      :products="accessories"
      v-show="accessories.length || isProductsLoading"
    />
    <ProductHomepageCarousel
      title="Playstation 4 Games"
      :products="ps4Games"
      categoryId="Playstation_4_(Games)"
      v-show="ps4Games.length || isProductsLoading"
      type="Games"
    />
    <ProductHomepageCarousel
      title="Xbox One Games"
      :products="xboxOneGames"
      categoryId="Xbox_One_(Games)"
      v-show="xboxOneGames.length || isProductsLoading"
      type="Games"
    />

    <ProductHomepageCarousel
      title="Playstation 3 Games"
      :products="ps3Games"
      categoryId="Playstation_3_(Games)"
      v-show="ps3Games.length || isProductsLoading"
      type="Games"
    />
  </div>
</template>

<script>
import SmallBanner from "@/components/Banners/SmallBanner/SmallBanner.layout.vue";
import NewBanner from "@/components/Banners/Banner.layout.vue";
import AdvertBanner from "@/components/Homepage/Advert.banner.vue";
import FeatureBanner from "@/components/Homepage/Feature.banner.vue";
import useProductStore from "@/store/products.store";
import useCategoryStore from "@/store/categories.store";
import { mapState, mapActions, mapWritableState } from "pinia";
import ProductHomepageCarousel from "./Product.homepage.carousel.vue";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapWritableState(useProductStore, ["isProductsLoading"]),
    ...mapState(useProductStore, [
      "products",
      "preorders",
      "consoles",
      "ps5Games",
      "ps4Games",
      "ps3Games",
      "xboxOneGames",
      "xbox360Games",
      "accessories",
    ]),
  },
  methods: {
    ...mapActions(useProductStore, [
      "getPreorderedGamesAction",
      "getConsolesActions",
      "getGamesByCategoryAction",
      "searchProductByProductTypeAction",
      "loadGamesByCategoryAction",
    ]),
    ...mapActions(useCategoryStore, ["getCategoryIdByCategoryNameAction"]),
  },
  components: {
    ProductHomepageCarousel,
    FeatureBanner,
    AdvertBanner,
    NewBanner,
    SmallBanner,
  },
  async created() {
    this.isProductsLoading = true;
    await this.getConsolesActions(5);
    await this.searchProductByProductTypeAction("Accessories", 5);
    this.isProductsLoading = false;
  },
};
</script>

<style></style>
