import { defineStore } from "pinia";
import {
  createNewOrderAPI,
  fetchAllOrderAPI,
  getOrderByDateAPI,
  deleteOrderByOrderIdAPI,
  getOrdersByOrderIdByRoles,
  fetchAllUserCreatedOrdersAPI,
  getOrderByOrderIdAPI,
  updateOrderByOrderIdAPI,
  getOrdersByDateAPI,
  searchOrderByStatusAPI,
} from "@/api/orders.api";

export default defineStore("orders", {
  state() {
    return {
      orders: [],
    };
  },
  actions: {
    async createNewOrderAction(order) {
      if (order) {
        let isOrderCreated = await createNewOrderAPI(order);
        return isOrderCreated;
      }
    },
    async fetchAllOrdersAction(userId, sortBy, sortOrder, limit = 10) {
      let response = null;

      if (this.orders.length) {
        let lastElementInOrdersArray = this.orders[this.orders.length - 1];

        let lastOrderDoc = this.getOrderDetailsByOrderIdAction(
          lastElementInOrdersArray.orderId
        );
        response = await fetchAllUserCreatedOrdersAPI(
          userId,
          lastOrderDoc,
          sortBy,
          sortOrder,
          limit
        );
      } else {
        response = await fetchAllUserCreatedOrdersAPI(
          userId,
          null,
          sortBy,
          sortOrder,
          limit
        );
      }

      let orders = [];
      if (!response.empty) {
        response.forEach((order) => {
          let newOrder = { orderId: order.id, ...order.data() };
          orders.push(newOrder);
        });
      }
      this.orders = orders;
    },
    async searchOrderByOrderTextAction(orderSearchText, isAdmin, userId) {
      if (orderSearchText) {
        this.orders = [];
        let order = await getOrdersByOrderIdByRoles(
          orderSearchText,
          isAdmin,
          userId
        );
        order.forEach((orderResponse) => {
          let order_details = {
            orderId: orderResponse.id,
            ...orderResponse.data(),
          };
          this.orders.push(order_details);
        });
      }
    },
    async fetchUserOrdersAction(userId, sortBy, sortOrder, limit = 10) {
      let response = null;
      if (this.orders.length) {
        let lastElementInOrdersArray = this.orders[this.orders.length - 1];
        let lastOrderDoc = await this.getOrderDetailsByOrderIdAction(
          lastElementInOrdersArray.orderId
        );
        response = await fetchAllUserCreatedOrdersAPI(
          userId,
          lastOrderDoc,
          sortBy,
          sortOrder,
          limit
        );
      } else {
        response = await fetchAllUserCreatedOrdersAPI(
          userId,
          null,
          sortBy,
          sortOrder,
          limit
        );
      }

      let orders = [];
      response.forEach((order) => {
        let newOrder = { orderId: order.id, ...order.data() };
        orders.push(newOrder);
      });
      this.orders = [...this.orders, ...orders];
    },

    async getOrderDetailsByOrderIdAction(orderId) {
      return getOrderByOrderIdAPI(orderId);
    },

    async updateOrderDetailsByOrderIdAction(orderId, orderdetails) {
      return updateOrderByOrderIdAPI(orderId, orderdetails);
    },
    async deleteOrderByOrderIdAction(orderId) {
      return deleteOrderByOrderIdAPI(orderId);
    },
    async searchOrderByStatusAction(
      userId,
      status,
      lastDoc,
      sortBy,
      sortOrder,
      limit
    ) {
      let orders = [];
      let response = null;
      if (this.orders.length) {
        let lastOrder = this.orders[this.orders.length - 1];

        if (lastOrder.orderId) {
          lastDoc = await this.getOrderDetailsByOrderIdAction(
            lastOrder.orderId
          );
          response = await searchOrderByStatusAPI(
            userId,
            status,
            lastDoc,
            sortBy,
            sortOrder,
            limit
          );
        } else {
          response = await searchOrderByStatusAPI(
            userId,
            status,
            null,
            sortBy,
            sortOrder,
            limit
          );
        }

        response.forEach((ordersByStatus) => {
          let order = { orderId: ordersByStatus.id, ...ordersByStatus.data() };
          orders.push(order);
        });
        this.orders = orders;
      }
    },
    async getOrderseByDateAction(userId, startDate, endDate) {
      let response = await getOrdersByDateAPI(userId, startDate, endDate);
      let orders = [];
      response.forEach((order) => {
        let new_order = { order: order.id, ...order.data() };
        orders.push(new_order);
      });
      this.orders = orders;
    },
    async getOrderByOrderDateAction(
      userId,
      sortBy,
      sortOrder,
      startDate,
      endDate,
      lastDoc
    ) {
      let response = await getOrderByDateAPI(
        userId,
        sortBy,
        sortOrder,
        startDate,
        endDate,
        lastDoc
      );
      this.orders = [];
      let orders = [];
      response.forEach((order) => {
        let newOrder = { orderId: order.id, ...order.data() };
        orders.push(newOrder);
      });
      this.orders = orders;
    },
  },
});
