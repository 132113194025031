<template>
  <div
    class="px-2 py-2 rounded lg:max-w-[275px] sm:w-full shadow-md bg-white border"
  >
    <div>
      <router-link
        :to="{
          name: 'PRODUCT_DETAILS',
          params: { productId: product.productId },
        }"
      >
        <img
          :src="product.mainImageUrl"
          class="sm:h-[275px] w-full object-contain text-left"
          alt=""
        />
      </router-link>
    </div>
    <div class="text-left px-1">
      <div class="">
        <div class="mt-1 mb-3">
          <router-link
            class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
            :class="getProductConditionClasses(product.condition)"
            v-replaceCharDirective
            :to="{
              name: 'SEARCH_RESULTS',
              query: { condition: product.condition, type: product.type },
            }"
          >
            <span v-replaceCharDirective> {{ product.condition }}</span>
          </router-link>
        </div>
        <h3
          class="mt-2 sm:text-md mb-1 font-bold leading-right font-robotoSlab"
        >
          <router-link
            :to="{
              name: 'PRODUCT_DETAILS',
              params: { productId: product.productId },
            }"
            :title="product.productName"
            class="line-clamp-2"
            >{{ product.productName }}</router-link
          >
        </h3>
      </div>
      <div class="">
        <span class="text-sm text-gray-500 font-archivo">
          <router-link
            :to="{
              name: 'SEARCH_RESULTS',
              query: { brandId: product.brandId },
            }"
          >
            {{ getBrandName(product.brandId) }}
          </router-link></span
        >
      </div>
      <div class="mb-1 font-roboto">
        <span class="py-1 text-sm font-roboto font-semibold mr-2"
          >₹{{ product.productSellingPrice }}
        </span>
        <span
          v-if="product.productSellingPrice < product.productPrice"
          class="mr-2 py-1 text-xs"
          :class="{
            'line-through text-gray-400 ': product.productSellingPrice > 0,
          }"
        >
          <span>₹{{ product.productPrice }}</span>
        </span>
        <span
          class="text-xs text-red-600"
          v-if="
            Number(product.productSellingPrice) < Number(product.productPrice)
          "
          >{{
            Math.floor(
              ((Number(product.productPrice) -
                Number(product.productSellingPrice)) /
                Number(product.productPrice)) *
                100
            )
          }}% Off</span
        >
      </div>
      <!-- <div class="mb-4">
          <span
            class="text-xs font-bold font-lato inline-block px-2 py-1 uppercase rounded text-yellow-600 bg-yellow-200 uppercase last:mr-0 mr-1"
          >
            Best Sellar
          </span>
        </div> -->
    </div>
  </div>
</template>

<script>
import useProductStore from "@/store/products.store";
import useBrandStore from "@/store/brands.store";
import { mapActions } from "pinia";
export default {
  props: ["product"],
  watch: {},
  methods: {
    ...mapActions(useBrandStore, ["getBrandNameByBrandIdAction"]),
    ...mapActions(useProductStore, ["getProductDetailsByProductIdAction"]),
    // ...mapActions(useCartStore, ["addCartItem"]),
    // addItemsToCart() {},
    getBrandName(brandId) {
      let brand = this.getBrandNameByBrandIdAction(brandId);
      let brandName = null;
      brandName = brand && brand.brandName ? brand.brandName : null;
      return brandName;
    },
    getProductConditionClasses(condition) {
      switch (condition) {
        case "PREOWNED":
          return " bg-rose-600 text-white";
        case "PREORDER":
          return " bg-yellow-500 text-white";
        case "NEW":
          return "bg-green-500 text-white";
        case "MINT":
          return "bg-lime-500 text-white";
        default:
          return "bg-slate-500 text-white";
      }
    },
  },
  created() {},
};
</script>

<style></style>
