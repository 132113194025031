<template>
  <div class="bg-white font-roboto">
    <div
      class="w-full px-4 py-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8 mx-auto"
    >
      <div class="">
        <div
          v-if="isProductDetailsLoading"
          class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700"
        >
          <svg
            class="w-12 h-12 text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 640 512"
          >
            <path
              d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"
            />
          </svg>
        </div>
        <ProductDetailsCarousel v-else :galleryImages="product.galleryImages" />
      </div>
      <!-- Product details -->
      <div class="w-full" v-if="isProductDetailsLoading">
        <div
          class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"
        ></div>
        <div
          class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"
        ></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div
          class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"
        ></div>
        <div
          class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"
        ></div>
        <div
          class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"
        ></div>
      </div>
      <div
        v-else
        class="lg:max-w-lg sm:col-start-2 sm:row-span-2 sm:self-start"
      >
        <div class="lg:max-w-lg lg:self-end">
          <p class="mb-2 text-sm text-gray-500">
            {{ getBrandName(product.brandId) }}
          </p>
          <div class="">
            <span
              class="xl:text-2xl tracking-tight text-gray-900 font-extrabold lg:text-xl text-md"
            >
              {{ product.productName }}
            </span>
          </div>

          <section aria-labelledby="information-heading" class="mt-2">
            <h2 id="information-heading" class="sr-only">
              Product information
            </h2>
            <div class="flex items-center"></div>
            <p
              class="md:text-2xl font-bold text-gray-900 sm:text-xl md:mt-4"
            ></p>

            <span class="md:text-xl font-bold text-gray-900 sm:text-xl block">
              ₹ {{ product.productSellingPrice }}
              <span
                class="font-medium ml-2 text-red-600"
                v-if="
                  Number(product.productPrice) >
                  Number(product.productSellingPrice)
                "
                >({{
                  Math.floor(
                    ((product.productPrice - product.productSellingPrice) /
                      product.productPrice) *
                      100
                  )
                }}% Off)</span
              >
            </span>
            <span
              class="font-medium line-through text-gray-400 mt-2"
              v-if="
                Number(product.productPrice) >
                Number(product.productSellingPrice)
              "
            >
              ₹ {{ product.productPrice.toLocaleString("en-IN") }}
            </span>
            <div class="text-sm">(Incl. all Taxes)</div>
            <div class="mt-6 flex items-center mb-2">
              <p
                class="text-sm inline py-1 px-3 text-white"
                :class="{
                  'bg-yellow-500': product.condition === 'PREORDER',
                  'bg-red-500': product.condition === 'PREOWNED',
                  'bg-green-500': product.condition === 'NEW',
                  'bg-lime-500': product.condition === 'MINT',
                }"
              >
                {{ product.condition }}
              </p>
            </div>

            <div
              class="mt-2 flex items-center"
              v-if="product.condition != 'PREORDER'"
            >
              <div class="">
                <p
                  class="text-sm text-gray-500 uppercase"
                  v-if="product.quantity > 0"
                  :class="{
                    'bg-green-500 text-white py-1 px-3': product.quantity > 0,
                  }"
                >
                  IN Stock
                </p>
                <p
                  class="text-sm text-gray-500"
                  :class="{
                    'bg-red-500 text-white py-1 px-3':
                      Number(product.quantity) == 0,
                  }"
                  v-else
                >
                  OUT OF STOCK
                </p>
                <p
                  class="text-md text-gray-500 mt-4"
                  v-if="
                    userHasPermission(CREATE_PRODUCT) ||
                    userHasPermission(CREATE_ONLY_PRODUCT)
                  "
                >
                  <span class="font-bold">Quantity : </span>
                  <span>{{ product.quantity }}</span>
                </p>
              </div>
            </div>
            <p
              v-if="product.condition === 'PREORDER'"
              class="mt-8 text-sm text-red-500 uppercase"
            >
              Preordered Products Will be delivered after product release date.
            </p>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="my-4">
              <!-- <IncrementButton title="Quantity" count="0" /> -->
            </div>

            <div class="mt-10 gap-4 flex" v-if="product.quantity > 0">
              <button
                type="submit"
                @click.prevent="addProductToCart"
                v-if="!isProductPresentInCart"
                class="w-1/2 bg-gray-600 border border-transparent rounded-md py-2 sm:text-xs md:text-md uppercase font-robotoSlab px-1 md:py-3 md:px-8 flex items-center justify-center text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
              >
                Add to Cart
              </button>
              <button
                type="submit"
                @click.prevent="this.$router.push('/cart')"
                v-else
                class="w-1/2 bg-gray-600 sm:py-2 sm:px-1 border border-transparent rounded-md py-2 sm:text-xs md:text-md uppercase font-robotoSlab px-1 md:py-3 md:px-8 flex items-center justify-center text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
              >
                Go to Cart
              </button>
              <!-- <button
                type="submit"
                class="w-1/2 bg-white text-black border border-black rounded-md py-3 px-8 flex items-center justify-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
              >
                Add to bag
              </button> -->
            </div>

            <ProductWarrantyAlert
              v-if="product.warranty && product.type !== 'Games'"
              :themeColor="manageProductWarrantyThemeColor"
            >
              <p v-if="product.warranty == 'COMPANY_WARRANTY'">
                {{ PRODUCT_COMPANY_WARRANTY }}
              </p>
              <p v-if="product.warranty == 'SELLAR_WARRANTY'">
                {{ PRODUCT_SELLAR_WARRANTY }}
              </p>
              <p v-if="product.warranty == 'NO_WARRANTY'">
                {{ PRODUCT_NO_WARRANTY }}
              </p>
            </ProductWarrantyAlert>

            <p class="mt-4 text-indigo-600 text-sm">{{ product.note }}</p>
            <div class="mt-10 gap-4 flex">
              <button
                type="submit"
                v-if="
                  userHasPermission(CREATE_PRODUCT) ||
                  userHasPermission(CREATE_ONLY_PRODUCT)
                "
                @click="
                  $router.push({
                    name: 'EDIT_PRODUCT_FORM',
                    params: { productId: product.productId },
                  })
                "
                class="w-1/4 bg-green-600 border border-transparent text-sm rounded-md py-2 px-4 flex items-center justify-center font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-500"
              >
                Edit
              </button>
              <button
                type="submit"
                v-if="
                  userHasPermission(CREATE_PRODUCT) ||
                  userHasPermission(CREATE_ONLY_PRODUCT)
                "
                @click.prevent="
                  deleteProductByProductId(
                    product.productId,
                    product.productName
                  )
                "
                class="w-1/4 bg-red-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-500"
              >
                Delete
              </button>
              <!-- <button
                type="submit"
                class="w-1/2 bg-white text-black border border-black rounded-md py-3 px-8 flex items-center justify-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
              >
                Add to bag
              </button> -->
            </div>
          </section>
        </div>
      </div>
      <!-- Product image -->
    </div>

    <div class="max-w-7xl mx-auto">
      <div class="">
        <div class="">
          <nav class="-mb-px flex space-x-8 ml-4" aria-label="Tabs">
            <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200" -->
            <a
              href="javascript:void(0)"
              @click.prevent="tab = 'productdescription'"
              :class="{
                'text-indigo-600 border-b-4 border-gray-600 text-md font-semibold uppercase':
                  tab === 'productdescription',
              }"
              class="text-gray-500 hover:text-gray-700 whitespace-nowrap flex py-4 px-1 font-medium text-sm"
            >
              Product Description
            </a>

            <a
              href="javascript:void(0)"
              class="whitespace-nowrap flex py-4 px-1 font-medium text-sm text-gray-500"
              @click.prevent="tab = 'similarproducts'"
              :class="{
                'text-indigo-600 border-b-4 border-gray-600 font-semibold uppercase':
                  tab === 'similarproducts',
              }"
              aria-current="page"
            >
              Similar Products
            </a>
          </nav>
          <div class="mt-12">
            <ProductDescription
              v-if="tab === 'productdescription'"
              :product="product"
            />
            <!-- <Review v-if="tab === 'reviews'" /> -->
            <ProductSimiarComponent
              v-if="tab === 'similarproducts'"
              :currentProductId="product.productId"
              :categoryId="product.categoryId"
              :genreId="product.genre"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CREATE_PRODUCT, CREATE_ONLY_PRODUCT } from "@/assets/Permissions";
import useCartStore from "@/store/cart.store";
import ProductSimiarComponent from "@/components/Products/Product.similar.products.vue";
import ProductDescription from "./Product.description.vue";
import { mapActions } from "pinia";
import useProductStore from "@/store/products.store";
import useBrandStore from "@/store/brands.store";
import useGenreStore from "@/store/genre.store";
import useToastStore from "@/store/toast.store";
//import useCartStore from "@/store/cart.store";
// import IncrementButton from "@/components/Commons/Buttons/Increment.group.button.vue";
import ProductDetailsCarousel from "@/components/Products/Product.details.carousel.vue";
// import Review from "./Reviews/Review.vue";
// import ProductDescription from "./Product.description.vue";
import { hasUserPermissions } from "@/utils/auth.util";
import ProductWarrantyAlert from "../Commons/Alerts/ProductWarrantyAlert.vue";
import {
  PRODUCT_SELLAR_WARRANTY,
  PRODUCT_NO_WARRANTY,
  PRODUCT_COMPANY_WARRANTY,
} from "@/assets/Constants";
export default {
  data() {
    return {
      CREATE_PRODUCT,
      CREATE_ONLY_PRODUCT,
      isProductDetailsLoading: false,
      tab: "productdescription",
      PRODUCT_SELLAR_WARRANTY,
      PRODUCT_NO_WARRANTY,
      PRODUCT_COMPANY_WARRANTY,
      product: {
        productName: "",
        productPrice: 0,
        status: "",
        productPriceDiscount: 0,
        fullDescription: "",
        shortDescription: "",
        mainImageUrl: "",
        allImagesUrls: [],
        categoryId: "",
        brandId: "",
        quantity: 0,
        additionalDetails: [],
        condition: "",
        galleryImage1Url: "",
        galleryImage2Url: "",
        galleryImage3Url: "",
        galleryImage4Url: "",
      },
    };
  },
  components: {
    // Review,
    // ProductDescription,
    ProductDetailsCarousel,
    ProductDescription,
    ProductSimiarComponent,
    ProductWarrantyAlert,

    // IncrementButton,
  },
  methods: {
    ...mapActions(useCartStore, [
      "addOrUpdateProductToCartAction",
      "isItemPresentInCartAction",
      "loadCartItemsFromLocalStorageAction",
    ]),
    ...mapActions(useProductStore, [
      "getProductDetailsByProductIdAction",
      "deleteProductDetailsByProductIdAction",
    ]),
    ...mapActions(useBrandStore, ["getBrandNameByBrandIdAction"]),
    ...mapActions(useGenreStore, ["getGenreDetailsByGenreId"]),
    ...mapActions(useToastStore, ["success", "error"]),
    // ...mapActions(useCartStore, ["addCartItem"]),
    // addItemsToCart() {},
    userHasPermission(CREATE_PRODUCT) {
      return hasUserPermissions(CREATE_PRODUCT);
    },
    async deleteProductByProductId(productId, productName) {
      try {
        let action = confirm(
          "Are you sure you wanna delete product " + productName
        );
        if (action) {
          if (this.isProductPresentInCart) {
            let localCartItems = localStorage.getItem("reygamescartcache");
            if (localCartItems) {
              let items = JSON.parse(localCartItems);
              let itemIdx = items
                .map(function (item) {
                  return item.productId;
                })
                .indexOf(productId);
              items.splice(itemIdx, 1);
              localStorage.setItem("reygamescartcache", JSON.stringify(items));
            }
          }

          let response = await this.deleteProductDetailsByProductIdAction(
            productId
          );
          this.success("Successfully Delete Product By ID " + productName);
          this.$router.push("/");
        }
      } catch (err) {
        this.error(err.message);
      }
    },
    getBrandName(brandId) {
      let brand = this.getBrandNameByBrandIdAction(brandId);
      let brandName = null;
      brandName = brand && brand.brandName ? brand.brandName : null;
      return brandName;
    },
    getGenreName(genreId) {
      let genre = this.getGenreDetailsByGenreId(genreId);
      return genre && genre.genreName ? genre.genreName : null;
    },
    addProductToCart() {
      this.addOrUpdateProductToCartAction(this.product, 1);
    },
  },
  computed: {
    isProductPresentInCart() {
      return this.isItemPresentInCartAction(this.product.productId)
        ? true
        : false;
    },
    manageProductWarrantyThemeColor() {
      if (this.product && this.product.warranty) {
        let status = this.product.warranty;
        switch (status) {
          case "COMPANY_WARRANTY":
            return {
              iconColor: "bg-green-400",
              color: "text-green-700",
              bgColor: "bg-green-50",
            };
          case "SELLAR_WARRANTY":
            return {
              iconColor: "bg-yellow-400",
              color: "text-yellow-700",
              bgColor: "bg-yellow-50",
            };
          case "NO_WARRANTY":
            return {
              iconColor: "bg-rose-400",
              color: "text-rose-700",
              bgColor: "bg-rose-50",
            };
          default:
            return {
              iconColor: "text-gray-400",
              color: "text-gray-700",
              bgColor: "bg-gray-50",
            };
        }
      } else {
        return {
          iconColor: "text-gray-400",
          color: "text-gray-700",
          bgColor: "bg-gray-800",
        };
      }
    },
  },
  async created() {
    let productId = this.$route.params.productId;
    if (productId) {
      this.isProductDetailsLoading = true;
      let product = await this.getProductDetailsByProductIdAction(productId);
      let galleryImages = [];
      let productDetails = { productId: product.id, ...product.data() };
      if (productDetails) {
        galleryImages.push(productDetails.mainImageUrl);
        galleryImages.push(productDetails.galleryImage1Url);
        galleryImages.push(productDetails.galleryImage2Url);
        galleryImages.push(productDetails.galleryImage3Url);
        galleryImages.push(productDetails.galleryImage4Url);
      }
      productDetails = { ...productDetails, galleryImages: galleryImages };
      this.product = productDetails;
      this.isProductDetailsLoading = false;
      document.title = this.product.productName + " | ReyGamesCastle";
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
