import {
  categoryCollection,
  productCollection,
} from "@/firebase/firebase.config";

export const saveNewCategoryDetails = async (category) => {
  let isCategoryNameTaken = await categoryCollection
    .where("categoryName", "==", category.categoryName)
    .get();
  category.categoryId = category.categoryName.split(" ").join("_");

  if (!isCategoryNameTaken.empty) {
    throw new Error(
      "Category Name has been Taken please choose different name"
    );
  } else {
    return await categoryCollection.doc(category.categoryId).set(category);
  }
};

export const fetchAllSavedCategories = async () => {
  return await categoryCollection.get();
};

export const sortCategoryByField = async (fieldName, sortOrder = "asc") => {
  return await categoryCollection.orderBy(fieldName, sortOrder).get();
};

export const getCategoryByCategoryId = async (categoryId) => {
  return await categoryCollection
    .doc(categoryId)

    .get();
};

export const updateCategoryByCategoryId = async (
  categoryId,
  categoryDetails
) => {
  let isCategoryNameTakenByOther = false;
  let isCategoryNameTaken = await categoryCollection
    .where("categoryName", "==", categoryDetails.categoryName)
    .get();

  isCategoryNameTaken.forEach((category) => {
    if (
      category.categoryName === categoryDetails.categoryName &&
      category.categoryId != categoryId
    ) {
      isCategoryNameTakenByOther = true;
    }
  });

  if (isCategoryNameTakenByOther) {
    throw new Error(
      "Category Name is Already taken ",
      categoryDetails.categoryName
    );
  } else {
    return await categoryCollection.doc(categoryId).set(categoryDetails);
  }
};

export const deleteCategoryByCategoryId = async (categoryId, categoryName) => {
  let products = await productCollection
    .where("categoryId", "==", categoryId)
    .get();

  if (!products.empty) {
    let productNames = "";
    products.forEach((product) => {
      let prods = { productId: product.id, ...product.data() };
      productNames += " , " + prods.productName;
    });
    throw new Error(
      `Failed to delete Category ${categoryName} as its used by ${productNames}`
    );
  } else {
    return await categoryCollection.doc(categoryId).delete();
  }
};
