<template>
  <li class="flex items-start py-6 space-x-4" :key="product.productId">
    <img
      :src="product.mainImageUrl"
      :alt="product.productName"
      class="flex-none w-20 h-20 rounded-md object-contain"
    />
    <div class="flex-auto space-y-1">
      <h3>{{ product.productName }}</h3>
      <p class="text-gray-500">
        <span class="text-xs">MRP</span> ₹{{ product.productSellingPrice }}
        <span class="text-xs">/-</span>
      </p>
      <div class="flex items-center">
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            @click="incrementCartItem"
            class="relative inline-flex items-center px-1 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:border-slate-500"
          >
            <img :src="AddSVGPNG" alt="" class="h-3 w-3" />
          </button>
          <span
            type="button"
            class="-ml-px relative inline-flex text-xs items-center px-2 py-1 border border-gray-300 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:border-slate-500"
          >
            {{ product.cartQuantity }}
          </span>
          <button
            @click.prevent="removeCartItem"
            type="button"
            class="-ml-px relative inline-flex items-center px-1 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-slate-500 focus:border-slate-500"
          >
            <img :src="MinusSVGPNG" alt="" class="h-3 w-3" />
          </button>
        </span>
      </div>
    </div>
    <p class="flex-none font-medium text-sm">
      ₹ {{ product.productSellingPrice * product.cartQuantity }}
    </p>
  </li>
</template>

<script>
import useCartStore from "@/store/cart.store";
import AddSVGPNG from "@/assets/icons/add.png";
import MinusSVGPNG from "@/assets/icons/minus.png";
import { mapActions } from "pinia";
export default {
  data() {
    return {
      AddSVGPNG,
      MinusSVGPNG,
    };
  },
  methods: {
    ...mapActions(useCartStore, [
      "addOrUpdateProductToCartAction",
      "removeProductFromCartAction",
    ]),
    incrementCartItem() {
      this.addOrUpdateProductToCartAction(this.product);
    },
    removeCartItem() {
      this.removeProductFromCartAction(this.product);
    },
  },
  props: {
    product: {
      productId: "",
      productName: "",
      productSellingPrice: 0,
      mainImageUrl: "",
      cartQuantity: 1,
    },
    quantity: 1,
  },
};
</script>

<style></style>
