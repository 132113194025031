<template>
  <SlotOverLay
    :isOverlayActive="isOverlayActive"
    :applyBackdrop="true"
    @OverlayOutsideClicked="isOverlayActive = false"
    ><appUserResetPasswordModal :isOverlayActive="isOverlayActive" />
  </SlotOverLay>
  <div class="min-h-full flex flex-col justify-center py-4 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-28 w-auto"
        :src="MainLogo"
        alt="Reygamescastle main logo"
      />
      <h2
        class="mt-6 text-center text-3xl font-extrabold text-gray-900 font-robotoSlab"
      >
        Sign in to your account
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <vee-form
          :validation-schema="schema"
          @submit="signInUserByCredentials"
          class="space-y-6"
          action="#"
          method="POST"
        >
          <div>
            <label for="email" class="block text-sm font-bold text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <vee-field
                as="input"
                id="emailId"
                name="emailId"
                type="text"
                required
                v-model="user.emailId"
                @change="hasEmailErrorOnPasswordReset = false"
                ref="userLoginEmailIdInputRef"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />

              <ErrorMessage name="emailId" class="text-xs text-red-500" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-bold text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <vee-field
                id="password"
                name="password"
                type="password"
                required
                v-model="user.password"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <ErrorMessage name="password" class="text-red-500 text-sm" />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm">
              <a
                href="#"
                @click.prevent="isOverlayActive = true"
                class="font-medium text-indigo-600 hover:text-indigo-500 hover:underline active:underline"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div class=" ">
            <button
              type="submit"
              :disabled="registrationInProgress"
              class="w-full flex justify-center items-center uppercase font-archivo py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            >
              <appSpinner v-if="registrationInProgress" class="mr-2" />
              Sign in
            </button>
            <button
              type="submit"
              @click.prevent="$router.push({ name: 'registration' })"
              class="w-full mt-4 flex justify-center items-center py-2 uppercase font-archivo px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-800 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
            >
              Sign Up
            </button>
          </div>
        </vee-form>

        <!-- <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-3">
            <div>
              <a
                href="#"
                class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span class="sr-only">Sign in with Facebook</span>
                <svg
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span class="sr-only">Sign in with Twitter</span>
                <svg
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path
                    d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span class="sr-only">Sign in with GitHub</span>
                <svg
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import UserResetPasswordModal from "./User.reset.password.modal.vue";
import MainLogo from "@/assets/img/mainlogo.png";
import useAuthStore from "@/store/auth.store";
import useToastStore from "@/store/toast.store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { ErrorMessage } from "vee-validate";
export default {
  data() {
    return {
      MainLogo,
      schema: {
        emailId: "required|email",
        password: "required|min:8|max:20",
      },
      user: {
        emailId: "",
        password: "",
      },
      hasEmailErrorOnPasswordReset: false,
      toggleUserPasswordVerificationModal: false,
      registrationInProgress: false,
      isOverlayActive: false,
    };
  },

  computed: {
    ...mapState(useAuthStore, ["isUserLoggedIn", "userdetails"]),
    ...mapWritableState(useAuthStore, ["isLogginOrSignupInProgress"]),
  },
  watch: {},
  methods: {
    ...mapActions(useAuthStore, [
      "signUser",
      "handleUserDetailsAfterSuccessfullLogin",
      "signInUser",
      "sendPasswordResetEmailAction",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    async signInUserByCredentials() {
      try {
        this.registrationInProgress = true;
        this.isLogginOrSignupInProgress = true;
        await this.signInUser(this.user.emailId, this.user.password);
        this.$router.replace("/");
        this.registrationInProgress = false;
        this.isLogginOrSignupInProgress = false;
        this.success(`Welcome back ${this.user.emailId.split("@")[0]}`);
      } catch (error) {
        console.log("err ", error);
        if (error.code === "auth/internal-error") {
          if (
            JSON.parse(error.message).error &&
            JSON.parse(error.message).error.message
          ) {
            this.error(
              JSON.parse(error.message).error.message.replace(/_/g, " ")
            );
          } else {
            this.error(error.message);
          }
        } else {
          this.error(error.message);
        }

        this.registrationInProgress = false;
        this.isLogginOrSignupInProgress = false;
      }
    },
    async handleUserForgotPassword() {
      try {
        let emailId = this.user.emailId;

        if (!emailId) {
          this.hasEmailErrorOnPasswordReset = true;
        } else {
          await this.sendPasswordResetEmailAction(emailId);
          this.success("Sent A Verification Email to " + emailId);
        }
      } catch (err) {
        this.error(err.message);
      }
    },
  },
  components: { ErrorMessage, UserResetPasswordModal },
};
</script>

<style></style>
