<template>
  <ul class="absolute top-full left-0 w-full hidden pt-4 z-50 xs:w-xs sm:w-sw">
    <li class="border relative w-64 bg-white" v-for="item in items" :key="item">
      <a
        @click.prevent="handleUserSidebarAction(item.categoryId)"
        class="flex cursor-pointer font-poppins text-xs items-center py-2 px-4 font-medium text-gray-600 outline-none transition-all duration-100 ease-in-out hover:border-l-4 hover:border-l-indigo-600 hover:text-slate-600 focus:border-l-4"
      >
        <!-- <img
          :src="PlaystationPNG"
          v-if="item.icon === 'playstation'"
          class="h-5 w-5 mr-2"
        />
        <img :src="XboxPNG" v-if="item.icon === 'xbox'" class="h-5 w-5 mr-2" />
        <img
          :src="AccessoriesPNG"
          v-if="item.icon === 'Accessories' || item.icon == null"
          class="h-5 w-5 mr-2"
        /> -->
        <img :src="item.categoryLogoURL" class="h-5 w-5 mr-2 object-contain" />

        {{ item.categoryName }}
      </a>
      <SidebarItem
        v-if="item.subCategories"
        :category="item"
        :items="item.subCategories"
        :showChild="showChild"
        :key="item.categoryId"
      />
    </li>
  </ul>
</template>

<script>
import NintendoPNG from "@/assets/icons/nintendo.png";
import XboxPNG from "@/assets/icons/xbox.png";
import AccessoriesPNG from "@/assets/icons/accessories.png";
import PlaystationPNG from "@/assets/icons/playstation.png";
import useAuthStore from "@/store/auth.store";
import { mapWritableState } from "pinia";
export default {
  name: "SidebarItem",
  components: {},
  computed: {
    ...mapWritableState(useAuthStore, ["toggleSidebar"]),
  },
  props: {
    items: {
      type: Array,
      required: false,
    },
    showfirstChild: {
      type: Boolean,
    },
  },
  data() {
    return {
      showChild: false,
      currentSubParent: "",
      PlaystationPNG,
      AccessoriesPNG,
      XboxPNG,
      NintendoPNG,
    };
  },
  methods: {
    handleUserSidebarAction(categoryId) {
      let type = null;
      if (categoryId && categoryId.includes("Consoles")) {
        type = "Consoles";
      }
      if (categoryId && categoryId.includes("Games")) {
        type = "Games";
      }

      if (categoryId && categoryId.includes("Accessories")) {
        type = "Accessories";
      }

      this.$router.replace({
        name: "SEARCH_RESULTS",
        query: { categoryId: categoryId, type: type },
      });
      this.toggleSidebar = false;
    },
  },
};
</script>

<style>
li:hover > ul {
  display: block;
}
</style>
