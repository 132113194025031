<template>
  <div class="space-y-6">
    <vee-form :validation-schema="schema" @submit="handleFormSubmission">
      <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="statusName"
                  class="block text-sm font-medium text-gray-700"
                  >Status Name</label
                >
                <vee-field
                  type="text"
                  name="statusName"
                  id="statusName"
                  autocomplete="family-name"
                  v-model="status.statusName"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="statusName" class="text-red-500 text-xs" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          @click.prevent="handleCancelSaveStatus"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import useToastStore from "@/store/toast.store";
import useAuthStore from "@/store/auth.store";
import useStatusStore from "@/store/status.store";
import { mapState, mapActions } from "pinia";
import { saveNewStatusDetails } from "@/api/status.api";
export default {
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
  },
  data() {
    return {
      schema: {
        statusName: "required|min:3|max:25",
      },
      status: {
        statusId: "",
        statusName: "",
        createdAt: new Date(),
        createdBy: this.userdetails ? this.userdetails.userId : null,
        updatedBy: null,
        updatedAt: null,
      },
    };
  },
  methods: {
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useStatusStore, ["fetchAllStatusAction"]),
    async handleFormSubmission() {
      try {
        this.status.statusId = this.status.statusName.toUpperCase();
        this.status.createdBy = this.userdetails.userId;
        let response = await saveNewStatusDetails(this.status);
        this.success("successfully saved Status Details");
        this.fetchAllStatusAction();
        this.$router.replace("/status");
      } catch (error) {
        this.error(error.message);
      }
    },
    handleCancelSaveStatus() {
      this.$router.push("/status");
    },
  },
};
</script>

<style></style>
