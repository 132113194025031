<template>
  <div class="mt-16">
    <!-- max-w-7xl mx-auto -->
    <div class="pt-8 pb-4 my-4 sm:mx-auto">
      <div
        class="-mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap 2xl:max-w-7xl 2xl:mx-auto"
      >
        <div class="bg-transparent">
          <h3
            class="text-2xl leading-6 text-gray-900 font-bold font-montserrat uppercase"
          >
            {{ title }}
          </h3>
          <p class="mt-1 text-sm text-indigo-600 font-semibold hidden">
            Get all the latest {{ title }}
          </p>
          <div
            class="sm:flex sm:items-baseline sm:justify-between md:hidden mt-2"
          >
            <button
              @click.prevent="handleViewMoreBtn"
              class="text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:hidden animate-bounce"
            >
              Browse all {{ title }}<span aria-hidden="true"> &rarr;</span>
            </button>
          </div>
        </div>
        <div class="ml-4 mt-4 flex-shrink-0">
          <div class="sm:flex sm:items-baseline sm:justify-between">
            <button
              @click.prevent="handleViewMoreBtn"
              class="hidden text-sm font-semibold text-indigo-700 hover:text-indigo-900 sm:block animate-bounce"
            >
              Browse all {{ title }}<span aria-hidden="true"> &rarr;</span>
            </button>
          </div>
          <!-- <button
            
            class="relative inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
          >
            Explore
          </button> -->
        </div>
      </div>
    </div>
    <div
      class="grid gap-x-6 lg:grid-cols-5 content-center md:grid-cols-3 gap-y-10 sm:grid-cols-2 grid-cols-1 gap-4"
    >
      <!-- max-w-7xl mx-auto -->
      <ProductCartSkeleton
        v-if="products.length === 0 && isProductsLoading == true"
      />
      <ProductCard
        v-else
        v-for="product in products"
        :key="product"
        :product="product"
      />
    </div>
  </div>
</template>
<script>
import Carousel from "vue-owl-carousel";
import ProductCartSkeleton from "@/components/Skeletons/Product.card.skeleton.vue";
import ProductCard from "@/components/Products/Product.card.vue";
import useProductStore from "@/store/products.store";
import { mapState } from "pinia";
export default {
  name: "product-card",
  computed: {
    ...mapState(useProductStore, ["isProductsLoading"]),
  },
  props: {
    title: {
      required: true,
      default: "No title",
    },
    products: {
      default: [],
      required: true,
    },
    categoryId: {
      default: null,
      required: true,
    },
    condition: {
      default: null,
      required: false,
      type: String,
    },
    type: {
      default: null,
      required: false,
      type: String,
    },
    isLoading: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  components: {
    ProductCard,
    ProductCartSkeleton,
    Carousel,
  },
  methods: {
    handleViewMoreBtn() {
      this.$router.push({
        name: "SEARCH_RESULTS",
        query: {
          categoryId: this.categoryId,
          condition: this.condition,
          type: this.type,
        },
      });
    },
  },
};
</script>

<style></style>
