<template>
  <div class="border-t border-gray-200 px-4 py-6 text-sm">
    <h3 class="-mx-2 -my-3 flow-root">
      <!-- Expand/collapse section button -->
      <button
        type="button"
        @click.prevent="isExpanded = !isExpanded"
        class="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500"
        aria-controls="filter-section-mobile-0"
        aria-expanded="false"
      >
        <span class="font-medium text-gray-900"> {{ title }} </span>
        <span class="ml-6 flex items-center">
          <!--
        Expand icon, show/hide based on section open state.

        Heroicon name: solid/plus-sm
      --><svg
            v-if="isExpanded"
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            v-else
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </svg>
          <!--
        Collapse icon, show/hide based on section open state.

        Heroicon name: solid/minus-sm
      -->
        </span>
      </button>
    </h3>
    <!-- Filter section, show/hide based on section state. -->
    <div
      class="pt-6"
      id="filter-section-mobile-0 "
      :class="{
        hidden: !isExpanded && !isSidebarBarFlyoutActive,
        'z-50': isSidebarBarFlyoutActive,
      }"
    >
      <div class="space-y-3">
        <div class="flex items-center" v-for="item in items" :key="item.id">
          <input
            :id="item.id"
            :name="item.id"
            :value="{ typeId: typeId, id: item.id }"
            type="checkbox"
            v-model="isProductFilterOn.filters"
            class="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
          />
          <label
            v-if="typeId === 'categoryId'"
            :for="item.id"
            class="ml-3 min-w-0 flex-1 text-gray-500"
          >
            {{ item.categoryName }}
          </label>
          <label
            v-if="typeId === 'brandId'"
            :for="item.id"
            class="ml-3 min-w-0 flex-1 text-gray-500"
          >
            {{ item.brandName }}
          </label>
          <label
            v-if="typeId === 'genreId'"
            :for="item.id"
            class="ml-3 min-w-0 flex-1 text-gray-500"
          >
            {{ item.genreName }}
          </label>
          <label
            v-if="typeId === 'productSellingPrice'"
            :for="item.id"
            class="ml-3 min-w-0 flex-1 text-gray-500"
          >
            Price
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useProductStore from "@/store/products.store";
import { mapActions, mapWritableState } from "pinia";
export default {
  props: {
    title: {
      default: "No Category Title",
      type: String,
      required: true,
    },
    typeId: {
      default: undefined,
      type: String,
      required: true,
    },
    isSidebarBarFlyoutActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: [],
    },
    isClosed: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: true,
      checkedFilters:
        this.isProductFilterOn && this.isProductFilterOn.filters
          ? this.isProductFilterOn.filters
          : [],
    };
  },
  methods: {
    ...mapActions(useProductStore, ["searchProductsByMultipleFilters"]),
    ...mapWritableState(useProductStore, ["products"]),

    addNewFilter() {
      this.addCategoryFilterAction(this.isProductFilterOn.filters);
    },
  },
  components: {},
  watch: {
    "isProductFilterOn.filters"(oldVal, newVal) {
      if (oldVal != newVal) {
        this.searchProductsByMultipleFilters(this.isProductFilterOn.filters);
      }
    },
  },
  computed: { ...mapWritableState(useProductStore, ["isProductFilterOn"]) },
};
</script>

<style></style>
