<template>
  <div class="bg-white overflow-hidden border shadow-lg">
    <div class="px-4 py-5 sm:px-6">
      <div class="sm:col-span-2">
        <dt class="text-sm font-medium text-gray-500">About</dt>
        <dd class="text-sm text-gray-900">
          {{ profileDetails.about }}
        </dd>
      </div>
      <dl class="mt-8 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">Username</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ profileDetails.username }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-4 w-4 text-gray-400 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <dt class="text-sm font-medium text-gray-500">Contact</dt>
          </div>

          <dd class="mt-1 text-sm text-gray-900">
            {{ profileDetails.contact }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-4 w-4 text-gray-400 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <dt class="text-sm font-medium text-gray-500">Email Address</dt>
          </div>
          <dd class="mt-1 text-sm text-gray-900">
            {{ profileDetails.emailId }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profileDetails: {
      default: {},
    },
  },
};
</script>

<style></style>
