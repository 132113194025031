<template>
  <div
    class="border px-2 py-2 rounded xs:max-w-[175px] animate-pulse"
    v-for="n in 5"
    :key="n"
  >
    <div class="h-[275px] text-left bg-gray-100">
      <div class="text-left bg-gray-100"></div>
    </div>
    <div class="text-left px-1">
      <div class="">
        <div class="mt-1 mb-3">
          <span
            class="inline-flex items-center h-4 w-[80%] px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
          >
          </span>
        </div>
        <h3
          class="mt-2 sm:text-md font-bold leading-right font-merriweather h-4 w-20 text-gray-100"
        ></h3>
      </div>
      <div class="mb-1 font-roboto h-4 w-24 bg-gray-100">
        <span class="mr-2 py-1 text-xs">
          <span></span>
        </span>
      </div>
      <div class="mb-1 font-roboto h-4 w-36 bg-gray-100">
        <span class="mr-2 py-1 text-xs">
          <span></span>
        </span>
      </div>
      <div class="">
        <span class="text-sm text-gray-500 font-archivo h-2 w-20 bg-gray-100">
        </span>
      </div>
      <div class="mb-1 font-roboto h-4 w-24 bg-gray-100">
        <span class="mr-2 py-1 text-xs">
          <span></span>
        </span>
      </div>
      <div class="mb-1 font-roboto h-4 w-36 bg-gray-100">
        <span class="mr-2 py-1 text-xs">
          <span></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
