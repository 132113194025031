<template>
  <div class="" ref="document">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <img :src="MainLogo" alt="" class="h-24 w-auto object-cover" />
          <p class="mt-2 text-sm text-gray-700 my-4" v-if="editMode">
            <button
              @click.prevent="saveNewManualGeneratedInvoiceDetails"
              class="inline-flex my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            >
              Generate Invoice
            </button>
            <button
              @click.prevent="editUserAddress"
              class="inline-flex ml-4 my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            >
              Edit Customer Address
            </button>
            <button
              @click.prevent="editInvoiceTotal"
              class="inline-flex ml-4 my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            >
              Edit Total
            </button>
            <button
              @click.prevent="addInvoiceNote"
              class="inline-flex ml-4 my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            >
              Add Note
            </button>
          </p>

          <p class="mt-2 text-sm text-gray-700 my-4">
            <span>Invoice Date: </span>
            <time datetime="2022-08-01">{{
              moment().format(`MMMM Do YYYY`)
            }}</time>
          </p>
          <div class="flex justify-between flex-col lg:flex-row font-archivo">
            <div class="">
              <p class="mt-2 text-sm text-gray-700 max-w-md my-8">
                <span class="block mb-1 font-bold">INVOICE ISSUED BY</span>
                <span class="block text-md uppercase">{{
                  companyDetails.companyName
                }}</span>
                {{ companyDetails.companyPrimaryAddress }}
              </p>
            </div>

            <div class="">
              <p class="mt-2 text-sm text-gray-700 max-w-md my-8">
                <span class="block mb-1 font-bold"
                  >BILLING / SHIPPING ADDRESS,</span
                >
                {{ customerAddress }}
              </p>
            </div>
          </div>
          <p class="mt-2 text-sm text-gray-700 my-4" v-if="invoiceId">
            <span>Invoice No: </span>
            <span>{{ invoiceId }}</span>
          </p>

          <p class="mt-2 text-sm text-gray-700 max-w-md my-4">
            <span class="font-bold">GSTIN :</span>
            {{ companyDetails.gstin }}
          </p>
        </div>
      </div>
      <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="">
            <tr class="bg-indigo-600">
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 max-w-2xl md:block mx-4"
              >
                Product Name
              </th>
              <th
                scope="col"
                class="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
              >
                Unit Price
              </th>
              <th
                scope="col"
                class="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
              >
                Quantity
              </th>
              <th
                scope="col"
                class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-6 md:pr-0 md:block mx-4"
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border-b border-gray-200"
              v-for="(item, idx) in cartItems"
              :key="item.productId"
            >
              <td class="py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div class="font-medium text-gray-900 max-w-xl flex">
                  <!-- <div class="h-10 w-10 flex-shrink-0">
                    <img class="h-10 w-10" :src="item.mainImageUrl" alt="" />
                  </div> -->
                  <span class="">{{ idx + 1 }}.</span>
                  <span class="ml-2">{{ item.productName }}</span>
                  <span class="hidden xs:block"
                    >Qty: {{ item.cartQuantity }}</span
                  >
                </div>
              </td>
              <td
                class="hidden py-4 px-3 text-right text-sm text-gray-900 sm:table-cell"
              >
                ₹ {{ item.productSellingPrice }}
              </td>
              <td
                class="hidden py-4 px-3 text-right text-sm text-gray-900 sm:table-cell"
              >
                {{ item.cartQuantity }}
              </td>
              <td
                class="py-4 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0"
              >
                ₹ {{ item.cartQuantity * item.productSellingPrice }}
              </td>
            </tr>

            <!-- More projects... -->
          </tbody>
          <tfoot>
            <tr>
              <th
                scope="row"
                colspan="3"
                class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-900 sm:table-cell md:pl-0"
              >
                Subtotal
              </th>
              <th
                scope="row"
                class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-900 sm:hidden"
              >
                Subtotal
              </th>
              <td
                class="pl-3 pr-4 pt-6 text-right text-sm text-gray-900 sm:pr-6 md:pr-0"
              >
                ₹ {{ totalPrice }}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colspan="3"
                class="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-900 sm:table-cell md:pl-0"
              >
                Tax
              </th>
              <th
                scope="row"
                class="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-900 sm:hidden"
              >
                Tax
              </th>
              <td
                class="pl-3 pr-4 pt-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0"
              >
                0.0
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colspan="3"
                class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
              >
                Total
              </th>
              <th
                scope="row"
                v-if="discountedTotal <= 0"
                class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                Total
              </th>
              <th
                scope="row"
                v-if="discountedTotal > 0"
                class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                Discounted Total
              </th>
              <td
                class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                v-if="discountedTotal <= 0"
              >
                ₹ {{ totalPrice }}
              </td>
              <td
                class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                v-if="discountedTotal > 0"
              >
                ₹ {{ discountedTotal }}
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="mb-24">
          <p
            class="uppercase my-12 inline-block p-2 text-black"
            v-if="discountedTotal <= 0"
          >
            PAID {{ numTowords(totalPrice) }} only.
          </p>
          <p class="uppercase my-12" v-else>
            PAID {{ numTowords(discountedTotal) }} only.
          </p>
          <p v-if="note">Note: {{ note }}</p>
          <p class="text-sm mb-6">
            Play and return the games and get upto 70% off while exchanging next
            games. this only applicable only for preowned games
          </p>
          <p class="text-sm">
            For Any Queries Contact
            {{ companyDetails.customerSupportContact }} or leave a email at
            {{ companyDetails.customerSupportEmail }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogo from "@/assets/img/mainlogo.png";
import useInvoiceStore from "@/store/invoice.store";
import useAuthStore from "@/store/auth.store";
import useProductStore from "@/store/products.store";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId();
import numTowords from "num-words";
import html2pdf from "html2pdf.js";
import moment from "moment";
import useCompanyStore from "@/store/company.store";
import useToastStore from "@/store/toast.store";
import useCartStore from "@/store/cart.store";
import { mapActions, mapState } from "pinia";
export default {
  computed: {
    ...mapState(useCartStore, ["cartItems", "itemCount", "totalPrice"]),
    ...mapState(useCompanyStore, ["companyDetails"]),
    ...mapState(useAuthStore, ["userdetails"]),
    computeMarginBasedOnItemsInCart() {
      return this.cartItems.length / 2 == 0 ? 0.25 : 0.35;
    },
  },
  data() {
    return {
      moment,
      customerAddress: "",
      note: "",
      discountedTotal: 0,
      editMode: true,
      margin: 0.25,
      numTowords,
      documentName: "",
      invoiceId: null,
      MainLogo,
    };
  },
  methods: {
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useInvoiceStore, ["saveNewInvoiceDetails"]),
    ...mapActions(useCartStore, ["clearCartItemsAction"]),
    ...mapActions(useProductStore, [
      "updateProductDetailsByProductIdAction",
      "getProductDetailsByProductIdAction",
    ]),
    editUserAddress() {
      let person = prompt("Customer Address", this.customerAddress);
      if (person != null) {
        this.customerAddress = person;
      }
    },
    editInvoiceTotal() {
      let person = prompt("Invoice Total", this.totalPrice);
      if (person != null) {
        this.discountedTotal = person;
      }
    },
    addInvoiceNote() {
      let note = prompt(
        "Invoice Note",
        "30 Games Installed Still 10 Games needed to be installed"
      );
      if (note) {
        this.note = note;
      }
    },
    async saveNewManualGeneratedInvoiceDetails() {
      if (this.customerAddress.length <= 10) {
        this.error("Customer Address Should be atleast 10 characters long");
        return;
      }

      if (this.discountedTotal > this.totalPrice) {
        this.error(
          `Newly Added Invoice Total ${this.discountedTotal} Cannot be greater than total price of invoice ${this.totalPrice}`
        );
        return;
      }
      let currentLoggedUserId =
        this.userdetails && this.userdetails.userId
          ? this.userdetails.userId
          : null;
      let products = [];
      this.cartItems.forEach((item) => {
        let newProduct = {
          productId: item.productId,
          productName: item.productName,
          cartQuantity: item.cartQuantity,
          price: item.productSellingPrice,
          mainImageUrl: item.mainImageUrl,
        };
        products.push(newProduct);
      });
      let newInvoice = {
        invoiceId: "INVOICE_" + uid(),
        createdBy: currentLoggedUserId,
        updatedBy: null,
        updatedAt: null,
        createdAt: new Date(),
        products: products,
        note: this.note,
        customerAddress: this.customerAddress,
        discountTotal: Number(this.discountedTotal),
        totalPrice: this.totalPrice,
        generatedType: "MANUAL",
        gstin: this.companyDetails.gstin,
        companyName: this.companyDetails.companyName,
        companyAddress: this.companyDetails.companyPrimaryAddress,
      };
      await this.saveNewInvoiceDetails(newInvoice);
      products.forEach(async (product) => {
        let productId = product.productId;
        if (productId) {
          let productDB = await this.getProductDetailsByProductIdAction(
            productId
          );
          let dbProduct = {
            productId: productDB.id,
            ...productDB.data(),
          };
          if (Number(dbProduct.quantity) >= Number(product.cartQuantity)) {
            let qty = Number(dbProduct.quantity);
            qty = qty - Number(product.cartQuantity);
            dbProduct.quantity = qty.toString();
          }

          await this.updateProductDetailsByProductIdAction(dbProduct);
          this.clearCartItemsAction();
          this.$router.push("/invoices");
        }
      });
      this.documentName = newInvoice.invoiceId;
      this.invoiceId = newInvoice.invoiceId;
      this.exportToPDF();
    },
    exportToPDF() {
      this.editMode = false;
      html2pdf(this.$refs.document, {
        margin: this.computeMarginBasedOnItemsInCart,
        filename: this.documentName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
        pagebreak: {},
      });
    },
  },
  components: {
    html2pdf,
  },
};
</script>

<style></style>
