<template>
  <div class="">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-7xl mx-auto divide-y-2 divide-gray-200">
        <h2
          class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl mb-20"
        >
          Frequently asked questions
        </h2>
        <dl class="mt-6 space-y-6 divide-y divide-gray-200">
          <appFaqAccordian
            v-for="(faqData, idx) in faq"
            :key="idx"
            :faqData="{ key: faqData.question, value: faqData.awnser }"
            :isActive="idx == 0 ? true : false"
          >
            <!-- <template v-slot:svgIcon
              ><svg
                class="rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                /></svg></template
          >-->
          </appFaqAccordian>

          <!-- More questions... -->
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import FaqAccordian from "./Faq.accordian.vue";
import useCompanyStore from "@/store/company.store";
import { mapState, mapActions } from "pinia";
export default {
  computed: {
    ...mapState(useCompanyStore, ["companyDetails"]),
  },
  methods: {
    ...mapActions(useCompanyStore, ["getCompanyDetailsAction"]),
  },
  data() {
    return {
      contact:
        this.companyDetails && this.companyDetails.customerSupportContact
          ? this.companyDetails.customerSupportContact
          : null,
      supportMail:
        this.companyDetails && this.companyDetails.customerSupportEmail
          ? this.companyDetails.customerSupportEmail
          : null,
      faq: [
        {
          question: "Shipping & Delivery",
          awnser:
            "The Products will be delivered once the order is confirmed and dispatched. And will be delivered in 4 days.",
        },
        // {
        //   question: "Support",
        //   awnser: `contact ${this.contact} or contact ${this.supportMail}.`,
        // },
        {
          question: "How to update Contact or Profile Information",
          awnser:
            "Login into your Reygamecastle Account and Click on profile icon on top right corner of screen,select view profile, Click on Update Profile Detail button and update the information.",
        },
        {
          question:
            "How to Add new address or edit existing address or view addreess",
          awnser:
            "Login into your Reygamecastle Account and Click on profile icon on top right corner of screen,select view profile, Click on Add New Delivery Address button to add new address, Click Address tab next to profile tab to view all the saved address.",
        },
        {
          question: "How to Check Previous Orders",
          awnser:
            "Login into your Reygamecastle Account and Click on profile icon on top right corner of screen,select Orders Option.",
        },

        {
          question: "Swaping Terms & Condition",
          awnser:
            "You can swap your product with preowned products only. you cannot swap your product with new sealed products.",
        },
        {
          question: "Privacy Policy",
          awnser:
            "We are committed to protecting all the information you share with us. We follow stringent procedures to help protect the confidentiality, security, and integrity of data stored on our systems. our servers are accessible only to authorized personnel and that your information is shared with respective personnel on need to know basis to complete the transaction and to provide the services requested by you.",
        },
        {
          question: "Refund Policy",
          awnser:
            "Any request for refund is only entertained by visiting our store with order items.",
        },
        {
          question: "Order Cancellation Policy",
          awnser:
            "You Cannot cancel the order once the order is placed.So please review  your order before placing it.",
        },
      ],
    };
  },
  async created() {
    // let response = await this.getCompanyDetailsAction();

    this.contact = this.companyDetails.customerSupportEmail;
    this.supportMail = this.companyDetails.customerSupportContact;
  },
  components: {
    appFaqAccordian: FaqAccordian,
  },
};
</script>

<style></style>
