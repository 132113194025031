export const userdetailsState = {
  userId: null,
  emailId: null,
  username: null,
  isVerified: null,
  roleId: null,
  permissions: null,
  meta: {},
  username: null,
  about: null,
  profileImageUrl: null,
};

export const userdetails = {
  userId: null,
  emailId: null,
  username: null,
  isVerified: false,
  meta: {},
  username: null,
  about: null,
  profileImageUrl: null,
};

export const defaulStatusObject = {
  statusId: 0,
  statusName: "",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
  updatedBy: null,
};
