<template>
  <div class="space-y-6">
    <vee-form
      :validation-schema="schema"
      @submit="handleCompanyDetailsAddFormSubmission"
    >
      <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <h2 class="text-center text-2xl font-bold mb-12">
            Add / Edit Company Details
          </h2>
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="companyName"
                  class="block text-sm font-medium text-gray-700"
                  >Company Name</label
                >
                <vee-field
                  type="text"
                  name="companyName"
                  id="companyName"
                  v-model="company.companyName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="companyName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="ownerName"
                  class="block text-sm font-medium text-gray-700"
                  >Owner Name</label
                >
                <vee-field
                  type="text"
                  name="ownerName"
                  v-model="company.ownerName"
                  id="ownerName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="ownerName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="companyFacebookLink"
                  class="block text-sm font-medium text-gray-700"
                  >Facebook Link</label
                >
                <vee-field
                  type="text"
                  v-model="company.companyFacebookLink"
                  name="companyFacebookLink"
                  id="companyFacebookLink"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="companyFacebookLink"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="companyInstagramLink"
                  class="block text-sm font-medium text-gray-700"
                  >Instagram Link</label
                >
                <vee-field
                  type="text"
                  v-model="company.companyInstagramLink"
                  name="companyInstagramLink"
                  id="companyInstagramLink"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="companyInstagramLink"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="companyYoutubeLink"
                  class="block text-sm font-medium text-gray-700"
                  >Youtube Link</label
                >
                <vee-field
                  type="text"
                  name="companyYoutubeLink"
                  v-model="company.companyYoutubeLink"
                  id="companyYoutubeLink"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="companyYoutubeLink"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="gstin"
                  class="block text-sm font-medium text-gray-700"
                  >GSTIN</label
                >
                <vee-field
                  type="text"
                  name="gstin"
                  id="gstin"
                  v-model="company.gstin"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="gstin" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="customerSupportContact"
                  class="block text-sm font-medium text-gray-700"
                  >Customer Support Contact</label
                >
                <vee-field
                  type="text"
                  name="customerSupportContact"
                  id="customerSupportContact"
                  v-model="company.customerSupportContact"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="customerSupportContact"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="customerSupportContact"
                  class="block text-sm font-medium text-gray-700"
                  >Support Email Address</label
                >
                <vee-field
                  type="text"
                  name="customerSupportEmail"
                  id="customerSupportEmail"
                  v-model="company.customerSupportEmail"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="customerSupportEmail"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="companyPrimaryAddress"
                  class="block text-sm font-medium text-gray-700"
                  >Primary Address<span class="ml-2 text-xs text-yellow-500">
                    You Have Entered
                    {{ company.companyPrimaryAddress.length }} Characters.</span
                  ></label
                >
                <div class="mt-1">
                  <vee-field
                    as="textarea"
                    rows="4"
                    v-model="company.companyPrimaryAddress"
                    name="companyPrimaryAddress"
                    id="companyPrimaryAddress"
                    class="shadow-sm border py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="companyPrimaryAddress"
                    class="text-red-500 text-xs"
                  />
                </div>
              </div>
              <div class="col-span-6" v-if="company.companySecondaryAddress">
                <label
                  for="companySecondaryAddress"
                  class="block text-sm font-medium text-gray-700"
                  >Secondary Address<span class="ml-2 text-xs text-yellow-500">
                    You Have Entered
                    {{ company.companySecondaryAddress.length }}
                    Characters.</span
                  ></label
                >
                <div class="mt-1">
                  <vee-field
                    as="textarea"
                    rows="4"
                    v-model="company.companySecondaryAddress"
                    name="companySecondaryAddress"
                    id="companySecondaryAddress"
                    class="shadow-sm border py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="companySecondaryAddress"
                    class="text-red-500 text-xs"
                  />
                </div>
              </div>

              <!-- <div class="col-span-6 sm:col-span-3">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                  >Status</label
                >
                <select
                  id="country"
                  name="country"
                  autocomplete="country-name"
                  v-model="brand.brandStatus"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option>United States</option>
                  <option>Canada</option>
                  <option>Mexico</option>
                </select>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          :disabled="requestInProgress"
          :class="{ 'bg-red-500': requestInProgress }"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          @click.prevent="$router.push('/')"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import useAuthStore from "@/store/auth.store";
import useToastStore from "@/store/toast.store";
import useCompanyStore from "@/store/company.store";
import { mapActions, mapState } from "pinia";
import { CREATE_BRAND, READ_BRAND } from "@/assets/Permissions";
export default {
  computed: {
    ...mapState(useCompanyStore, ["companyDetails"]),
    ...mapState(useAuthStore, ["userdetails"]),
  },
  data() {
    return {
      CREATE_BRAND,
      READ_BRAND,
      isUserDataValid: false,
      company: {
        companyId: "",
        companyName: "",
        customerSupportEmail: "",
        companyPrimaryAddress: "",
        gstin: "",
        companySecondaryAddress: "",
        ownerName: "",
        companyFacebookLink: "",
        companyInstagramLink: "",
        companyYoutubeLink: "",
        customerSupportContact: "",
        createdBy: "",
        updatedBy: "",
        updatedAt: "",
        createdAt: "",
      },
      requestInProgress: false,
      schema: {
        companyName: "required|max:50|min:5",
        customerSupportEmail: "required|email",
        companyPrimaryAddress: "required|max:150|min:10",
        gstin: "required|max:15|min:15|alphaNumeric",
        ownerName: "required|max:50|min:5",
        companyFacebookLink: "required|url",
        companyInstagramLink: "required|url",
        companyYoutubeLink: "required|url",
        customerSupportContact: "required|max:10|min:10|numeric",
      },
    };
  },
  methods: {
    ...mapActions(useCompanyStore, [
      "getCompanyDetailsAction",
      "saveNewCompanyDetailsAction",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    async handleCompanyDetailsAddFormSubmission() {
      try {
        this.requestInProgress = true;
        this.company.companyId = "COMPANY_ID";
        if (!this.company.createdAt) {
          this.company.createdAt = new Date();
          this.company.createdBy =
            this.userdetails && this.userdetails.userId
              ? this.userdetails.userId
              : null;
        } else if (this.company.createdAt && !this.company.updatedAt) {
          this.company.updatedAt = new Date();
          this.company.updatedBy =
            this.userdetails && this.userdetails.userId
              ? this.userdetails.userId
              : null;
        }
        await this.saveNewCompanyDetailsAction(this.company);
        this.requestInProgress = false;
        await this.getCompanyDetailsAction();
        this.success("Successfully Added Details");
      } catch (err) {
        this.error(err.message);
        this.requestInProgress = false;
      }
      this.$router.push("/");
    },
  },
  async created() {
    await this.getCompanyDetailsAction();
    this.company = this.companyDetails;
  },
};
</script>

<style></style>
