<template>
  <div class="space-y-6">
    <vee-form :validation-schema="schema" @submit="handleStatusUpdate">
      <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="statusId"
                  class="block text-sm font-medium text-gray-700"
                  >Status Id</label
                >
                <vee-field
                  type="text"
                  name="statusId"
                  id="statusId"
                  autocomplete="family-name"
                  v-model="status.statusId"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="statusId" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="statusName"
                  class="block text-sm font-medium text-gray-700"
                  >Status Name</label
                >
                <vee-field
                  type="text"
                  name="statusName"
                  id="statusName"
                  autocomplete="family-name"
                  v-model="status.statusName"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="statusName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="createdAt"
                  class="block text-sm font-medium text-gray-700"
                  >Created At</label
                >
                <input
                  type="text"
                  name="createdAt"
                  id="createdAt"
                  readonly="readonly"
                  :value="
                    status.createdAt
                      ? moment(status.createdAt.toDate()).format(
                          `MMMM Do YYYY, h:mm:ss a`
                        )
                      : ''
                  "
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div class="col-span-6">
                <label
                  for="createdBy"
                  class="block text-sm font-medium text-gray-700"
                  >Created By</label
                >
                <vee-field
                  type="text"
                  name="createdBy"
                  id="createdBy"
                  autocomplete="family-name"
                  readonly="readonly"
                  v-model="status.createdBy"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <!-- <div class="col-span-6">
                <label
                  for="updatedAt"
                  class="block text-sm font-medium text-gray-700"
                  >Updated At</label
                >
                <input
                  type="text"
                  name="updatedAt"
                  id="updatedAt"
                  readonly="readonly"
                  :value="
                    status.updatedAt
                      ? moment(status.updatedAt.toDate()).format(
                          `MMMM Do YYYY, h:mm:ss a`
                        )
                      : ''
                  "
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div> -->
              <div class="col-span-6">
                <label
                  for="updatedBy"
                  class="block text-sm font-medium text-gray-700"
                  >Updated By</label
                >
                <vee-field
                  type="text"
                  name="updatedBy"
                  id="updatedBy"
                  autocomplete="family-name"
                  readonly="readonly"
                  v-model="status.updatedBy"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          @click.prevent="handleCancelSaveStatus"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import moment from "moment";
import useAuthStore from "@/store/auth.store";
import useStatusStore from "@/store/status.store";
import useToastStore from "@/store/toast.store";
import { mapActions, mapState } from "pinia";
export default {
  data() {
    return {
      moment,
      status: {
        statusName: "",
        statusId: "",
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
      },
      schema: {
        statusName: "required|min:3|max:25",
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userdetails", "userId"]),
  },
  methods: {
    ...mapActions(useStatusStore, [
      "getStatusDetailsByStatusIdAction",
      "updateStatusDetailByStatusIdAction",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    handleCancelSaveStatus() {
      this.$router.push("/status");
    },
    async handleStatusUpdate() {
      let updatedStatus = {
        ...this.status,
        updatedBy: this.userId,
        updatedAt: new Date(),
      };
      await this.updateStatusDetailByStatusIdAction(
        this.status.statusId,
        updatedStatus
      );
      this.success("Successfully Updated Status Details");
      this.$router.replace("/status");
    },
  },
  async created() {
    let statusId = this.$route.params.statusId;
    let status = await this.getStatusDetailsByStatusIdAction(statusId);
    // if (status) {
    this.status = status;
    // } else {
    //   this.error("Unable to Find Status ");
    // }
  },
};
</script>

<style></style>
