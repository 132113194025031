<template>
  <nav
    aria-label="Offers"
    class="order-last lg:order-first max-w-7xl mx-auto md:block hidden mt-12"
  >
    <div class="lg:px-8">
      <ul
        role="list"
        class="grid grid-cols-1 divide-y divide-gray-200 lg:grid-cols-4 lg:divide-y-0 lg:divide-x border"
      >
        <li class="flex flex-col" v-for="feature in features" :key="feature">
          <a
            href="javascript:void(0)"
            class="relative flex-1 flex flex-col justify-center bg-white py-6 px-4 text-center focus:z-10 cursor-default"
          >
            <p class="font-semibold text-gray-900">{{ feature.title }}</p>
            <p class="text-sm text-gray-500">
              {{ feature.desc }}
            </p>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          title: "Buy",
          desc: "Buy any games, consoles at reasonable price compaired to market",
        },
        {
          title: "Play",
          desc: "Play the purchased games as long you wish",
        },
        {
          title: "Trade",
          desc: "Swap! purchased games with other games(Preowned) and get upto 70% off",
        },
        {
          title: "Repeat",
          desc: "Repeat the process so that you can play any games at low cost.",
        },
      ],
    };
  },
};
</script>

<style></style>
