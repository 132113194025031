<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-3xl font-semibold text-gray-900">Invoices</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the invoices which are saved in database
        </p>
        <p>{{ invoices.length }}</p>
        <div class="">
          <label class="text-sm">Search By Date</label>
          <Datepicker
            class="pl-2 pr-2 h-10 md:w-48 text-sm rounded-lg text-gray-600 border border-gray-200 focus:border-gray-300 focus:outline-none focus:shadow-inner leading-none"
            v-model="daterange"
            :clearable="true"
          />
        </div>
      </div>

      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <div class="max-w-sm mt-4">
          <label class="text-sm">Search By Invoice ID</label>
          <input
            type="search"
            class="mt-1 w-full pl-5 pr-2 h-10 py-1 rounded-lg border border-gray-200 focus:border-gray-300 focus:outline-none focus:shadow-inner leading-none"
            placeholder="Search By Invoice Id eg: INVOICE_V4iIU7 "
            v-on:keyup.enter="handleSearchInvoiceByInvoiceId"
            v-model="invoiceIdText"
          />
        </div>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="relative overflow-hidden ring-1 ring-black ring-opacity-5"
          >
            <!-- Selected row actions, only show when rows are selected. -->
            <!-- <div
              class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16"
            >
              <button
                type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              >
                Bulk edit
              </button>
              <button
                type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              >
                Delete all
              </button>
            </div> -->

            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </th>
                  <th
                    scope="col"
                    class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created By
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <!-- Selected: "bg-gray-50" -->
                <TableRowSkeleton v-if="invoices.length === 0" />
                <tr
                  v-else
                  v-for="invoice in invoices"
                  :key="invoice.invoiceId"
                  :id="invoice.invoiceId"
                >
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <!-- Selected row marker, only show when row is selected. -->
                    <div
                      class="absolute inset-y-0 left-0 w-0.5 bg-transparent focus:bg-indigo-600"
                    ></div>

                    <input
                      :value="invoice.invoiceId"
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </td>
                  <!-- Selected: "text-indigo-600", Not Selected: "text-gray-900" -->
                  <td
                    class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <router-link
                      :to="{
                        name: 'INVOICE_DETAILS',
                        params: { invoiceId: invoice.invoiceId },
                      }"
                    >
                      {{ invoice.invoiceId }}</router-link
                    >
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      invoice.createdAt
                        ? moment(invoice.createdAt.toDate()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : ""
                    }}
                  </td>

                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:underline hover:cursor-pointer"
                    @click.prevent="toggleUserSidebarDetails(invoice.createdBy)"
                  >
                    {{ invoice.createdBy }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <button
                      @click.prevent="
                        deleteInvoiceByInvoiceId(invoice.invoiceId)
                      "
                      class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                <!-- More people... -->
              </tbody>
            </table>
          </div>
          <div v-if="invoices.length > 0">
            <p class="mt-2 text-sm text-gray-700">
              Total Invoices Amount Generated ₹{{ handleInvoiceTotal() }}
            </p>
          </div>
          <div
            v-if="!requestInProgress && invoices.length == 0"
            class="p-4 font-robotoSlab border"
          >
            No Invoices has been generated.....
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useToastStore from "@/store/toast.store";
import moment from "moment";
import useAuthStore from "@/store/auth.store";
import useInvoiceStore from "@/store/invoice.store";
import { hasUserPermissions } from "@/utils/auth.util";
import { mapActions, mapState, mapWritableState } from "pinia";
import { CREATE_INVOICE, CREATE_ONLY_INVOICE } from "@/assets/Permissions";
export default {
  computed: {
    ...mapState(useInvoiceStore, ["invoices", "filters"]),
    ...mapState(useAuthStore, ["userdetails"]),
    ...mapWritableState(useInvoiceStore, ["isFiltersApplied"]),
  },
  data() {
    return {
      CREATE_INVOICE,
      CREATE_ONLY_INVOICE,
      requestInProgress: false,
      daterange: null,
      startDate: null,
      endDate: null,
      moment,
      isFilterOn: false,
      invoiceTotal: 0,
      invoiceIdText: null,
    };
  },
  watch: {
    async daterange(newVal, oldVal) {
      if (newVal != oldVal) {
        let startDate = new Date(
          newVal.getFullYear(),
          newVal.getMonth(),
          newVal.getDate()
        );
        let endDate = new Date(
          newVal.getFullYear(),
          newVal.getMonth(),
          newVal.getDate()
        );

        endDate.setDate(endDate.getDate() + 1);

        let userId =
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userI
            : null;
        this.requestInProgress = true;
        await this.applyInvoiceFilterDateAction(userId, startDate, endDate);
        this.requestInProgress = false;
        this.isFilterOn = this.isFiltersApplied;
      }
    },
  },
  methods: {
    ...mapActions(useInvoiceStore, [
      "getPaginatedInvoicesAction",
      "getInvoiceByDateAction",
      "applyInvoiceFilterDateAction",
      "deleteInvoiceByInvoiceIdAction",
      "searchInvoiceByInvoiceIDAction",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useAuthStore, ["toggleUserInfoSidebarAction"]),
    async handleSearchInvoiceByInvoiceId() {
      this.isFilterOn = true;
      if (this.invoiceIdText) {
        this.invoiceIdText = this.invoiceIdText.trim();
        await this.searchInvoiceByInvoiceIDAction(this.invoiceIdText);
      }
    },
    handleInvoiceTotal() {
      let total = 0;
      if (this.invoices instanceof Array) {
        this.invoices.forEach((invoice) => {
          if (invoice.discountTotal > 0) {
            total += Number(invoice.discountTotal);
          } else {
            total += Number(invoice.totalPrice);
          }
        });
        this.invoiceTotal = total;
        return total;
      }
    },
    async deleteInvoiceByInvoiceId(invoiceId) {
      let shouldInvoiceBeDeleted = confirm(
        `Are you Sure you wanna delete invoice by invoice id ${invoiceId}`
      );
      if (shouldInvoiceBeDeleted) {
        try {
          await this.deleteInvoiceByInvoiceIdAction(invoiceId);
          this.success(
            `Successfully Deleted Invoice by invoice id ${invoiceId}`
          );
          this.requestInProgress = true;
          if (!this.isFilterOn) {
            await this.getPaginatedInvoicesAction(
              userId,
              "createdAt",
              "desc",
              10,
              this.startDate,
              this.endDate
            );
          }

          this.requestInProgress = false;
        } catch (err) {
          this.error(`Failed to delete the Invoice ${err.message}`);
        }
      }
    },
    async handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;
      let userId =
        this.userdetails && this.userdetails.userId
          ? this.userdetails.userI
          : null;
      let ele = document.getElementById("footerSection");
      let bottom =
        Math.round(scrollTop) + innerHeight >= offsetHeight - ele.offsetHeight;

      if (bottom) {
        if (!this.requestInProgress) {
          this.requestInProgress = true;
          // if (!this.isFilterOn) {
          await this.fetchListOfInvoices();
          //    }

          this.requestInProgress = false;
        }
      }
    },
    async fetchListOfInvoices() {
      let userId =
        this.userdetails && this.userdetails.userId
          ? this.userdetails.userId
          : null;
      if (
        hasUserPermissions(CREATE_INVOICE) ||
        hasUserPermissions(CREATE_ONLY_INVOICE)
      ) {
        await this.getPaginatedInvoicesAction(
          null,
          "createdAt",
          "desc",
          10,
          this.startDate,
          this.endDate
        );
      } else {
        await this.getPaginatedInvoicesAction(
          userId,
          "createdAt",
          "desc",
          10,
          this.startDate,
          this.endDate
        );
      }
    },
    async toggleUserSidebarDetails(userId = null) {
      if (userId) {
        await this.toggleUserInfoSidebarAction(userId);
      }
    },
  },
  async created() {
    // this.isFilterOn = this.isFiltersApplied;
    if (!this.isFiltersApplied) {
      await this.fetchListOfInvoices();
    }

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style></style>
