<template>
  <tr>
    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
      <!-- Selected row marker, only show when row is selected. -->
      <div
        class="absolute inset-y-0 left-0 w-0.5 bg-transparent focus:bg-indigo-600"
      ></div>

      <input
        value="category.categoryId"
        type="checkbox"
        v-if="doesUserHasPermission()"
        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
      />
    </td>
    <!-- Selected: "text-indigo-600", Not Selected: "text-gray-900" -->
    <td
      class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900"
      id="categoryName"
    >
      <span class="flex items-center"
        ><img
          :src="category.categoryLogoURL"
          :alt="category.categoryLogoURL"
          class="h-6 w-6 mr-2 object-contain"
        />
        {{ category.categoryName }}</span
      >
    </td>
    <td
      class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
    >
      <router-link
        v-if="doesUserHasPermission()"
        href="#"
        class="inline-flex mr-2 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        :to="{
          name: 'GET_CATEGORY_BY_ID',
          params: { categoryId: category.categoryId },
        }"
        >Edit</router-link
      >
      <button
        v-if="doesUserHasPermission()"
        @click.prevent="
          handleDeleteCategoryDetails(
            category.categoryId,
            category.categoryName
          )
        "
        class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
import { hasUserPermissions } from "@/utils/auth.util";
import { CREATE_CATEGORY, CREATE_ONLY_CATEGORY } from "@/assets/Permissions";
import { useToast } from "vue-toastification";
import useCategoryStore from "@/store/categories.store";
import { mapActions, mapState } from "pinia";
let toast = useToast();
export default {
  ...mapState(useCategoryStore, ["categories"]),
  props: {
    category: {
      type: Object,
      default: {
        categoryName: "Test Category",
      },
    },
  },
  methods: {
    ...mapActions(useCategoryStore, [
      "getAllSavedCategoriesAction",
      "deleteCategoryDetailsByCategoryIdAction",
    ]),
    doesUserHasPermission() {
      return (
        hasUserPermissions(CREATE_CATEGORY) ||
        hasUserPermissions(CREATE_ONLY_CATEGORY)
      );
    },
    async handleDeleteCategoryDetails(categoryId, categoryName) {
      try {
        let response = confirm(
          `Are You Sure you want to delete category ${categoryName}.`
        );
        if (response) {
          await this.deleteCategoryDetailsByCategoryIdAction(
            categoryId,
            categoryName
          );
          toast.success("Successfully Deleted Category ");
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let hasPermission =
      hasUserPermissions(CREATE_CATEGORY) ||
      hasUserPermissions(CREATE_ONLY_CATEGORY);
    if (hasPermission) {
      next();
    } else {
      next("/");
    }
  },
};
</script>

<style></style>
