<template>
  <div class="flex items-center text-sm font-medium text-indigo-600">
    <!-- Heroicon name: outline/truck -->

    <slot></slot>
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "No title message has been pro",
    },
  },
};
</script>

<style></style>
