<template>
  <div class="bg-white">
    <!-- max-w-2xl lg:max-w-7xl lg:px-8 -->

    <div class="mx-auto pt-4 pb-24 px-4 sm:px-6">
      <h1
        class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
      >
        Gaming Cart
      </h1>
      <p class="mt-2 text-sm text-gray-700">
        Total {{ itemCount }} products founds in your cart
      </p>

      <form
        v-if="cartItems.length > 0"
        class="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16"
      >
        <section aria-labelledby="cart-heading" class="lg:col-span-7">
          <h2 id="cart-heading" class="sr-only">Items in your shopping cart</h2>

          <ul
            role="list"
            class="text-sm font-medium text-gray-900 divide-y divide-gray-200"
          >
            <CartItem
              v-for="product in cartItems"
              :key="product.productId"
              :product="product"
            />

            <!-- More products... -->
          </ul>
        </section>

        <!-- Order summary -->
        <CartOrderSummary />
      </form>
      <NoItemsComponent
        v-else
        title="No Games in Cart"
        message="Your cart is empty. Keep shopping to find a Games, Consoles, Accessories!"
      />
    </div>
  </div>
</template>

<script>
import NoItemsComponent from "@/components/Commons/Alerts/NoItems.alert.vue";
import useCartStore from "@/store/cart.store";
import CartItem from "@/components/Cart/Cart.item.vue";
import useProductStore from "@/store/products.store";
import CartOrderSummary from "@/components/Cart/Cart.Order.summary.vue";
import { mapActions, mapState } from "pinia";
export default {
  computed: {
    ...mapState(useProductStore, ["products"]),
    ...mapState(useCartStore, ["cartItems", "itemCount", "totalPrice"]),
  },
  data() {
    return {};
  },
  components: {
    CartItem,
    CartOrderSummary,
    NoItemsComponent,
  },
  methods: {
    ...mapActions(useCartStore, ["loadCartItemsFromLocalStorageAction"]),
  },
  created() {
    document.title = "Gaming Cart | Reygamescastle";
  },
};
</script>

<style></style>
