<template>
  <section
    aria-labelledby="perks-heading"
    class="bg-white border-t border-white md:block sm:hidden"
  >
    <h2 id="perks-heading" class="sr-only">Our perks</h2>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 sm:py-32 lg:px-8">
      <div
        class="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-0"
      >
        <div
          class="text-center md:flex md:items-start md:text-left lg:block lg:text-center"
        >
          <div class="md:flex-shrink-0">
            <div class="flow-root">
              <img
                class="-my-1 h-24 w-auto mx-auto"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxR6LJ4cY3rI9BqW-kkbr1T35fLfL4pW78bgKMlfX8rRdI5ikq1o1HesqwrRWvHchJF-Q&usqp=CAU"
                alt=""
              />
            </div>
          </div>
          <div class="mt-6 md:mt-0 md:ml-4 lg:mt-6 lg:ml-0">
            <h3
              class="text-sm font-semibold tracking-wide uppercase text-gray-900"
            >
              Buy
            </h3>
            <p class="mt-3 text-sm text-gray-500">
              Buy any games, consoles, Accessories
            </p>
          </div>
        </div>

        <div
          class="text-center md:flex md:items-start md:text-left lg:block lg:text-center"
        >
          <div class="md:flex-shrink-0">
            <div class="flow-root">
              <img
                class="-my-1 h-24 w-auto mx-auto"
                src="https://i.etsystatic.com/25760358/r/il/76ab0c/2711637734/il_fullxfull.2711637734_l1qp.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="mt-6 md:mt-0 md:ml-4 lg:mt-6 lg:ml-0">
            <h3
              class="text-sm font-semibold tracking-wide uppercase text-gray-900"
            >
              Play
            </h3>
            <p class="mt-3 text-sm text-gray-500">Play as long as you wish</p>
          </div>
        </div>

        <div
          class="text-center md:flex md:items-start md:text-left lg:block lg:text-center"
        >
          <div class="md:flex-shrink-0">
            <div class="flow-root">
              <img
                class="-my-1 h-24 w-auto mx-auto"
                src="https://cdn-icons-png.flaticon.com/512/7481/7481067.png"
                alt=""
              />
            </div>
          </div>
          <div class="mt-6 md:mt-0 md:ml-4 lg:mt-6 lg:ml-0">
            <h3
              class="text-sm font-semibold tracking-wide uppercase text-gray-900"
            >
              Return
            </h3>
            <p class="mt-3 text-sm text-gray-500">
              Return and get upto 70% off on purchase of next game
            </p>
          </div>
        </div>

        <div
          class="text-center md:flex md:items-start md:text-left lg:block lg:text-center"
        >
          <div class="md:flex-shrink-0">
            <div class="flow-root">
              <img
                class="-my-1 h-24 w-auto mx-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Repeat_font_awesome.svg/1200px-Repeat_font_awesome.svg.png"
                alt=""
              />
            </div>
          </div>
          <div class="mt-6 md:mt-0 md:ml-4 lg:mt-6 lg:ml-0">
            <h3
              class="text-sm font-semibold tracking-wide uppercase text-gray-900"
            >
              Repeat
            </h3>
            <p class="mt-3 text-sm text-gray-500">Repeat the process</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
