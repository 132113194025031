<template>
  <SidebarFlyout
    :isOverlayActive="isOverlayActive"
    v-if="isOverlayActive"
    :isSidebarBarFlyoutActive="isSidebarBarFlyoutActive"
    @flyoutOverlayClicked="handleFlyoutOverlayClicked"
  />
  <div class="font-roboto bg-slate-50">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <!--
    This example requires Tailwind CSS v2.0+ 
    
    This example requires some changes to your config:
    
    ```
    // tailwind.config.js
    module.exports = {
      // ...
      plugins: [
        // ...
        require('@tailwindcss/forms'),
      ],
    }
    ```
  -->
    <div class="bg-transparent">
      <!--
      Mobile filter dialog
  
      Off-canvas filters for mobile, show/hide based on off-canvas filters state.
    -->

      <div class="max-w-7xl mx-auto px-4 py-4 sm:px-6">
        <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">
          Showing {{ products.length }} matching Search Results...
        </h1>
        <p class="mt-4 max-w-xl text-sm text-gray-700">
          Showing search results and keep scrolling for more products
        </p>
      </div>

      <!-- Filters -->
      <section aria-labelledby="filter-heading">
        <div
          class="relative col-start-1 row-start-1 py-4"
          v-if="isProductFilterOn.isFilterOn"
        >
          <div
            class="max-w-7xl mx-auto flex space-x-6 divide-x divide-gray-200 text-sm px-4 sm:px-6 lg:px-8"
          >
            <div>
              <button
                type="button"
                class="group text-gray-700 font-medium flex items-center"
                aria-controls="disclosure-1"
                aria-expanded="false"
                v-if="isProductFilterOn.filters.length"
              >
                <!-- Heroicon name: solid/filter -->
                <svg
                  class="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clip-rule="evenodd"
                  />
                </svg>
                {{ isProductFilterOn.filters.length }} Filters
              </button>
            </div>
            <div class="pl-6">
              <button
                type="button"
                @click.prevent="handleClearProductFilters"
                class="text-gray-500"
              >
                Clear all Filters
              </button>
            </div>
          </div>
        </div>
        <h2 id="filter-heading" class="sr-only">Filters</h2>

        <div class="relative z-10 bg-transparent border-b border-gray-200 pb-4">
          <div
            class="max-w-7xl mx-auto px-4 flex items-center justify-between sm:px-6 lg:px-8"
          >
            <div class="relative inline-block text-left">
              <appProductSortMenu :isActive="toggleSortMenu" />
            </div>

            <!-- Mobile filter dialog toggle, controls the 'mobileFiltersOpen' state. -->
            <button
              type="button"
              class="text-sm font-medium text-gray-700 hover:text-gray-900 flex"
              @click.prevent="
                () => {
                  isOverlayActive = true;
                  isSidebarBarFlyoutActive = true;
                }
              "
            >
              <svg
                class="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clip-rule="evenodd"
                />
              </svg>
              Filters
            </button>
          </div>
        </div>
      </section>
    </div>

    <div
      class="grid gap-x-6 lg:grid-cols-5 md:grid-cols-3 gap-y-10 sm:grid-cols-2 grid-cols-1 gap-4 2xl:max-w-7xl 2xl:mx-auto mt-24 2xl:px-0"
    >
      <ProductCard
        v-for="product in products"
        :key="product"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import SidebarFlyout from "@/components/Commons/Flyouts/Sidebar.flyout.vue";
import useProductStore from "@/store/products.store";
import { hasUserPermissions } from "@/utils/auth.util";
import { CREATE_ONLY_PRODUCT, CREATE_PRODUCT } from "@/assets/Permissions";
import { mapActions, mapState, mapWritableState } from "pinia";
// import SidebarFilter from "@/components/Filters/Sidebar.filter.vue";
import ProductCard from "@/components/Products/Product.card.vue";
export default {
  name: "PRODUCT_SEARCH_RESULTS_COMPONENT",
  mounted() {},
  async created() {
    await this.fetchProductsPaginated();
    window.addEventListener("scroll", this.handleScroll);
  },

  computed: {
    ...mapWritableState(useProductStore, [
      "products",
      "filters",
      "isProductFilterOn",
    ]),
  },
  components: {
    ProductCard,
    // SidebarFilter,
    SidebarFlyout,
  },
  data() {
    return {
      toggleBtn: false,
      toggleFilterSidebar: false,
      toggleSortMenu: false,
      isOverlayActive: false,
      requestInProgress: false,
      hasUserPermissions,
      CREATE_ONLY_PRODUCT,
      CREATE_PRODUCT,
    };
  },
  methods: {
    ...mapActions(useProductStore, [
      "searchProductsAction",
      "fetchPaginatedProductsAction",
      "clearSidebarProductFilterAction",
      "getProductsByQuatityAction",
    ]),
    handleOverlayClick() {
      this.toggleSortMenu = false;
      this.isOverlayActive = false;
    },
    handleFlyoutOverlayClicked(isActive) {
      this.isOverlayActive = isActive;
    },
    async handleClearProductFilters() {
      this.clearSidebarProductFilterAction();
      await this.fetchProductsPaginated();
    },
    async showOutOfStockProducts() {
      await this.getProductsByQuatityAction(0);
    },

    async fetchProductsPaginated() {
      let {
        categoryId,
        brandId,
        type,
        genre,
        condition,
        discount,
        searchText,
        sortBy = "createdAt",
        sortOrder = "asc",
      } = this.$route.query;
      if (this.filters.query !== this.$route.query) {
        this.products = [];
        await this.fetchPaginatedProductsAction(
          10,
          type,
          null,
          sortBy,
          sortOrder,
          condition,
          categoryId,
          brandId,
          genre,
          searchText
        );
        this.filters.isFilterApplied = true;
        this.filters.query = this.$route.query;
      }
    },

    async handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;

      let ele = document.getElementById("footerSection");
      let bottom =
        Math.round(scrollTop) + innerHeight >= offsetHeight - ele.offsetHeight;

      if (bottom && !this.isProductFilterOn.isFilterOn) {
        if (!this.requestInProgress) {
          let {
            categoryId,
            brandId,
            type,
            genre,
            condition,
            discount,
            searchText,
            sortBy = "createdAt",
            sortOrder = "asc",
          } = this.$route.query;
          this.requestInProgress = true;
          await this.fetchPaginatedProductsAction(
            10,
            type,
            null,
            sortBy,
            sortOrder,
            condition,
            categoryId,
            brandId,
            genre,
            searchText
          );
          this.requestInProgress = false;
        }
      }
    },
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style></style>
