<template>
  <Carousel
    id="gallery"
    :items-to-show="1"
    :wrap-around="true"
    v-model="currentSlide"
  >
    <Slide v-for="slide in galleryImages" :key="slide">
      <div class="sm:order-0 h-[450px] sm:mb-24 md:mb-2 w-full">
        <div class="max-w-xl sm:items-center h-[450px] md:max-w-auto">
          <img
            class="h-full w-full object-contain"
            :src="galleryImages[currentSlide]"
            alt=""
          />
        </div>
      </div>
    </Slide>
  </Carousel>

  <Carousel
    id="thumbnails"
    :items-to-show="4"
    :wrap-around="true"
    v-model="currentSlide"
    ref="carousel"
  >
    <Slide v-for="(slide, idx) in galleryImages" :key="slide">
      <div
        class="carousel__item h-full w-full md:mt-4 sm:mt-2"
        @click="slideTo(idx)"
      >
        <button
          type="button"
          class="flex-0 aspect-square mb-3 lg:h-32 overflow-hidden text-center"
        >
          <img class="h-full w-full object-cover" :src="slide" alt="" />
        </button>
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Gallery",
  props: {
    galleryImages: {
      default: [],
      required: true,
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    currentSlide: 0,
  }),
  methods: {
    slideTo(val) {
      this.currentSlide = val;
    },
  },
});
</script>
