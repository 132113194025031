<template>
  <div class="bg-slate-50">
    <router-view :key="$route.fullPath" />
    <!-- <SlotOverLay :isOverlayActive="isUserOffline">
      <p>This is offline message</p>
    </SlotOverLay> -->
  </div>
</template>

<script>
import useToastStore from "@/store/toast.store";
import useAuthStore from "@/store/auth.store";
import useCartStore from "@/store/cart.store";
import useCategoriesStore from "@/store/categories.store";
import useBrandStore from "@/store/brands.store";
import useGenreStore from "@/store/genre.store";
import useStatusStore from "@/store/status.store";
import useProductStore from "@/store/products.store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { auth } from "@/firebase/firebase.config";
import { Country, State, City } from "country-state-city";

export default {
  data() {
    return {
      isUserOffline: false,
    };
  },
  computed: {
    ...mapWritableState(useAuthStore, [
      "userId",
      "isUserLoggedIn",
      "userdetails",
      "isLogginOrSignupInProgress",
    ]),
  },
  methods: {
    ...mapActions(useAuthStore, [
      "setUserLoggedInToTrueAction",
      "preLogginUserProcess",
      "addCountriesDataToStoreAction",
      "updateUserDetailsToLocalStore",
      "processUserLogoutAction",
      "mergeLoggedInUserDetailsToLocalState",
    ]),
    ...mapActions(useToastStore, ["error", "success"]),
    ...mapActions(useCartStore, ["loadCartItemsFromLocalStorageAction"]),
    ...mapActions(useCategoriesStore, ["getAllSavedCategoriesAction"]),
    ...mapState(useCategoriesStore, ["organiseCategoriesGetter"]),
    ...mapActions(useBrandStore, ["fetchAllBrands"]),
    ...mapActions(useStatusStore, ["fetchAllStatusAction"]),
    ...mapActions(useGenreStore, ["fetchAllGenreAction"]),
    ...mapActions(useProductStore, ["loadGamesByCategoryAction"]),
    handleInternetConnectionDisconnected() {
      this.isUserOffline = true;
      this.error("Your Internet Connection Has been disconnected");
      if (this.isLogginOrSignupInProgress) {
        this.isLogginOrSignupInProgress = false;
      }
    },
    handleInternetConnectionRestored() {
      this.isUserOffline = false;
      this.success("Internet Connection Restored");
    },
  },
  async created() {
    window.addEventListener(
      "offline",
      this.handleInternetConnectionDisconnected
    );
    window.addEventListener("online", this.handleInternetConnectionRestored);
    if (auth.currentUser) {
      this.isLogginOrSignupInProgress = true;
      await this.mergeLoggedInUserDetailsToLocalState();

      this.isLogginOrSignupInProgress = false;
    } else {
      this.userdetails = {};
    }
    await this.getAllSavedCategoriesAction();
    await this.fetchAllBrands();
    await this.fetchAllStatusAction();
    await this.fetchAllGenreAction();
    await this.loadGamesByCategoryAction();

    this.addCountriesDataToStoreAction(Country.getAllCountries());
    // this.$router.push(this.$router.options.history.state.back.toString());
  },
  beforeUnmount() {
    window.removeEventListener(
      "offline",
      this.handleInternetConnectionDisconnected
    );
    window.removeEventListener("online", this.handleInternetConnectionRestored);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Reygamescastle";
      },
    },
  },
};
</script>
<style></style>
