<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Invoice</h1>

        <p class="mt-2 text-sm text-gray-700 my-4" v-if="isOrderDelivered">
          <span>Invoice Date: </span>
          <time datetime="2022-08-01"
            >{{
              moment(
                order.orderStatus[
                  order.orderStatus.length - 1
                ].createdAt.toDate()
              ).format("MMMM Do YYYY, h:mm a")
            }}
          </time>
        </p>
        <p class="mt-2 text-sm text-gray-700 max-w-md my-8">
          <span class="block mb-1 font-bold">Invoice issued by</span>
          <span class="block text-md uppercase">{{
            companyDetails.companyName
          }}</span>
          {{ companyDetails.companyPrimaryAddress }}
        </p>
        <p class="mt-2 text-sm text-gray-700 max-w-md my-4">
          <span class="font-bold">GSTIN :</span>
          {{ companyDetails.gstin }}
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <p class="mt-2 text-sm text-gray-700 max-w-md my-8">
          <span class="block mb-1 font-bold">Customer,</span>
          <span class="block"
            >{{ order.deliveryAddress.firstName }}
            {{ order.deliveryAddress.lastName }}</span
          >
          <span class="block"
            >{{ order.deliveryAddress.addressLine1 }}
            {{ order.deliveryAddress.addressLine2 }}
            {{ order.deliveryAddress.city }} {{ order.deliveryAddress.state }}
            {{ order.deliveryAddress.country.name }}</span
          >
          <span class="block"
            >Pincode : {{ order.deliveryAddress.pincode }}</span
          >
        </p>
      </div>
    </div>
    <div class="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-indigo-500">
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0 max-w-2xl"
            >
              Product Name
            </th>
            <th
              scope="col"
              class="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Unit Price
            </th>
            <th
              scope="col"
              class="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Quantity
            </th>
            <th
              scope="col"
              class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b border-gray-200"
            v-for="item in order.products"
            :key="item.productId"
          >
            <td class="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
              <router-link
                :to="{
                  name: 'PRODUCT_DETAILS',
                  params: { productId: item.productId },
                }"
                class="font-medium text-gray-900 max-w-xl flex"
              >
                <div class="h-10 w-10 flex-shrink-0">
                  <img class="h-10 w-10" :src="item.mainImageUrl" alt="" />
                </div>
                <span class="ml-2">{{ item.productName }}</span>
              </router-link>
            </td>
            <td
              class="hidden py-4 px-3 text-right text-sm text-gray-900 sm:table-cell"
            >
              ₹ {{ item.price }}
            </td>
            <td
              class="hidden py-4 px-3 text-right text-sm text-gray-900 sm:table-cell"
            >
              {{ item.quantity }}
            </td>
            <td
              class="py-4 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0"
            >
              ₹ {{ item.quantity * item.price }}
            </td>
          </tr>

          <!-- More projects... -->
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="row"
              colspan="3"
              class="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
            >
              Subtotal
            </th>
            <th
              scope="row"
              class="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-800 sm:hidden"
            >
              Subtotal
            </th>
            <td
              class="pl-3 pr-4 pt-6 text-right text-sm text-gray-800 sm:pr-6 md:pr-0"
            >
              ₹ {{ order.amount }}
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              colspan="3"
              class="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
            >
              Tax
            </th>
            <th
              scope="row"
              class="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
            >
              Tax
            </th>
            <td
              class="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0"
            >
              0.0
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              colspan="3"
              class="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
            >
              Total
            </th>
            <th
              scope="row"
              class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
            >
              Total
            </th>
            <td
              class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
            >
              ₹ {{ order.amount }}
            </td>
          </tr>
        </tfoot>
      </table>
      <div class="mb-24">
        <p
          class="uppercase my-12 inline-block p-2 text-black"
          v-if="order.amount >= 0"
        >
          PAID {{ numTowords(order.amount) }} only.
        </p>
        <p v-if="note">Note: {{ note }}</p>
        <p class="text-sm mb-6">
          Play and return the games and get upto 70% off while exchanging next
          games. this only applicable only for preowned games
        </p>
        <p class="text-sm">
          For Any Queries Contact
          {{ companyDetails.customerSupportContact }} or leave a email at
          {{ companyDetails.customerSupportEmail }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import numTowords from "num-words";
import html2pdf from "html2pdf.js";
import useOrderStore from "@/store/orders.store";
import useCompanyStore from "@/store/company.store";
import useCartStore from "@/store/cart.store";
import { mapState, mapActions } from "pinia";
export default {
  computed: {
    ...mapState(useCartStore, ["cartItems"]),
    ...mapState(useCompanyStore, ["companyDetails"]),
    isOrderDelivered() {
      if (
        this.order &&
        this.order.orderStatus &&
        this.order.orderStatus.length
      ) {
        return this.order.orderStatus[this.order.orderStatus.length - 1]
          .status === "ORDER_DELIVERED"
          ? true
          : false;
      }
    },
  },
  data() {
    return {
      numTowords,
      order: {
        orderId: null,
        products: [],
        totalPrice: 0,
        itemCount: 0,

        quantity: 0,
        price: 0,
        amount: 0,
        discountedTotal: 0,
        deliveryAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: { isoCode: "", name: "" },
          firstName: "",
          lastName: "",
          note: "",
          pincode: "",
          state: "",
          type: "",
        },
      },
    };
  },
  methods: {
    ...mapActions(useOrderStore, ["getOrderDetailsByOrderIdAction"]),
  },
  async created() {
    let orderId = this.$route.query.orderId;
    if (orderId) {
      let response = await this.getOrderDetailsByOrderIdAction(orderId);
      if (response.exists) {
        let orderDetails = { orderId: response.id, ...response.data() };
        this.order = orderDetails;
      }
    }
  },
};
</script>

<style></style>
