import {
  addNewCompanyDetailsAPI,
  getCompanyDetailsAPI,
} from "@/api/company.api";
import { defineStore } from "pinia";

export default defineStore("company_details", {
  state() {
    return {
      companyDetails: {
        companyName: null,
        companyPrimaryAddress: null,
        gstin: null,
        companySecondaryAddress: null,
        ownerName: null,
        companyFacebookLink: null,
        companyInstagramLink: null,
        companyYoutubeLink: null,
        customerSupportContact: null,
      },
    };
  },
  actions: {
    async saveNewCompanyDetailsAction(details) {
      return await addNewCompanyDetailsAPI(details);
    },
    async getCompanyDetailsAction() {
      let details = await getCompanyDetailsAPI();
      let company = { ...details.data(), companyId: details.id };
      this.companyDetails = company;
    },
  },
});
