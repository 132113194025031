<template>
  <div class="rounded-md p-4 sm:grid-cols-1 lg:grid-cols-1">
    <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
      <dl class="space-y-8 px-4 sm:space-y-6 sm:px-6 border py-6">
        <div>
          <dt
            class="text-xs font-medium text-gray-500 sm:w-40 sm:flex-shrink-0"
          >
            {{ address.firstName }} {{ address.lastName }}
          </dt>
          <dd class="mt-1 text-xs text-gray-900 sm:col-span-2">
            <p>
              {{ address.addressLine1 }} {{ address.addressLine2 }}
              {{ address.city }} {{ address.state }} {{ address.country }}
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      default: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        country: "",
        pincode: "",
        note: "",
      },
    },
  },
};
</script>

<style></style>
