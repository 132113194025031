<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Genre</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the Genre which are saved in database
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <router-link
          type="button"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
          :to="{ name: 'ADD_GENRE_FORM' }"
          v-if="
            hasUserPermissions(CREATE_GENRE) ||
            hasUserPermissions(CREATE_ONLY_GENRE)
          "
        >
          Create New Genre
        </router-link>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="relative overflow-hidden ring-1 ring-black ring-opacity-5"
          >
            <!-- Selected row actions, only show when rows are selected. -->
            <!-- <div
              class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16"
            >
              <button
                type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              >
                Bulk edit
              </button>
              <button
                type="button"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              >
                Delete all
              </button>
            </div> -->

            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <span
                      class="absolute left-4 top-1/2 -mt-2 text-xs h-4 w-4 rounded border-gray-300 text-black sm:left-6"
                    ></span>
                    <!-- <input
                        type="checkbox"
                        v-model="checkAll"
                        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                      /> -->
                  </th>
                  <th
                    scope="col"
                    class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created By
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Updated At
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Updated By
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <!-- Selected: "bg-gray-50" -->
                <tr
                  v-for="(stat, idx) in genre"
                  :key="stat.genreId"
                  :id="stat.genreName"
                >
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <!-- Selected row marker, only show when row is selected. -->
                    <div
                      class="absolute inset-y-0 left-0 w-0.5 bg-transparent focus:bg-indigo-600"
                    ></div>
                    <span
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-black text-xs focus:ring-indigo-500 sm:left-6"
                      >{{ idx + 1 }}</span
                    >
                    <!-- <input
                        :value="stat.statusId"
                        type="checkbox"
                        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                      /> -->
                  </td>
                  <!-- Selected: "text-indigo-600", Not Selected: "text-gray-900" -->
                  <td
                    class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    {{ stat.genreName }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      stat.createdAt
                        ? moment(stat.createdAt.toDate()).format("MMMM Do YYYY")
                        : ""
                    }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      class="hover:underline hover:cursor-pointer active:cursor-pointer active:underline"
                      v-textTrim="10"
                    >
                      {{ stat.createdBy }}</span
                    >
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      stat.updatedAt
                        ? moment(stat.updatedAt.toDate()).format("MMMM Do YYYY")
                        : ""
                    }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      class="hover:underline hover:cursor-pointer active:cursor-pointer active:underline"
                      v-textTrim="10"
                    >
                      {{ stat.updatedBy }}</span
                    >
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <router-link
                      href="#"
                      v-if="
                        hasUserPermissions(CREATE_GENRE) ||
                        hasUserPermissions(CREATE_ONLY_GENRE)
                      "
                      class="inline-flex mr-2 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      :to="{
                        name: 'EDIT_GENRE_FORM',
                        params: { genreId: stat.genreId },
                      }"
                      >Edit</router-link
                    >
                    <button
                      @click="deleteGenreById(stat.genreId, stat.genreName)"
                      v-if="
                        hasUserPermissions(CREATE_GENRE) ||
                        hasUserPermissions(CREATE_ONLY_GENRE)
                      "
                      class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CREATE_GENRE, CREATE_ONLY_GENRE } from "@/assets/Permissions";
import useToastStore from "@/store/toast.store";
import useGenreStore from "@/store/genre.store";
import { mapState, mapActions } from "pinia";
import { hasUserPermissions } from "@/utils/auth.util";
export default {
  data() {
    return {
      checkAll: "",
      CREATE_GENRE,
      CREATE_ONLY_GENRE,
      hasUserPermissions,
    };
  },
  computed: {
    ...mapState(useGenreStore, ["genre"]),
  },
  methods: {
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useGenreStore, [
      "fetchAllGenreAction",
      "deleteGenreDetailsByGenreIdAction",
    ]),
    async deleteGenreById(genreId, genreName) {
      try {
        let dialog = confirm(
          "Are you sure you want to delete Status " +
            genreName.toUpperCase() +
            " ? "
        );
        if (dialog) {
          await this.deleteGenreDetailsByGenreIdAction(genreId, genreName);
          this.success(`Successfully Deleted Genre ${genreName}.`);
          await this.fetchAllGenreAction();
        }
      } catch (err) {
        this.error(err.message);
      }
    },
  },
  async created() {
    await this.fetchAllGenreAction();
  },
};
</script>

<style></style>
