<template>
  <appOverlay
    :isOverlayActive="isOverlayActive"
    @overlayClicked="handleOverlayClick"
  />
  <div
    class="pointer-events-none fixed inset-y-0 right-0 flex max-w-sm pl-10 sm:pl-16"
    :class="{ 'z-50': isOverlayActive && isSidebarBarFlyoutActive }"
  >
    <!-- :class="{ 'z-50': !isOverlayActive }" -->
    <div class="pointer-events-auto w-screen md:max-w-md xs:max-w-sm">
      <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
        <div class="border-t border-gray-200">
          <SidebarCategoryFilterFlyout
            title="Category"
            type="Category"
            :items="categories"
            typeId="categoryId"
            :isClosed="false"
          />
          <SidebarCategoryFilterFlyout
            title="Brands"
            type="Brand"
            :items="brands"
            typeId="brandId"
            :isClosed="true"
          />
          <SidebarCategoryFilterFlyout
            title="Genre"
            type="Genre"
            :items="genre"
            typeId="genreId"
            :isClosed="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCategoryStore from "@/store/categories.store";
import useBrandStore from "@/store/brands.store";
import useProductStore from "@/store/products.store";
import useGenreStore from "@/store/genre.store";
import { mapState } from "pinia";
import SidebarCategoryFilterFlyout from "./Sidebar.category.filter.vue";
export default {
  components: {
    SidebarCategoryFilterFlyout,
  },
  computed: {
    ...mapState(useCategoryStore, ["categories"]),
    ...mapState(useBrandStore, ["brands"]),
    ...mapState(useProductStore, ["isProductFilterOn"]),
    ...mapState(useGenreStore, ["genre"]),
  },
  data() {
    return {};
  },
  props: {
    isOverlayActive: {
      required: true,
      default: false,
      type: Boolean,
    },
    isSidebarBarFlyoutActive: {},
  },
  methods: {
    handleOverlayClick() {
      this.$emit("flyoutOverlayClicked", false);
    },
  },
};
</script>

<style></style>
