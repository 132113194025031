import { defineStore } from "pinia";
import {
  createNewGenreApi,
  fetchAllGenreApi,
  getGenreByGenreIdAPI,
  updateGenreByGenreIdAPI,
  deleteStatusDetailsByStatusIdAPI,
} from "@/api/genre.api";

export default defineStore("genre", {
  state() {
    return {
      genre: [],
    };
  },
  actions: {
    async createNewGenreAction(genre) {
      return await createNewGenreApi(genre);
    },
    async fetchAllGenreAction() {
      let response = await fetchAllGenreApi();
      let genreArr = [];
      response.forEach((genreItem) => {
        let gen = {
          id: genreItem.id,
          genreId: genreItem.id,
          ...genreItem.data(),
        };
        genreArr.push(gen);
      });
      this.genre = genreArr;
    },
    async getGenreByGenreIdAction(genreId) {
      return await getGenreByGenreIdAPI(genreId);
    },

    async updateGenreByGenreIdAction(genre) {
      return await updateGenreByGenreIdAPI(genre);
    },

    async deleteGenreDetailsByGenreIdAction(genreId, genreName) {
      return await deleteStatusDetailsByStatusIdAPI(genreId, genreName);
    },

    getGenreDetailsByGenreId(genreId) {
      return this.genre.find((genre) => {
        return genre.genreId === genreId;
      });
    },
  },
});
