<template>
  <div class="bg-white py-6">
    <div class="m-12">
      <NoItemsAlert
        v-if="!isLoading && products.length === 0"
        title="No Similar Products!!!"
        :message="NO_SIMILAR_PRODUCTS_FOUND"
      />
    </div>

    <div
      class="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:mx-auto xs:grid-cols-2 max-w-7xl mx-auto gap-4 px-4"
    >
      <ProductCartSkeleton v-if="isLoading" />

      <ProductCard
        v-else
        v-for="product in products"
        :key="product"
        :product="product"
      />
    </div>
  </div>
</template>
<script>
import { NO_SIMILAR_PRODUCTS_FOUND } from "@/assets/Constants";
import NoItemsAlert from "../Commons/Alerts/NoItems.alert.vue";
import useProductStore from "@/store/products.store";
import { mapActions } from "pinia";
import ProductCartSkeleton from "@/components/Skeletons/Product.card.skeleton.vue";
import ProductCard from "@/components/Products/Product.card.vue";
export default {
  name: "product-Similar",
  data() {
    return {
      products: [],
      isLoading: false,
      NO_SIMILAR_PRODUCTS_FOUND,
    };
  },
  methods: {
    ...mapActions(useProductStore, ["getSimilarProductsAction"]),
  },
  async created() {
    let items = [];
    this.isLoading = true;
    let response = await this.getSimilarProductsAction(
      this.currentProductId,
      this.genreId,
      this.categoryId
    );
    response.forEach((product) => {
      let similarProduct = { productId: product.id, ...product.data() };
      if (similarProduct.productId != this.currentProductId) {
        items.push(similarProduct);
      }
    });
    this.products = items;
    this.isLoading = false;
  },
  props: {
    title: {
      required: true,
      default: "No title",
    },
    categoryId: {
      required: true,
      default: "No title",
      type: String,
    },
    genreId: {
      required: true,
      default: "No title",
      type: String,
    },
    currentProductId: {
      required: true,
      default: "No title",
      type: String,
    },
  },
  components: {
    ProductCard,
    ProductCartSkeleton,
    NoItemsAlert,
  },
};
</script>

<style></style>
