<template>
  <div
    class="fixed inset-0 overflow-hidden"
    :class="{
      hidden: !isOverlayActive || isOverLayClicked,
      'z-40': isOverlayActive || !isOverLayClicked,
      'backdrop-blur-2xl': applyBackdrop,
    }"
    role="dialog"
    aria-modal="true"
  >
    <div class="absolute inset-0 overflow-hidden">
      <!-- Background overlay, show/hide based on slide-over state. -->
      <div
        class="absolute inset-0"
        aria-hidden="true"
        @click="handleOutsideClick"
      ></div>

      <!--
        Slide-over panel, show/hide based on slide-over state.

        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-0"
          To: "translate-x-full"
      -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import useAuthStore from "@/store/auth.store";
import { mapWritableState } from "pinia";
export default {
  props: {
    isOverlayActive: {
      default: false,
      type: Boolean,
    },
    applyBackdrop: {
      default: false,
      type: Boolean,
    },
    backdropClasses: {
      required: false,
      type: String,
      default: "backdrop-blur-xl",
    },
  },
  data() {
    return {
      isModalClosed: false,
      isOverLayClicked: false,
      propsBackdropClasses: this.backdropClasses,
    };
  },
  methods: {
    handleOutsideClick() {
      this.isOverLayClicked = true;
      this.$emit("OverlayOutsideClicked", true);
      this.toggleUserInfoSidebar = false;
    },
    applyEnterCssClasses() {
      return `transform transition ease-in-out duration-500 sm:duration-700`;
    },
    applyLeaveCssClassess() {
      return `transform transition ease-in-out duration-500 sm:duration-700`;
    },
    returnBackdropClasses() {
      return this.backdropClasses;
    },
  },
  computed: {
    ...mapWritableState(useAuthStore, ["toggleUserInfoSidebar"]),
  },
  watch: {
    isOverlayActive(oldVal, newVal) {
      if (newVal) {
        this.isOverLayClicked = false;
      }
    },
  },
};
</script>

<style></style>
