<template>
  <vee-form
    :validation-schema="validationSchema"
    @submit="handleCategoryFormSubmission"
  >
    <div class="space-y-6 bg-white px-4 py-5">
      <div class="sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="categoryName"
                  class="block text-sm font-medium text-gray-700"
                  >Category Name</label
                >
                <vee-field
                  type="text"
                  name="categoryName"
                  id="categoryName"
                  v-model="category.categoryName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="categoryName"
                  class="text-xs text-red-600"
                  id="errorMessageCategoryName"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="categoryLogoURL"
                  class="block text-sm font-medium text-gray-700"
                  >Category Logo URL</label
                >
                <vee-field
                  type="text"
                  name="categoryLogoURL"
                  id="categoryLogoURL"
                  v-model="category.categoryLogoURL"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="categoryLogoURL"
                  class="text-xs text-red-600"
                  id="categoryLogoURL"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          id="saveCategoryBtn"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          @click.prevent="$router.push('/categories')"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </div>
  </vee-form>
</template>

<script>
import useCategoriesAuth from "@/store/categories.store";
import useAuthStore from "@/store/auth.store";
import { mapState, mapActions } from "pinia";
import { ErrorMessage } from "vee-validate";
import useToastStore from "@/store/toast.store";
import { CREATE_CATEGORY, CREATE_ONLY_CATEGORY } from "@/assets/Permissions";
import { hasUserPermissions } from "@/utils/auth.util";

export default {
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
    ...mapState(useCategoriesAuth, ["categories", "categoriesList"]),
  },
  data() {
    return {
      CREATE_ONLY_CATEGORY,
      validationSchema: {
        categoryName: "required|min:5|max:50",
        categoryLogoURL: "required|min:1|url",
      },
      category: {
        categoryId: null,
        categoryName: "",
        createdBy: "",
        createdAt: new Date(),
        updatedBy: null,
        updatedAt: null,
        subCategories: [],
        categoryLogoURL: "",
      },
    };
  },
  methods: {
    ...mapActions(useCategoriesAuth, [
      "saveNewCategoryDetailsActions",
      "getAllSavedCategoriesAction",
      "sortCategoriesByFieldAction",
      "mapAllCategoriesInOneListAction",
    ]),
    ...mapState(useCategoriesAuth, ["sidebarMenu"]),
    ...mapActions(useToastStore, ["success", "error"]),
    async handleCategoryFormSubmission(values) {
      try {
        this.category.parentCategoryId = values.parentCategory
          ? values.parentCategory
          : null;
        this.category.createdBy = this.userdetails.userId;

        await this.saveNewCategoryDetailsActions(this.category);
        await this.getAllSavedCategoriesAction();
        this.success(
          "Successfully Saved Category Named ",
          this.category.categoryName
        );
        this.$router.push("/categories");
      } catch (error) {
        this.error(error.message);
      }
    },
    hasAccess(permission) {
      return hasUserPermissions(permission);
    },
  },
  components: { ErrorMessage },
  beforeRouteEnter(to, from, next) {
    if (
      hasUserPermissions(CREATE_CATEGORY) ||
      hasUserPermissions(CREATE_ONLY_CATEGORY)
    ) {
      next();
    } else {
      next("/");
    }
  },
};
</script>

<style></style>
