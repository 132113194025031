<template>
  <div class="space-y-8 divide-y divide-gray-200 max-w-7xl mx-auto">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <!-- <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Profile</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div> -->

        <vee-form
          :validation-schema="schema"
          @submit="handleUserProfileDetailOnSubmission"
          class="mt-6 sm:mt-5 space-y-6 sm:space-y-5 xs:px-4"
        >
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center">
            <span
              for="file-upload"
              class="block text-sm font-medium text-gray-700"
            >
              Click on the Image to upload
            </span>

            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="flex items-center">
                <label
                  for="file-upload"
                  class="block text-sm h-24 w-24 font-medium text-gray-700"
                >
                  <span
                    class="h-24 w-24 rounded-full overflow-hidden bg-gray-100"
                  >
                    <img
                      :src="defaultProfileImage"
                      alt="default profile image"
                      class="h-24 w-24 rounded-full object-cover overflow-hidden"
                      v-if="!hasProfileImage && !newSelectedProfileImageUrl"
                    />
                    <img
                      v-else-if="
                        (!hasProfileImage && newSelectedProfileImageUrl) ||
                        (hasProfileImage && newSelectedProfileImageUrl)
                      "
                      :src="newSelectedProfileImageUrl"
                      alt=""
                    />
                    <img
                      v-else
                      class="h-24 w-24 rounded-full object-cover overflow-hidden"
                      :src="
                        userdetails && userdetails.profileImageUrl
                          ? userdetails.profileImageUrl
                          : ''
                      "
                      alt=""
                    />
                  </span>
                </label>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  class="sr-only"
                  @change="handleUserProfileImageUpload"
                />
              </div>
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <label
              for="username"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Username
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md shadow-sm">
                <vee-field
                  type="text"
                  name="username"
                  id="username"
                  readonly
                  v-model="additionalDetails.username"
                  class="shadow-sm focus:ring-gray-500 py-2 px-3 focus:border-gray-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Gamer"
                  aria-describedby="email-description"
                />
              </div>
              <ErrorMessage name="username" class="text-red-500 text-xs" />
            </div>
          </div>
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <label
              for="contact"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Contact
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md shadow-sm">
                <vee-field
                  type="text"
                  name="contact"
                  id="contact"
                  v-model="additionalDetails.contact"
                  class="shadow-sm focus:ring-gray-500 py-2 px-3 focus:border-gray-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Mobile Number: 01234567890"
                  aria-describedby="email-description"
                />
              </div>
              <ErrorMessage name="contact" class="text-red-500 text-xs" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label
              for="about"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              About
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <p class="text-yellow-500 text-xs">
                You have entered {{ additionalDetails.about.length }}
              </p>
              <vee-field
                as="textarea"
                v-model="additionalDetails.about"
                id="about"
                name="about"
                rows="3"
                placeholde="Write a few sentences about yourself."
                class="max-w-lg shadow-sm px-2 py-2 block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
              ></vee-field>
              <ErrorMessage name="about" class="text-red-500 text-xs" />
            </div>
          </div>

          <div class="pt-5 sm:pt-5">
            <div class="flex justify-center mt-8">
              <button
                type="submit"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Update
              </button>
              <button
                type="button"
                @click="$router.push('/')"
                class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
            </div>
          </div>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import useToastStore from "@/store/toast.store";
import useAuthStore from "@/store/auth.store";
import { ErrorMessage } from "vee-validate";
import defaultProfileImage from "@/assets/img/defaultprofile.png";
export default {
  data() {
    return {
      defaultProfileImage,
      schema: {
        about: "required|min:5|max:150",
        contact: "required|min:10|max:10|numeric",
      },
      additionalDetails: {
        about: null,
        file: undefined,
        username: null,
        contact: null,
      },
      isProfileImage_dragged: false,
      hasProfileImage: false,
      newSelectedProfileImageUrl: null,
    };
  },
  computed: {
    ...mapWritableState(useAuthStore, [
      "isUserLoggedIn",
      "userId",
      "userdetails",
    ]),
  },
  methods: {
    ...mapActions(useAuthStore, [
      "addUserAdditionalDetails",
      "userdetails",
      "mergeLoggedInUserDetailsToLocalState",
    ]),
    ...mapActions(useToastStore, ["success", "error"]),
    async handleUserProfileDetailOnSubmission(values) {
      try {
        await this.addUserAdditionalDetails({ ...this.additionalDetails });
        this.success(`Successfully Updated Profile details`);
        this.$router.push({
          name: "USER_PROFILE",
          params: {
            profileId:
              this.userdetails && this.userdetails.userId
                ? this.userdetails.userId
                : "",
          },
        });
      } catch (err) {
        this.error(`Failed to update profile details ${err.message}`);
      }
    },

    async handleAdditionalUserDetails() {
      await this.addUserAdditionalDetails({ ...this.additionalDetails });
      this.$router.push({
        name: "USER_PROFILE",
        params: {
          profileId:
            this.userdetails && this.userdetails.userId
              ? this.userdetails.userId
              : "",
        },
      });
    },
    async handleUserProfileImageUpload($event) {
      this.additionalDetails.file = $event.target.files[0];
      let imageFile = $event.target.files[0];
      if (imageFile) {
        if (
          imageFile.type === "image/jpeg" ||
          imageFile.type === "image/png" ||
          imageFile.type === "image/jpg"
        ) {
          this.additionalDetails.file = imageFile;
          this.newSelectedProfileImageUrl = URL.createObjectURL(imageFile);
        } else {
          throw new Error(
            `Only images and image Types are allowed are jpeg,jpg,png`
          );
        }
      }
    },
  },

  watch: {},
  created() {
    this.additionalDetails.username =
      this.userdetails && this.userdetails.emailId
        ? this.userdetails.emailId.split("@")[0]
        : "";
    this.additionalDetails.about =
      this.userdetails && this.userdetails.about ? this.userdetails.about : "";
    this.additionalDetails.contact =
      this.userdetails && this.userdetails.contact
        ? this.userdetails.contact
        : "";
    if (this.userdetails && this.userdetails.profileImageUrl) {
      this.hasProfileImage = true;
    }
  },

  components: { ErrorMessage },
};
</script>
1

<style></style>
