<template>
  <section
    aria-labelledby="summary-heading"
    class="mt-16 border px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
  >
    <h2 id="summary-heading" class="text-lg font-medium text-gray-900">
      Order summary
    </h2>

    <dl class="mt-6 space-y-4">
      <div class="flex items-center justify-between">
        <dt class="text-sm text-gray-600">Subtotal</dt>
        <dd class="text-sm font-medium text-gray-900">₹ {{ totalPrice }}</dd>
      </div>
      <div
        class="border-t border-gray-200 pt-4 flex items-center justify-between"
      >
        <dt class="flex items-center text-sm text-gray-600">
          <span>Shipping Charges</span>
          <a
            href="#"
            class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
          >
            <span class="sr-only"
              >Learn more about how shipping is calculated</span
            >
            <!-- Heroicon name: solid/question-mark-circle -->
            <!-- <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg> -->
          </a>
        </dt>
        <dd class="text-sm font-medium text-gray-900">0.0</dd>
      </div>
      <div
        class="border-t border-gray-200 pt-4 flex items-center justify-between"
      >
        <dt class="flex text-sm text-gray-600">
          <span>Tax </span>
          <a
            href="#"
            class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
          >
            <span class="sr-only">Learn more about how tax is calculated</span>
            <!-- Heroicon name: solid/question-mark-circle -->
            <!-- <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg> -->
          </a>
        </dt>
        <dd class="text-sm font-medium text-gray-900">0.0</dd>
      </div>
      <div
        class="border-t border-gray-200 pt-4 flex items-center justify-between"
      >
        <dt class="text-base font-medium text-gray-900">Order total</dt>
        <dd class="text-base font-medium text-gray-900">₹ {{ totalPrice }}</dd>
      </div>
    </dl>
    <!-- @click.prevent="handlePayments" -->
    <div class="mt-6">
      <button
        type="submit"
        v-if="userdetails.userId"
        class="w-full hidden bg-black border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-black"
      >
        Checkout
      </button>
      <router-link
        to="/login"
        v-else
        class="inline-flex my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
      >
        Login to Checkout
      </router-link>
      <router-link
        type="submit"
        :to="{ name: 'GENERATE_INVOICES' }"
        v-if="
          hasUserPermissions(CREATE_INVOICE) ||
          hasUserPermissions(CREATE_ONLY_INVOICE)
        "
        class="w-full flex justify-center mt-4 bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-200 focus:ring-indigo-600"
      >
        Generate Invoice
      </router-link>
    </div>
    <p class="my-4 text-red-700 text-sm" v-if="userdetails.userId">
      {{ NO_REFUND_POLICY_MESSAGE }}
    </p>
    <button
      type="button"
      v-if="itemCount > 0 && userdetails.userId"
      @click="toggleAddressModal = !toggleAddressModal"
      class="inline-flex my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
    >
      <!-- Heroicon name: solid/mail -->
      <svg
        class="flex-shrink-0 mr-1.5 h-3 w-3 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
          clip-rule="evenodd"
        />
      </svg>
      Choose Delivery Address
    </button>
    <div class="" v-if="userdetails.userId">
      <span class="text-sm font-rubik uppercase">Delivering To:</span>
      <p
        class="text-sm text-slate-600 mt-2 font-archivo"
        v-if="deliveryAddress != null"
      >
        <span class="block text-md text-black uppercase"
          >{{ deliveryAddress.firstName }} {{ deliveryAddress.lastName }}</span
        >
        <span class="inline-block mt-4"
          >{{ deliveryAddress.addressLine1 }}{{ deliveryAddress.addressLine2 }}
          {{ deliveryAddress.city }}
          {{
            getStateNameByStateCode(
              deliveryAddress.state,
              deliveryAddress.country.isoCode
            )
          }}
          {{ deliveryAddress.country.name }}</span
        >
        <span class="block mt-2">Pincode :{{ deliveryAddress.pincode }}</span>
        <span class="block mt-2">contact :{{ deliveryAddress.contact }}</span>
      </p>
    </div>
    <CardAddressList
      v-if="
        toggleAddressModal &&
        userdetails.addresses &&
        userdetails.addresses.length
      "
      @selectedDeliveryAddress="handleDeliveryAddress"
    />
    <p
      v-if="userdetails.addresses && !userdetails.addresses.length"
      class="text-sm"
    >
      {{ NO_USER_ADDRESS_FOUND_MESSAGE }}
    </p>
  </section>
</template>

<script>
import { State } from "country-state-city";
import CartSelectedAddress from "./Cart.selected.address.vue";
import { v4 as uuidv4 } from "uuid";
import useOrderStore from "@/store/orders.store";
import useProductStore from "@/store/products.store";
import useAuthStore from "@/store/auth.store";
import useCompanyStore from "@/store/company.store";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId();
import useCartStore from "@/store/cart.store";
import { mapActions, mapState } from "pinia";
import CardAddressList from "./Cart.address.list.vue";
import { hasUserPermissions } from "@/utils/auth.util";
import { CREATE_INVOICE, CREATE_ONLY_INVOICE } from "@/assets/Permissions";
import {
  NO_USER_ADDRESS_FOUND_MESSAGE,
  NO_REFUND_POLICY_MESSAGE,
} from "@/assets/Constants";
import { ordersCollection } from "@/firebase/firebase.config";
import { productCollection } from "@/firebase/firebase.config";
export default {
  computed: {
    ...mapState(useCartStore, ["totalPrice", "itemCount", "cartItems"]),
    ...mapState(useAuthStore, ["userdetails", "isUserLoggedIn"]),
    ...mapState(useCompanyStore, ["companyDetails"]),
    doesUserHasAddress() {
      return this.userdetails &&
        this.userdetails.addresses &&
        this.userdetails.addresses.length
        ? true
        : false;
    },
  },
  data() {
    return {
      hasUserPermissions,
      uuidv4,
      uid,
      secret: "GIopI7rTWB87jJBjqVLYInSy",
      key: "rzp_test_oJSilfNYzoFZeB",
      toggleAddressModal: false,
      deliveryAddress: null,
      CREATE_INVOICE,
      doesUserHasAddress: false,
      NO_USER_ADDRESS_FOUND_MESSAGE,
      NO_REFUND_POLICY_MESSAGE,
      CREATE_ONLY_INVOICE,
    };
  },
  methods: {
    ...mapActions(useOrderStore, ["createNewOrderAction"]),
    ...mapActions(useCartStore, ["clearCartItemsAction"]),
    ...mapActions(useProductStore, [
      "getProductDetailsByProductIdAction",
      "updateProductDetailsByProductIdAction",
    ]),
    getStateNameByStateCode(stateCode = "KA", countryCode = "IN") {
      return State.getStateByCodeAndCountry(stateCode, countryCode).name;
    },
    handlePostPaymentsProcess(razorpay_payment_id) {
      let amount = this.totalPrice ? this.totalPrice * 100 : 0;
      let orderStatus = [
        {
          status: "ORDER_PLACED",
          createdBy:
            this.userdetails && this.userdetails.userId
              ? this.userdetails.userId
              : null,
          createdAt: new Date(),
          createdByEmailId:
            this.userdetails && this.userdetails.emailId
              ? this.userdetails.emailId
              : null,
        },
      ];
      let currentStatusOfOrder = orderStatus[orderStatus.length - 1].status;
      let products = [];
      this.cartItems.forEach((product) => {
        let {
          productId,
          productName,
          productSellingPrice,
          cartQuantity,
          mainImageUrl,
        } = product;
        let newProduct = {
          productId,
          productName,
          price: productSellingPrice,
          quantity: cartQuantity,
          mainImageUrl,
        };
        products.push(newProduct);
      });
      let newOrder = {
        orderId: "ORDER_" + uid(),
        razorpay_payment_id: razorpay_payment_id,
        createdAt: new Date(),
        orderStatus: orderStatus,
        currentStatus: currentStatusOfOrder,
        amount: amount / 100,
        deliveryAddress: this.deliveryAddress,
        orderedBy:
          this.userdetails && this.userdetails.emailId
            ? this.userdetails.emailId
            : null,
        createdBy:
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null,
        updatedBy: null,
        updatedAt: null,
        products,
      };

      ordersCollection
        .doc(newOrder.orderId)
        .set(newOrder)
        .then((orderCreationResponse) => {
          products.forEach((product) => {
            productCollection
              .doc(product.productId)
              .get()
              .then((foundProduct) => {
                let dbProduct = {
                  productId: foundProduct.id,
                  ...foundProduct.data(),
                };
                if (Number(dbProduct.quantity) >= Number(product.quantity)) {
                  let qty = Number(dbProduct.quantity);
                  qty = qty - Number(product.quantity);
                  dbProduct.quantity = qty.toString();
                }
                if (dbProduct.quantity === "0") {
                  dbProduct.condition = "OUT OF STOCK";
                }
                productCollection
                  .doc(dbProduct.productId)
                  .set(dbProduct)
                  .then(() => {
                    this.clearCartItemsAction();
                    this.$router.push({
                      name: "MY_ORDERS",
                      hash: `#${newOrder.orderId}`,
                    });
                  })
                  .catch((err) => {
                    console.log("err ", err);
                  });
              })
              .catch((err) => {
                console.log("2 err ", err);
              });
          });
        })
        .catch((orderCreationError) => {
          console.error(orderCreationError);
        });
      // let response = await this.createNewOrderAction(newOrder);
      // products.forEach(async (product) => {
      //   let productId = product.productId;
      //   if (productId) {
      //     let productDB = await this.getProductDetailsByProductIdAction(
      //       productId
      //     );
      //     let dbProduct = {
      //       productId: productDB.id,
      //       ...productDB.data(),
      //     };
      //     if (dbProduct.quantity >= product.quantity) {
      //       let qty = dbProduct.quantity;
      //       qty = qty - product.quantity;
      //       dbProduct.quantity = qty;
      //     }
      //     await this.updateProductDetailsByProductIdAction(dbProduct);
      //   }
      // });
      // this.clearCartItemsAction();
      // this.$router.push({
      //   name: "MY_ORDERS",
      //   hash: `#${newOrder.orderId}`,
      // });
    },

    handleDeliveryAddress(addressIndex) {
      // this.deliveryAddress = address;
      if (
        addressIndex > -1 &&
        this.userdetails &&
        this.userdetails.addresses &&
        this.userdetails.addresses.length
      ) {
        this.deliveryAddress = this.userdetails.addresses[addressIndex];
        this.toggleAddressModal = false;
      }
    },
    async handlePayments() {
      if (!this.userdetails && !this.userdetails.userId) {
        alert("Login To Checkout");
        return;
      }

      if (this.userdetails && !this.userdetails.contact) {
        alert(
          "Add Your Contact Number. In Order to proceed the checkout. you can update contact details in profile section"
        );
        return;
      }

      if (this.deliveryAddress == null) {
        alert("Choose The Delivery Address");
        return;
      }
      let products = [];
      this.cartItems.forEach((product) => {
        let {
          productId,
          productName,
          productSellingPrice,
          cartQuantity,
          mainImageUrl,
        } = product;
        let newProduct = {
          productId,
          productName,
          price: productSellingPrice,
          quantity: cartQuantity,
          mainImageUrl,
        };
        products.push(newProduct);
      });
      let amount = this.totalPrice ? this.totalPrice * 100 : 0;
      let currency = "INR";
      let description = "Reygames Castle Products Transactions";
      let companyName =
        this.companyDetails && this.companyDetails.companyName
          ? this.companyDetails.companyName
          : "Rey Games Castle";
      let userId =
        this.userdetails && this.userdetails.userId
          ? this.userdetails.userId
          : null;
      let contact =
        this.userdetails && this.userdetails.contact
          ? this.userdetails.contact
          : null;
      let emailId =
        this.userdetails && this.userdetails.emailId
          ? this.userdetails.emailId
          : null;

      let options = {
        key: "rzp_test_oJSilfNYzoFZeB",
        amount,
        currency,
        description,
        name: companyName,
        description,
        handler: (response) => {
          let razorpay_payment_id = response.razorpay_payment_id;
          if (razorpay_payment_id) {
            this.handlePostPaymentsProcess(razorpay_payment_id);
          }
        },
        prefill: {
          userId: userId,
          contact,
          email: emailId,
        },
        theme: {
          color: "#3c3c3c",
        },
      };
      var razorPayInstance = new Razorpay(options);
      razorPayInstance.open();
      razorPayInstance.on("payment.failed", (response) => {});
    },
  },
  components: {
    CardAddressList,
    CartSelectedAddress,
  },
};
</script>

<style></style>
