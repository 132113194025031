<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-3xl font-semibold text-gray-900">Brands</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the Brands which are saved in database
        </p>
        <p class="mt-2 text-sm text-gray-700" v-if="checkedElements.length">
          Total {{ checkedElements.length }} has been selected
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <router-link
          v-if="doesUserHasPermissionToWriteBrand"
          type="button"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
          :to="{ name: 'Add-Brand' }"
        >
          Add Brand
        </router-link>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="relative overflow-hidden ring-1 ring-black ring-opacity-5"
          >
            <!-- Selected row actions, only show when rows are selected. -->
            <div
              class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16"
            >
              <button
                type="button"
                v-if="doesUserHasPermissionToWriteBrand"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              >
                Bulk edit
              </button>
              <button
                type="button"
                v-if="doesUserHasPermissionToWriteBrand"
                class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              >
                Delete all
              </button>
            </div>

            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="relative w-12 px-6 sm:w-16 sm:px-8"
                    v-if="doesUserHasPermissionToWriteBrand"
                  >
                    <input
                      type="checkbox"
                      v-model="checkAll"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </th>
                  <th
                    scope="col"
                    class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    :class="{
                      ['px-6 sm:w-16 sm:px-8']:
                        !doesUserHasPermissionToWriteBrand,
                    }"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    v-if="doesUserHasPermissionToWriteBrand"
                  >
                    Created By
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Updated At
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    v-if="doesUserHasPermissionToWriteBrand"
                  >
                    Updated By
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <!-- Selected: "bg-gray-50" -->
                <TableRowSkeleton v-if="brands.length === 0" />
                <tr
                  v-else
                  v-for="brand in brands"
                  :key="brand.brandId"
                  :id="brand.brandId"
                >
                  <td
                    class="relative w-12 px-6 sm:w-16 sm:px-8"
                    v-if="doesUserHasPermissionToWriteBrand"
                  >
                    <!-- Selected row marker, only show when row is selected. -->
                    <div
                      class="absolute inset-y-0 left-0 w-0.5 bg-transparent focus:bg-indigo-600"
                    ></div>

                    <input
                      v-model="checkedElements"
                      :value="brand.brandId"
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                  </td>
                  <!-- Selected: "text-indigo-600", Not Selected: "text-gray-900" -->
                  <td
                    class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900"
                    :class="{
                      ['px-6 sm:w-16 sm:px-8']:
                        !doesUserHasPermissionToWriteBrand,
                    }"
                  >
                    <span class="flex items-center">
                      <img
                        :src="brand.brandLogoURL"
                        :alt="brand.brandName"
                        class="h-6 w-6 mr-2 object-contain"
                      />
                      {{ brand.brandName }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      brand.createdAt
                        ? moment(brand.createdAt.toDate()).format(
                            "MMMM Do YYYY"
                          )
                        : ""
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:underline hover:cursor-pointer active:underline active:cursor-pointer"
                    v-if="doesUserHasPermissionToWriteBrand"
                    v-textTrim="10"
                    @click.prevent="
                      toggleUserInfoSidebarAction(brand.createdBy)
                    "
                  >
                    {{ brand.createdBy ? brand.createdBy : "" }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      brand.updatedAt
                        ? moment(brand.updatedAt.toDate()).format(
                            "MMMM Do YYYY"
                          )
                        : ""
                    }}
                  </td>
                  <td
                    v-if="doesUserHasPermissionToWriteBrand"
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:underline hover:cursor-pointer active:underline active:cursor-pointer"
                    v-textTrim="10"
                    @click.prevent="
                      toggleUserInfoSidebarAction(brand.updatedBy)
                    "
                  >
                    {{ brand.updatedBy ? brand.updatedBy : "" }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <router-link
                      v-if="doesUserHasPermissionToWriteBrand"
                      href="#"
                      class="inline-flex mr-2 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      :to="{
                        name: 'edit-brand',
                        params: { brandId: brand.brandId },
                      }"
                      >Edit<span class="sr-only"
                        >, Lindsay Walton</span
                      ></router-link
                    >
                    <button
                      class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      @click.prevent="
                        deleteBrandById(brand.brandId, brand.brandName)
                      "
                      v-if="doesUserHasPermissionToWriteBrand"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                <appBrandDeleteModal />
                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableRowSkeleton from "../Skeletons/Table.row.skeleton.vue";
import {
  CREATE_BRAND,
  READ_BRAND,
  READ_ORDERS,
  CREATE_ONLY_BRAND,
} from "@/assets/Permissions";
import moment from "moment";
import useBrandStore from "@/store/brands.store";
import useAuthStore from "@/store/auth.store";
import useToastStore from "@/store/toast.store";
import { mapActions, mapState } from "pinia";
export default {
  data() {
    return {
      moment,
      CREATE_BRAND,
      READ_BRAND,
      READ_ORDERS,
      CREATE_ONLY_BRAND,
      requestInProgress: false,
      checkedElements: [],
    };
  },
  computed: {
    ...mapState(useBrandStore, ["brands"]),
    ...mapState(useAuthStore, ["userdetails"]),
    doesUserHasPermissionToWriteBrand() {
      if (this.userdetails.permissions) {
        return (
          this.userdetails.permissions.includes(CREATE_BRAND) ||
          this.userdetails.permissions.includes(CREATE_ONLY_BRAND)
        );
      }
      return false;
    },

    checkAll: {
      get() {
        if (this.brands.length === 0) {
          return false;
        }
        return this.brands
          ? this.checkedElements.length == this.brands.length
          : false;
      },
      set(value) {
        let checkedItems = [];
        if (value) {
          this.brands.forEach((brand) => {
            checkedItems.push(brand.brandId);
          });
        }
        this.checkedElements = checkedItems;
      },
    },
  },

  methods: {
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useBrandStore, [
      "deleteBrandByBrandIdAction",
      "fetchAllBrands",
    ]),
    ...mapActions(useAuthStore, ["toggleUserInfoSidebarAction"]),
    async deleteBrandById(brandId, brandName) {
      let dialog = confirm(
        "Are you sure you want to delete brand " + brandName + " ? "
      );
      if (dialog) {
        try {
          this.requestInProgress = true;
          await this.deleteBrandByBrandIdAction(brandId);
          this.success(`Successfully Deleted Brand `);
          this.requestInProgress = false;
        } catch (err) {
          this.requestInProgress = false;
          this.error(err.message);
        }
      }
    },
  },
  components: {
    TableRowSkeleton,
  },
  async created() {
    await this.fetchAllBrands();
  },
};
</script>

<style></style>
