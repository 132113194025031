<template>
  <div class="space-y-6 bg-white px-4 py-5">
    <vee-form :validation-schema="schema" @submit="updateGenreDetails">
      <div class="sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="genreId"
                  class="block text-sm font-medium text-gray-700"
                  >Genre ID</label
                >
                <vee-field
                  type="text"
                  name="genreId"
                  id="genreId"
                  readonly
                  autocomplete="family-name"
                  v-model="genre.genreId"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="genreName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="genreName"
                  class="block text-sm font-medium text-gray-700"
                  >Genre Name</label
                >
                <vee-field
                  type="text"
                  name="genreName"
                  id="genreName"
                  autocomplete="family-name"
                  v-model="genre.genreName"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="genreName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="createdBy"
                  class="block text-sm font-medium text-gray-700"
                  >Created By</label
                >
                <vee-field
                  type="text"
                  name="createdBy"
                  id="createdBy"
                  autocomplete="family-name"
                  readonly="readonly"
                  v-model="genre.createdBy"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="createdAt"
                  class="block text-sm font-medium text-gray-700"
                  >Created At</label
                >
                <input
                  type="text"
                  name="createdAt"
                  id="createdAt"
                  autocomplete="family-name"
                  readonly="readonly"
                  :value="
                    genre.createdAt
                      ? moment(genre.createdAt.toDate()).format(
                          `MMMM Do YYYY, h:mm:ss a`
                        )
                      : ''
                  "
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="updatedBy"
                  class="block text-sm font-medium text-gray-700"
                  >Updated by</label
                >
                <vee-field
                  type="text"
                  name="updatedBy"
                  id="updatedBy"
                  autocomplete="family-name"
                  readonly="readonly"
                  v-model="genre.updatedBy"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="updatedAt"
                  class="block text-sm font-medium text-gray-700"
                  >Updated At</label
                >
                <input
                  type="text"
                  name="upupdatedAtdatedBy"
                  id="updatedAt"
                  autocomplete="family-name"
                  readonly="readonly"
                  :value="
                    genre.updatedAt
                      ? moment(genre.updatedAt.toDate()).format(
                          `MMMM Do YYYY, h:mm:ss a`
                        )
                      : ''
                  "
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Update
        </button>
        <button
          type="button"
          @click.prevent="handleCancelSaveStatus"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import moment from "moment";
import useAuthStore from "@/store/auth.store";
import useGenreStore from "@/store/genre.store";
import useToastStore from "@/store/toast.store";
import { mapState, mapActions } from "pinia";
export default {
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
    ...mapState(useGenreStore, ["genre"]),
  },
  data() {
    return {
      moment,
      schema: {
        genreName: "required|min:3|max:25",
      },
      genre: {
        genreId: null,
        genreName: "",
        createdAt: null,
        updatedAt: null,
        updatedBy: null,
        createdBy: null,
      },
    };
  },
  methods: {
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useGenreStore, [
      "createNewGenreAction",
      "fetchAllGenreAction",
      "getGenreByGenreIdAction",
      "updateGenreByGenreIdAction",
    ]),
    async updateGenreDetails() {
      try {
        this.genre.updatedAt = new Date();
        this.genre.updatedBy =
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null;

        await this.updateGenreByGenreIdAction(this.genre);
        this.$router.push("/genre");
        this.success("Successfully Updated Genre " + this.genre.genreName);
      } catch (err) {
        this.error(err.message);
      }
    },
  },
  async created() {
    let genreId = this.$route.params.genreId;
    if (genreId) {
      let response = await this.getGenreByGenreIdAction(genreId);
      let genre = { genreId: response.id, ...response.data() };
      this.genre = genre;
    } else {
      this.$router.push("/genre");
    }
  },
};
</script>

<style></style>
