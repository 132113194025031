<template>
  <div class="px-6 font-rubik">
    <div>
      <span class="text-sm font-bold">Short Description</span>
      <p class="text-gray-500 mt-6 text-sm">{{ product.shortDescription }}</p>
    </div>
    <div class="mt-8">
      <span class="text-sm font-bold uppercase leading-3"
        >Full Description</span
      >
      <p class="text-gray-500 mt-6 text-sm">{{ product.fullDescription }}</p>
    </div>
    <div class="mt-8 font-archivo">
      <span class="text-sm font-bold uppercase leading-3">Specification</span>
      <ul role="list" class="mt-6 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Brand
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ getBrandName(product.brandId) }}
              </p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Manufacturer
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ product.manufacturer }}
              </p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class=""> Warranty </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500" v-replaceCharDirective>
                {{ product.warranty }}
              </p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Release Date
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ product.releasedate }}
              </p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Condition
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ product.condition }}
              </p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Product Dimensions
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ product.productdimensions }}
              </p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Platform
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ getcategoryName(product.categoryId) }}
              </p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Language
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">{{ product.language }}</p>
            </div>
          </div>
        </li>
        <li class="flow-root">
          <div
            class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500"
          >
            <div>
              <h3 class="text-sm font-medium text-gray-900">
                <span class="">
                  <span class="absolute inset-0" aria-hidden="true"></span>
                  Genre
                </span>
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ getGenreName(product.genre) }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import useCategoryStore from "@/store/categories.store";
import useBrandStore from "@/store/brands.store";
import useGenreStore from "@/store/genre.store";
import { mapActions } from "pinia";
export default {
  props: {
    product: {
      productName: "",
      productPrice: 0,
      status: "",
      productPriceDiscount: 0,
      fullDescription: "",
      shortDescription: "",
      mainImageUrl: "",
      allImagesUrls: [],
      categoryId: "",
      brandId: "",
      quantity: 0,
      additionalDetails: [],
      condition: "",
      galleryImage1Url: "",
      galleryImage2Url: "",
      galleryImage3Url: "",
      galleryImage4Url: "",
    },
  },
  methods: {
    ...mapActions(useBrandStore, ["getBrandNameByBrandIdAction"]),
    ...mapActions(useCategoryStore, ["getCategoryNameByCategoryIdAction"]),
    ...mapActions(useGenreStore, ["getGenreDetailsByGenreId"]),
    getBrandName(brandId) {
      let brand = this.getBrandNameByBrandIdAction(brandId);
      return brand && brand.brandName ? brand.brandName : null;
    },
    getcategoryName(categoryId) {
      let category = this.getCategoryNameByCategoryIdAction(categoryId);
      return category && category.categoryName ? category.categoryName : null;
    },
    getGenreName(genreId) {
      let genre = this.getGenreDetailsByGenreId(genreId);
      return genre && genre.genreName ? genre.genreName : null;
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
