<template>
  <div class="rounded-md p-4 sm:grid-cols-1 lg:grid-cols-1">
    <div class="px-2 pt-5 pb-5 sm:px-0 sm:pt-0">
      <dl
        class="space-y-8 px-8 sm:space-y-6 sm:px-6 border py-6"
        v-for="(address, index) in userdetails.addresses"
        :key="address"
      >
        <div>
          <dt class="font-medium text-gray-800 sm:w-40 sm:flex-shrink-0">
            <span class="font-bold uppercase w-full text-xs">
              {{ address.firstName }} {{ address.lastName }}</span
            >
          </dt>
          <dd class="text-xs text-gray-900 sm:col-span-2 space-y-1">
            <p class="">
              {{ address.addressLine1 }} {{ address.addressLine2 }}
            </p>
            <p>
              {{ address.city }}
              {{
                getStateNameByStateCode(
                  address.state.isoCode,
                  address.country.isoCode
                )
              }}
              {{ address.country.name }}
            </p>
            <p>
              {{ address.contact }}
            </p>
            <p
              class="mt-4 text-rose-600"
              v-if="address.note || address.note.trim().length > 0"
            >
              Note: {{ address.note }}
            </p>
          </dd>
        </div>
        <div class="">
          <button
            type="button"
            @click.prevent="selectedAddressToDelivery(index)"
            class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Select Address
          </button>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { State } from "country-state-city";
import useAuthStore from "@/store/auth.store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      selectedAddress: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        country: { name: "", isoCode: "" },
        pincode: "",
        note: "",
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
  },
  methods: {
    selectedAddressToDelivery(index) {
      if (index > -1) {
        this.selectedAddress =
          this.userdetails && this.userdetails.addresses
            ? this.userdetails.addresses[index]
            : null;
        this.selectedAddress = this.userdetails.addresses[index];

        this.selectedAddress = this.$emit("selectedDeliveryAddress", index);
      }
    },
    fetchAdresses() {
      return this.userdetails && this.userdetails.addresses
        ? this.userdetails.addresses
        : [];
    },
    getStateNameByStateCode(stateCode = "KA", countryCode = "IN") {
      return State.getStateByCodeAndCountry(stateCode, countryCode).name;
    },
  },
};
</script>

<style></style>
