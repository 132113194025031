import {
  auth,
  userCollection,
  storage,
  roleCollection,
} from "@/firebase/firebase.config";
export const registerUser = async (emailId, password) => {
  return await auth.createUserWithEmailAndPassword(emailId, password);
};

export const processNewUserDetailsOfRegistration = async (userdetails) => {
  debugger;
  return await userCollection.doc(userdetails.userId).set({
    ...userdetails,
  });
};

export const loginUser = async (user) => {
  return await auth.signInWithEmailAndPassword(user.email, user.password);
};

export const updateUserProfileDetails = async (
  userId,
  username,
  about,
  profileImageUrl,
  userdetails
) => {
  debugger;
  let user = await userCollection.doc(userId).get();
  if (user.exists) {
    return userCollection
      .doc(userId)
      .set({ ...userdetails, username, about, profileImageUrl });
  } else {
    throw new Error("User Does not Exists!!!");
  }
};

export const uploadUserProfile = (profileImageFile, uid) => {
  let profileImgName = profileImageFile.name;
  let ImageExtenstion = profileImgName.split(".").pop();
  const myNewFile = new File([profileImageFile], `${uid}.${ImageExtenstion}`, {
    type: profileImageFile.type,
  });
  let rootStorage = storage.ref();
  let profileImage = rootStorage.child(`profile/${myNewFile.name}`);
  return profileImage.put(myNewFile);
};

export const updateUserAddress = async (address, userId) => {
  let user = await userCollection.doc(userId).get();
  if (user.exists) {
    let userdetails = { userId: user.id, ...user.data() };
    let addresses;
    if (userdetails.addresses) {
      addresses = [...userdetails.addresses];
    } else {
      addresses = [];
    }
    addresses.push(address);
    userdetails.addresses = addresses;
    await userCollection.doc(userId).set(userdetails);
  } else {
    throw new Error("Unable to find user details");
  }
};

export const registerNewUserApi = async (emailId, password) => {
  if (!emailId) {
    throw new Error("Email Id Cannot be undefiend or null");
  }
  if (!password) {
    throw new Error("Password Cannot be undefiend or null");
  }
  return await auth.createUserWithEmailAndPassword(emailId, password);
};

export const saveNewRegisterUserProfileDetailsApi = async (
  userdetails,
  roleId = "ROLE_CUSTOMER"
) => {
  let userId = userdetails.userId;
  if (userId) {
    let roleData = await roleCollection.doc(roleId).get();
    if (roleData.exists) {
      userdetails.roleId = roleId;
    } else {
      throw new Error("Cannot Find Role ", roleId);
    }
    delete userdetails.permissions;

    return await userCollection.doc(userId).set(userdetails);
  } else {
    throw new Error("User Id Cannot be Null or undefined");
  }
};

export const getUserDetailsByUserId = (userId) => {
  return userCollection.doc(userId).get();
};

export const logoutUser = async () => {
  return await auth.signOut();
};

export const sendPasswordResetEmailApi = async (email) => {
  return await auth.sendPasswordResetEmail(email);
};
