<template>
  <Carousel :wrap-around="true" :autoplay="4000">
    <Slide v-for="slide in offers" :key="slide">
      <div class="w-full h-[26rem]">
        <div v-if="!isLoading" class="banner-container relative w-full h-full">
          <div class="w-full h-full">
            <img
              :src="slide.imageUrl"
              alt="banner"
              class="object-cover w-full h-full"
            />
          </div>

          <div class="banner__overlay absolute inset-0 flex items-center px-12">
            <transition
              appear
              enter-active-class="animate__animated animate__slideInUp"
              leave-active-class="animate__animated animate__slideInDown"
              mode="out-in"
            >
              <div class="md:w-[40%] sm:w-[100%] space-y-6">
                <h1
                  class="text-3xl font-bold line-clamp-2 text-white font-montserrat uppercase"
                >
                  {{ slide.title }}
                </h1>

                <p
                  class="text-md line-clamp-4 font-medium text-white font-archivo"
                >
                  {{ slide.description }}
                </p>

                <div class="flex items-center justify-center space-x-2">
                  <router-link
                    :to="{
                      name: 'SEARCH_RESULTS',
                      query: { type: slide.type },
                    }"
                    class="inline-flex items-center text-sm justify-center px-5 py-2 z-40 border border-white font-medium rounded-md text-white bg-transparent"
                  >
                    Start Exploring
                  </router-link>
                </div>
              </div>
            </transition>
          </div>

          <div
            class="banner__overlay--down absolute bottom-0 h-32 w-full"
          ></div>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import useGalleryOfferStore from "@/store/gallery.store";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { mapState } from "pinia";

import "vue3-carousel/dist/carousel.css";
import router from "@/router";
export default {
  name: "GalleryOffers",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    router,
  },
  computed: {
    ...mapState(useGalleryOfferStore, ["offers"]),
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>

<style scoped>
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.banner__overlay--down {
  background-image: linear-gradient(
    to bottom,
    transparent 10%,
    rgba(var(--background-color-rgb), 0.8),
    rgba(var(--background-color-rgb), 1)
  );
}

.banner__overlay {
  background-image: linear-gradient(
    77deg,
    rgba(0, 0, 0, 0.6) 25%,
    transparent 85%
  );
}
</style>
