<template>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
    >

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->

    <div
      class="relative inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all align-middle sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
    >
      <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          @click.prevent="closeTheResetPasswordModal"
          class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span class="sr-only">Close</span>
          <!-- Heroicon name: outline/x -->
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div>
        <div
          class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
          v-if="resetPasswordLinkSent"
        >
          <!-- Heroicon name: outline/check -->
          <svg
            class="h-6 w-6 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <p
          v-if="resetPasswordLinkSent"
          class="mt-2 text-sm text-green-600 text-center font-archivo"
        >
          Reset Password Link has been sent..
        </p>
        <div
          class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100"
          v-if="hasServerError"
        >
          <svg
            class="h-6 w-6 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <p
          v-if="hasServerError"
          class="mt-2 text-sm text-red-600 text-center font-archivo"
        >
          {{ serverError.message }}
        </p>
        <div class="mt-3 sm:mt-5">
          <h3
            v-if="
              !hasServerError ||
              !resetPasswordLinkSent ||
              !resetPasswordLinkSent
            "
            class="2xl:text-lg leading-6 font-bold uppercase font-robotoSlab text-gray-900 md:text-md xs:text-xs text-center"
            id="modal-title"
          >
            Reset Password
          </h3>
          <vee-form
            :validation-schema="schema"
            @submit="handleResetPasswordLink"
          >
            <div class="mt-3">
              <div class="mt-1 relative rounded-md shadow-sm">
                <label class="text-sm font-archivo mb-4" for="emailId"
                  >Email Id</label
                >
                <vee-field
                  type="emailId"
                  name="emailId"
                  id="emailId"
                  v-model="emailId"
                  class="block py-2 px-3 w-full pr-10 border border-slate-300 text-slate-900 placeholder-slate-300 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm rounded-md"
                  placeholder="you@example.com"
                />
              </div>
              <ErrorMessage
                name="emailId"
                class="mt-2 text-sm text-red-600 capitalize font-archivo text-right"
              />
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                type="submit"
                :disabled="registrationInProgress"
                class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 hover:bg-indigo-700 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:text-sm xs:text-xs"
              >
                Reset Password
              </button>
            </div>
          </vee-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAuthStore from "@/store/auth.store";
import { mapActions, mapState, mapWritableState } from "pinia";
import { ErrorMessage } from "vee-validate";
export default {
  computed: {
    ...mapWritableState(useAuthStore, ["toggleUserInfoSidebar"]),
  },
  props: {
    isOverlayActive: {
      default: false,
      type: Boolean,
    },
  },
  beforeUnmount() {
    this.hasServerError = false;
    this.serverError = null;
    this.resetPasswordLinkSent = false;
    this.emailId = null;
    this.registrationInProgress = false;
  },
  data() {
    return {
      emailId: null,
      hasServerError: false,
      serverError: null,
      resetPasswordLinkSent: false,
      registrationInProgress: false,
      schema: {
        emailId: "required|email",
      },
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["sendPasswordResetEmailAction"]),
    closeTheResetPasswordModal() {
      this.$parent.$emit("OverlayOutsideClicked", true);
    },
    async handleResetPasswordLink(values) {
      this.registrationInProgress = true;
      this.hasServerError = false;
      this.serverError = null;
      this.resetPasswordLinkSent = false;
      try {
        let response = await this.sendPasswordResetEmailAction(this.emailId);
        this.resetPasswordLinkSent = true;
        // setTimeout(() => {
        // this.$parent.$emit("OverlayOutsideClicked", true);
        // }, 3000);
      } catch (error) {
        this.hasServerError = true;
        this.serverError = error;
        this.resetPasswordLinkSent = false;
      }
      this.registrationInProgress = false;
    },
  },
  watch: {
    isOverlayActive(oldVal, newVal) {
      if (newVal) {
        this.hasServerError = false;
        this.serverError = null;
        this.resetPasswordLinkSent = false;
        this.emailId = null;
        this.registrationInProgress = false;
      }
    },
  },
};
</script>

<style></style>
