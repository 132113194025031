import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import {
  saveNewProductApi,
  fetchAllProductsApi,
  getLatestProductDocAPI,
  getProductDetailsByProductIdAPI,
  updateProductDetailsByProductIdAPI,
  getPreorderGames,
  getConsolesApi,
  sortProductsByProductProperyAPI,
  getSimilarProductsAPI,
  deleteProductByProductIdAPI,
  getGamesByCategoryId,
  searchProductAPI,
  searchProductByProductTypeAPI,
  searchProductsByMultipleFiltersAPI,
  fetchPaginatedProductsAPI,
  getProductByQuantity,
} from "@/api/products.api";

import { productCollection } from "@/firebase/firebase.config";

export default defineStore("products", {
  state() {
    return {
      isProductsLoading: false,
      products: useStorage("products", []),
      preorders: [],
      consoles: [],
      ps5Games: [],
      ps4Games: [],
      ps3Games: [],
      xbox360Games: [],
      xboxOneGames: [],
      accessories: [],
      lastProductDoc: null,
      totalProductsInDocuments: 0,
      totalProductsInDB: 0,
      isProductFilterOn: {
        filters: [],
        isFilterOn: false,
      },
      filters: {
        isFilterApplied: false,
        query: null,
      },
    };
  },
  actions: {
    async saveNewProductDetailsActions(product) {
      return await saveNewProductApi(product);
    },
    async fetchAllProductDetailsAction() {
      let productsData = await fetchPaginatedProductsAPI();
      let productz = [];
      productsData.forEach((product) => {
        let pro = { productId: product.id, ...product.data() };
        productz.push(pro);
      });
      this.products = productz;
    },

    async fetchPaginatedProductsAction(
      limit = 10,
      type,
      lastDoc,
      sortBy = "createdAt",
      sortOrder = "asc",
      condition,
      categoryId,
      brandId,
      genre,
      globalSearchText
    ) {
      let response;
      if (this.products.length) {
        let lastProduct = this.products[this.products.length - 1];
        if (lastProduct.productId)
          lastDoc = await this.getProductDocByProductIdAction(
            lastProduct.productId
          );
        response = await fetchPaginatedProductsAPI(
          limit,
          type,
          lastDoc,
          sortBy,
          sortOrder,
          condition,
          categoryId,
          brandId,
          genre,
          globalSearchText
        );
        let newRecords = [];
        response.forEach((doc) => {
          this.products.push({ productId: doc.id, ...doc.data() });
        });
      } else {
        response = await fetchPaginatedProductsAPI(
          limit,
          type,
          null,
          sortBy,
          sortOrder,
          condition,
          categoryId,
          brandId,
          genre,
          globalSearchText
        );
        let newRecords = [];
        response.forEach((doc) => {
          newRecords.push({ productId: doc.id, ...doc.data() });
        });
        this.products = [...newRecords];
      }
    },

    async getProductDetailsByProductIdAction(productId) {
      return await getProductDetailsByProductIdAPI(productId);
    },

    async deleteProductDetailsByProductIdAction(productId) {
      return deleteProductByProductIdAPI(productId);
    },

    async updateProductDetailsByProductIdAction(productdetails) {
      return await updateProductDetailsByProductIdAPI(productdetails);
    },

    async getPreorderedGamesAction(limit = 5) {
      let preordersApi = await getPreorderGames(limit);
      let productz = [];
      preordersApi.forEach((product) => {
        let preOrderProduct = { productId: product.id, ...product.data() };
        productz.push(preOrderProduct);
      });
      this.preorders = productz;
    },

    async getConsolesActions(limit = 5) {
      let gamingConsoles = await getConsolesApi(limit);
      let gaming = [];
      gamingConsoles.forEach((console) => {
        let c = { productId: console.id, ...console.data() };
        gaming.push(c);
      });
      this.consoles = gaming;
    },

    async getGamesByCategoryAction(categoryId, limit = 5) {
      return await getGamesByCategoryId(categoryId, limit);
    },

    async loadGamesByCategoryAction() {
      this.isProductsLoading = true;
      let ps5Games = await getGamesByCategoryId("Playstation_5_(Games)", 5);
      let ps4Games = await getGamesByCategoryId("Playstation_4_(Games)", 5);
      let ps3Games = await getGamesByCategoryId("Playstation_3_(Games)", 5);
      let xbox360Games = await getGamesByCategoryId("Xbox_360_(Games)", 5);
      let xboxOneGames = await getGamesByCategoryId("Xbox_One_(Games)", 5);
      let ps5Gamez = [];
      let ps4Gamez = [];
      let ps3Gamez = [];
      let xbox360Gamez = [];
      let xboxOneGamez = [];

      ps5Games.forEach((game) => {
        let ps5Game = { productId: game.id, ...game.data() };
        ps5Gamez.push(ps5Game);
      });
      ps4Games.forEach((game) => {
        let ps4Game = { productId: game.id, ...game.data() };
        ps4Gamez.push(ps4Game);
      });
      ps3Games.forEach((game) => {
        let ps3Game = { productId: game.id, ...game.data() };
        ps3Gamez.push(ps3Game);
      });
      xbox360Games.forEach((game) => {
        let xbox360Game = { productId: game.id, ...game.data() };
        xbox360Gamez.push(xbox360Game);
      });
      xboxOneGames.forEach((game) => {
        let xboxOneGame = { productId: game.id, ...game.data() };
        xboxOneGamez.push(xboxOneGame);
      });

      this.ps5Games = ps5Gamez;
      this.ps4Games = ps4Gamez;
      this.ps3Games = ps3Gamez;
      this.xbox360Games = xbox360Gamez;
      this.xboxOneGames = xboxOneGamez;
      this.isProductsLoading = false;
    },

    async sortProductsByProductProperyAction(propertyName, order = "asc") {
      let products = await sortProductsByProductProperyAPI(propertyName, order);
      let sortedProducts = [];
      products.forEach((product) => {
        let sorted = { productId: product.id, ...product.data() };
        sortedProducts.push(sorted);
      });
      this.products = sortedProducts;
    },
    async getSimilarProductsAction(currentProductId, genreId, categoryId) {
      return await getSimilarProductsAPI(currentProductId, genreId, categoryId);
    },

    async searchProductsAction(
      categoryId,
      brandId,
      type,
      genre,
      condition,
      discount,
      searchText,
      limit = 2,
      sortBy,
      sortOrder,
      lastProductId
    ) {
      let searchProductsArr = [];
      let searchedProducts = await searchProductAPI(
        categoryId,
        brandId,
        type,
        genre,
        condition,
        discount,
        searchText,
        limit,
        sortBy,
        sortOrder,
        lastProductId
      );
      searchedProducts.forEach((searchProduct) => {
        let prod = { productId: searchProduct.id, ...searchProduct.data() };
        searchProductsArr.push(prod);
      });
      this.products = searchProductsArr;
    },

    async searchProductByProductTypeAction(type = "Accessories", limit = 5) {
      let response = await searchProductByProductTypeAPI(type, limit);
      let searchProducts = [];
      response.forEach((product) => {
        let searchedProduct = { productId: product.id, ...product.data() };
        searchProducts.push(searchedProduct);
      });
      if (type === "Accessories") {
        this.accessories = searchProducts;
      }
    },

    async searchProductsByMultipleFilters(filters) {
      this.products = [];
      if (filters.length) {
        this.isProductFilterOn.isFilterOn = true;
        this.isProductFilterOn.filters = filters;
        filters.forEach((newFilter) => {
          this.isProductFilterOn.filters.forEach((oldFilter) => {
            if (newFilter.typeId !== oldFilter.typeId) {
              this.isProductFilterOn.filters = [];
              this.isProductFilterOn.filters.push(newFilter);
            }
          });
        });

        let response = await searchProductsByMultipleFiltersAPI(
          this.isProductFilterOn.filters
        );
        console.log("response da..", response);
        let searchProducts = [];
        response.forEach((product) => {
          let searchedProduct = { productId: product.id, ...product.data() };
          searchProducts.push(searchedProduct);
        });
        this.products = searchProducts;
      } else {
        this.products = [];
      }
    },

    async getProductDocByProductIdAction(productId) {
      return await getLatestProductDocAPI(productId);
    },

    clearProductFiltersAction() {
      this.filters = {
        isFilterApplied: false,
        productType: null,
      };
    },
    async getProductsByQuatityAction(quantity = 0) {
      this.isProductFilterOn.isFilterOn = true;
      let response = await getProductByQuantity(quantity);
      this.products = [];
      response.forEach((product) => {
        let searched_product = { productId: product.id, ...product.data() };
        this.products.push(searched_product);
      });
    },

    clearSidebarProductFilterAction() {
      if (this.isProductFilterOn && this.isProductFilterOn.isFilterOn) {
        this.isProductFilterOn.isFilterOn = false;
        this.isProductFilterOn.filters = [];
      }
    },
  },
});
