import { defineStore } from "pinia";
import {
  saveNewCategoryDetails,
  fetchAllSavedCategories,
  sortCategoryByField,
  getCategoryByCategoryId,
  updateCategoryByCategoryId,
  deleteCategoryByCategoryId,
} from "@/api/categories.api";

export default defineStore("categories", {
  state() {
    return {
      categories: [],
      games: [],
      consoles: [],
      sidebarCategories: [],
    };
  },
  getters: {},
  actions: {
    sidebarMenu() {
      let gamesList = [];
      let consolesList = [];
      let accessoriesList = [];
      this.categories.forEach((category) => {
        if (category.categoryName.includes("games")) {
          gamesList.push(category);
        }
        if (categories.categoryName.includes("consoles")) {
          consolesList.push(category);
        }
        if (categories.categoryName.includes("accessories")) {
          accessoriesList.push(category);
        }
      });
      this.games = gamesList;
      this.consoles = consolesList;
    },
    async saveNewCategoryDetailsActions(category) {
      let savedCategory = await saveNewCategoryDetails(category);
      return savedCategory;
    },
    async getAllSavedCategoriesAction() {
      let categories = await fetchAllSavedCategories();
      let savedCategories = [];
      let games = [];
      let consoles = [];
      let accessories = [];
      let sidebarmenu = [];

      categories.forEach((category) => {
        let newCategory = {
          categoryName: "",
          ...category.data(),
          categoryId: category.id,
          id: category.id,
          label: "",
        };
        if (newCategory.categoryName.includes("Games")) {
          newCategory.icon = "Games";
          games.push(newCategory);
        }
        if (newCategory.categoryName.includes("Consoles")) {
          newCategory.icon = "Consoles";
          consoles.push(newCategory);
        }
        if (newCategory.categoryName.includes("Accessories")) {
          newCategory.icon = "Accessories";
          accessories.push(newCategory);
        }

        if (newCategory.categoryName.includes("Playstation")) {
          newCategory.icon = "playstation";
        }

        if (newCategory.categoryName.includes("Xbox")) {
          newCategory.icon = "xbox";
        }

        if (newCategory.categoryName.includes("Nintendo")) {
          newCategory.icon = "nintendo";
        }

        if (newCategory && newCategory.categoryName) {
          newCategory.label = newCategory.newCategoryName;
        }

        savedCategories.push(newCategory);
      });

      let gameCategory = {
        categoryId: "RootGameCategoryId",
        categoryName: "Games",
        subCategories: games,
        label: "Games",
        icon: "Games",
      };
      let consoleCategory = {
        categoryId: "rootConsoleCategoryId",
        categoryName: "Consoles",
        subCategories: consoles,
        label: "Consoles",
        icon: "Consoles",
      };

      let accessoriesCategory = {
        cateogoryId: "rootAccessoriesCategoryId",
        categoryName: "Accessories",
        subCategories: accessories,
        icon: "Accessories",
      };

      sidebarmenu.push(gameCategory);
      sidebarmenu.push(consoleCategory);
      sidebarmenu.push(accessoriesCategory);

      this.sidebarCategories = sidebarmenu;

      this.categories = savedCategories;
      this.games = games;
      this.consoles = consoles;
    },

    async sortCategoriesByFieldAction(fieldName, order = "asc") {
      let categories = await sortCategoryByField(fieldName, order);
      let sortedCategories = [];
      categories.forEach((category) => {
        sortedCategories.push({ ...category.data(), categoryId: category.id });
      });
      this.categories = sortedCategories;
    },
    async getACategoryByCategoryId(categoryId) {
      return await getCategoryByCategoryId(categoryId);
    },
    async updatedCategoryDetailsByCategoryId(categoryId, categoryDetails) {
      debugger;
      return await updateCategoryByCategoryId(categoryId, categoryDetails);
    },
    async deleteCategoryDetailsByCategoryIdAction(categoryId, categoryName) {
      return await deleteCategoryByCategoryId(categoryId, categoryName);
    },

    getCategoryNameByCategoryIdAction(categoryId) {
      return this.categories.find((category) => {
        return category.categoryId === categoryId;
      });
    },
  },
});
