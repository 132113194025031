<template>
  <div
    class="fixed inset-0 h-full w-full"
    :class="{ hidden: !isOverlayActive, 'z-40': isOverlayActive }"
    @click.prevent="overlayClicked"
  ></div>
</template>

<script>
export default {
  methods: {
    overlayClicked() {
      this.$emit("overlayClicked");
    },
  },
  props: {
    isOverlayActive: {
      default: false,
      required: true,
    },
  },
};
</script>

<style></style>
