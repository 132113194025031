<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-4 sm:px-8 sm:py-4 shadow-sm border">
      <div class="px-4 sm:px-0">
        <h1
          class="text-sm font-semibold uppercase tracking-wide text-indigo-600"
        >
          Thank you!
        </h1>
        <div class="">
          <p
            class="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl"
            v-if="order.currentStatus === 'ORDER_SHIPPED'"
          >
            It's on the way!
          </p>
          <p class="mt-2 text-indigo-600">
            Once the Product is Shipped you can track the product delivery
            status.
          </p>
          <p
            class="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl"
            v-if="order.currentStatus === 'ORDER_PLACED'"
          >
            Order Placed!!
          </p>
        </div>

        <p class="mt-2 text-base text-gray-500">
          Your order
          <span class="font-bold text-black"> {{ order.orderId }}</span> has
          <span v-if="order.currentStatus === 'ORDER_PLACED'"
            >been placed and will be shipped soon.</span
          >
          <span v-else-if="order.currentStatus === 'ORDER_SHIPPED'"
            >been shipped and will be with you soon.</span
          >
        </p>
        <dl
          class="mt-12 text-sm font-medium"
          v-if="
            order.currentStatus === 'ORDER_SHIPPED' &&
            this.order &&
            this.order.orderStatus &&
            this.order.orderStatus.length == 2
          "
        >
          <dt class="text-gray-900">Tracking number</dt>
          <dd class="text-indigo-600 mt-2">
            {{ this.order.orderStatus[1].trackingOrderNumber }}
          </dd>
        </dl>
        <dl
          class="mt-2 text-sm font-medium"
          v-if="
            order.currentStatus === 'ORDER_SHIPPED' &&
            this.order &&
            this.order.orderStatus &&
            this.order.orderStatus.length == 2
          "
        >
          <dt class="text-gray-900">Courier Name</dt>
          <dd class="text-indigo-600 mt-2">
            {{ this.order.orderStatus[1].courierName }}
          </dd>
        </dl>
        <dl class="mt-3 text-sm font-medium" v-if="order.createdAt != null">
          <dt class="text-gray-900">Order Placed On</dt>
          <dd class="text-indigo-600 mt-2">
            {{ moment(order.createdAt.toDate()).format(`MMMM Do YYYY`) }}
          </dd>
        </dl>
        <dl class="mt-3 text-sm font-medium">
          <dt class="text-gray-900">Order Placed</dt>
          <dd class="text-indigo-600 mt-2">
            {{ order.orderedBy }}
          </dd>
        </dl>
        <dl class="mt-3 text-sm font-medium">
          <dt class="text-gray-900">Order Id</dt>
          <dd class="text-indigo-600 mt-2">
            {{ order.orderId }}
          </dd>
        </dl>
        <dl
          class="mt-3 text-sm font-medium"
          v-if="
            hasUserPermissions(CREATE_ORDERS) ||
            hasUserPermissions(CREATE_ONLY_ORDERS)
          "
        >
          <dt class="text-gray-900">Razorpay Id</dt>
          <dd class="text-indigo-600 mt-2">
            {{ order.razorpay_payment_id }}
          </dd>
        </dl>
        <dl class="mt-3 text-sm font-medium font-archivo">
          <dt class="text-gray-900">Delivery Address</dt>
          <dd class="text-indigo-600 mt-2">
            <span class=""
              >{{ order.deliveryAddress.firstName }}
              {{ order.deliveryAddress.lastName }}
              {{ order.deliveryAddress.addressLine1 }}
              {{ order.deliveryAddress.addressLine2 }}
              {{ order.deliveryAddress.city }} {{ order.deliveryAddress.state }}
              {{ order.deliveryAddress.country.name }}
              Pincode:{{ order.deliveryAddress.pincode }}</span
            >
          </dd>
        </dl>
        <!-- <SelectDropdown
          v-if="doesUserHavePermissionToUpdateStatus()"
          :toggleOptions="toggleSelectDropdown"
          :options="selectOptions"
          :label="status"
          @handleSelectedOptionValue="handleSelectedOptionValue"
        /> -->
        <div class="">
          <router-link
            v-if="order.currentStatus === 'ORDER_SHIPPED'"
            :to="{
              name: 'ORDER_INVOICE_ID',
              params: { orderId: order.orderId },
            }"
            class="inline-flex my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
          >
            Download Order Invoice
          </router-link>
          <button
            v-if="order.currentStatus === 'ORDER_SHIPPED'"
            @click.prevent="handleTrackOrderRedirect"
            class="ml-4 inline-flex my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
          >
            Track Your Order
          </button>
        </div>
        <div>
          <button
            @click.prevent="productShipped"
            v-if="
              order.currentStatus === 'ORDER_PLACED' &&
              (hasUserPermissions(CREATE_ORDERS) ||
                hasUserPermissions(CREATE_ONLY_ORDERS))
            "
            :disabled="isRequestInProgress"
            :class="{ 'cursor-not-allowed': isRequestInProgress }"
            class="inline-flex my-2 mt-8 items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
          >
            Ship The Order
          </button>
        </div>
      </div>

      <div class="mt-16">
        <h2 class="sr-only">Recent orders</h2>

        <div class="space-y-16 sm:space-y-24">
          <div>
            <h3 class="sr-only">
              Order placed on
              <time datetime="2021-01-22">January 22, 2021</time>
            </h3>

            <div
              class="mt-6 flow-root px-4 sm:mt-10 sm:px-0"
              v-for="orderdetails in order.products"
              :key="orderdetails"
            >
              <div class="-my-6 divide-y divide-gray-200 sm:-my-10">
                <div class="flex py-6 sm:py-10">
                  <div class="min-w-0 flex-1 lg:flex lg:flex-col">
                    <div class="lg:flex-1">
                      <div class="sm:flex">
                        <div>
                          <h4 class="font-medium text-gray-900 text-sm">
                            {{ orderdetails.productName }}
                          </h4>
                          <p class="hidden mt-2 text-sm text-gray-500 sm:block">
                            {{ orderdetails.shortDescription }}
                          </p>
                        </div>
                      </div>
                      <p
                        class="mt-2 font-medium text-gray-900 sm:mt-0 block text-sm"
                      >
                        ₹ {{ orderdetails.price }}
                      </p>
                      <p
                        class="mt-4 font-medium text-gray-900 sm:mt-0 block text-sm"
                      >
                        <span class="mr-1">Quantity:</span
                        >{{ orderdetails.quantity }}
                      </p>
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0 sm:m-0 sm:mr-6 sm:order-first">
                    <img
                      :src="orderdetails.mainImageUrl"
                      alt="Olive drab green insulated bottle with flared screw lid and flat top."
                      class="col-start-2 col-end-3 sm:col-start-1 sm:row-start-1 sm:row-span-2 w-24 h-24 object-contain rounded-lg object-center sm:w-18 sm:h-18 lg:w-24 lg:h-24"
                    />
                  </div>
                </div>

                <!-- More products... -->
              </div>
            </div>
          </div>

          <!-- More orders... -->
        </div>
      </div>
      <dl
        class="mt-12 text-sm font-medium"
        v-if="order.currentStatus === 'ORDER_PLACED'"
      >
        <dt class="text-gray-900">Note:</dt>
        <dd class="text-rose-600 mt-2">
          Once The Order will be shipped you can track the delivery for live
          updates .
        </dd>
      </dl>
    </div>
  </div>
  <SlotOverLay
    :isOverlayActive="isOverlayActive"
    :applyBackdrop="true"
    @OverlayOutsideClicked="isOverlayActive = false"
    ><OrderShippedInfoModal @handleFormOrderSubmission="handleSubmission"
  /></SlotOverLay>
</template>

<script>
import OrderStatusCard from "./Order.status.card.vue";
import OrderShippedInfoModal from "./Order.shipped.info.modal.vue";
import SlotOverLay from "@/components/Commons/Overlays/Overlay.vue";
import { ordersCollection } from "@/firebase/firebase.config";
import { CREATE_ORDERS, CREATE_ONLY_ORDERS } from "@/assets/Permissions";
import { hasUserPermissions } from "@/utils/auth.util";
import moment from "moment";
import useAuthStore from "@/store/auth.store";
import SelectDropdown from "@/components/Commons/Dropdowns/Dropdown.select.vue";
import useOrderStore from "@/store/orders.store";
import { mapActions, mapState } from "pinia";
export default {
  data() {
    return {
      hasUserPermissions,
      CREATE_ONLY_ORDERS,
      CREATE_ORDERS,
      isProductShipped: false,
      isOverlayActive: false,
      order: {
        orderId: 0,
        products: [],
        createdAt: null,
        deliveryAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: { isoCode: "", name: "" },
          firstName: "",
          lastName: "",
          note: "",
          pincode: "",
          state: "",
          type: "",
        },
      },
      toggleSelectDropdown: false,
      status: "Status",
      selectOptions: [],
      statusBar: 1,
      moment,
      isRequestInProgress: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
    handleTrackOrderRedirect() {
      if (
        this.order &&
        this.order.orderStatus &&
        this.order.orderStatus.length == 2
      ) {
        let link = this.order.orderStatus[1].courierTrackingLink;
        window.open(link);
      }
    },
    getStatusBarVal() {
      return (this.statusBar / 8) * 100;
    },
    getCurrentOrderStatus() {
      let data = {
        status: "Default Status",
        orderPlacedAt: null,
      };
      if (
        this.order &&
        this.order.orderStatus &&
        this.order.orderStatus.length
      ) {
        let latestStatus =
          this.order.orderStatus[this.order.orderStatus.length - 1];
        switch (latestStatus.status) {
          case "ORDER_PLACED":
            data.status = "Placed";
            break;
          case "ORDER_SHIPPED":
            data.status = "Shipped";
            break;
          case "ORDER_OUT_FOR_DELIVERY":
            data.status = "Out For Delivery";
            break;
          case "ORDER_DELIVERED":
            data.status = "Delivered";
            break;
        }
      }
      return data;
    },
  },
  methods: {
    ...mapActions(useOrderStore, [
      "getOrderDetailsByOrderIdAction",
      "updateOrderDetailsByOrderIdAction",
    ]),
    async handleSelectedOptionValue(option, idx) {
      if (this.status != option.label) {
        this.status = option.value;
        let currentUserEmailId =
          this.userdetails && this.userdetails.emailId
            ? this.userdetails.emailId
            : null;
        let currentUser =
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null;
        let newOrderUpdatedDetails = { ...this.order };
        newOrderUpdatedDetails.updatedAt = new Date();
        newOrderUpdatedDetails.updatedBy = currentUser;

        let listOfStatus = newOrderUpdatedDetails.orderStatus;
        if (listOfStatus && listOfStatus.length) {
          let oldStatusList = [...listOfStatus];
          let newStatus = {
            status: this.status,
            createdAt: new Date(),
            createdBy: currentUser,
          };
          switch (this.status) {
            case "SHIPPED":
              newStatus = {
                status: "ORDER_SHIPPED",
                createdAt: new Date(),
                createdBy: currentUser,
                createdByEmailId: currentUserEmailId,
                trackingOrderNumber: option.trackingOrderNumber,
                courierName: option.courierName,
                courierTrackingLink: option.courierTrackingLink,
              };
              break;
            // case "DELIVERED":
            //   newStatus = {
            //     status: "ORDER_DELIVERED",
            //     createdAt: new Date(),
            //     createdBy: currentUser,
            //     createdByEmailId: currentUserEmailId,
            //   };
            //   break;
            // case "ONDELIVERARY":
            //   newStatus = {
            //     status: "ORDER_OUT_OF_DELIVERY",
            //     createdAt: new Date(),
            //     createdBy: currentUser,
            //     createdByEmailId: currentUserEmailId,
            //   };
            //   break;
          }
          oldStatusList.push(newStatus);
          newOrderUpdatedDetails.orderStatus = oldStatusList;
          newOrderUpdatedDetails.currentStatus = newStatus.status;
        }
        let currentOrderStatus =
          this.order.orderStatus[this.order.orderStatus.length - 1].status;
        if (this.status && currentOrderStatus !== "DELIVERED") {
          await this.updateOrderDetailsByOrderIdAction(
            this.order.orderId,
            newOrderUpdatedDetails
          );
        }
      }
    },
    doesUserHavePermissionToUpdateStatus() {
      return hasUserPermissions(CREATE_ORDERS);
    },
    async handleSubmission(val) {
      let option = { label: "Shipped", value: "SHIPPED", ...val };
      await this.handleSelectedOptionValue(option);
      this.isOverlayActive = false;
    },
    cancelOrder(orderId) {
      let decision = confirm(
        `Are you sure you want to delete order by order id ${orderId}`
      );
      if (decision) {
        this.isRequestInProgress = true;
        let reasonForCancelation = prompt(
          "Reason For Order Cancellation ",
          `Eg: Order Items are out of stock`
        );
        setTimeout(() => {
          this.isRequestInProgress = false;
        }, 3000);
      }
    },
    productShipped() {
      this.isOverlayActive = !this.isOverlayActive;
    },
  },
  async created() {
    const { orderid } = this.$route.query;
    ordersCollection.doc(orderid).onSnapshot((orderSnapShot) => {
      if (orderSnapShot.exists) {
        let orderdetails = {
          orderId: orderSnapShot.id,
          ...orderSnapShot.data(),
        };
        this.order = orderdetails;
        let currentStatus =
          this.order.orderStatus[this.order.orderStatus.length - 1];
        if (this.order && this.order.orderStatus.length) {
          let statusLists = [];
          let currentStatus =
            this.order.orderStatus[this.order.orderStatus.length - 1];
          if (currentStatus) {
            if (currentStatus.status) {
              let latestStatus = currentStatus.status;
              let SHIPPED = null;
              let ONDELIVERARY = null;
              let DELIVERED = null;
              switch (latestStatus) {
                case "ORDER_PLACED":
                  SHIPPED = { value: "SHIPPED", label: "Shipped" };
                  this.statusBar = 1;
                  statusLists.push(SHIPPED);
                  break;
                case "ORDER_SHIPPED":
                  ONDELIVERARY = {
                    value: "ONDELIVERARY",
                    label: "Out For Delivery",
                  };
                  DELIVERED = { value: "DELIVERED", label: "Delivered" };
                  this.statusBar = 8;
                  statusLists.push(ONDELIVERARY);
                  break;
                case "ORDER_OUT_OF_DELIVERY":
                  DELIVERED = { value: "DELIVERED", label: "Delivered" };
                  statusLists.push(DELIVERED);
                  this.statusBar = 5;
                  break;
                case "ORDER_DELIVERED":
                  this.statusBar = 8;
                  break;
              }
              this.selectOptions = statusLists;
              if (currentStatus && currentStatus.status) {
                this.status = currentStatus.status;
              }
            }
          }
        }
      }
    });
  },
  components: {
    SelectDropdown,
    SlotOverLay,
    OrderShippedInfoModal,
    OrderStatusCard,
  },
};
</script>

<style></style>
