<template>
  <div class="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
    <div class="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
      <slot></slot>
      <div class="ml-4 flex-auto flex flex-col">
        <slot name="title"></slot>
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerTitle: {
      default: "No Title Has Been Provided",
      type: String,
      required: true,
    },
    bannerDesc: {
      default: "No Banner Description Has Been Provided",
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
