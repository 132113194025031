<template>
  <div class="flex items-center justify-center space-x-2">
    <div
      class="w-2 h-2 rounded-full animate-pulse bg-white dark:bg-violet-400"
    ></div>
    <div
      class="w-2 h-2 rounded-full animate-pulse bg-white dark:bg-violet-400"
    ></div>
    <div
      class="w-2 h-2 rounded-full animate-pulse bg-white dark:bg-violet-400"
    ></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
