<template>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
    >
      <vee-form
        @submit="handleOrderShippedStatus"
        :validation-schema="orderShippedSchema"
      >
        <div class="space-y-6">
          <h3
            class="text-lg leading-6 text-gray-900 text-center uppercase font-bold"
            id="modal-title"
          >
            Ship Order
          </h3>
          <div>
            <label
              for="trackingOrderNumber"
              class="block text-sm font-medium text-gray-700"
            >
              Tracking Order Number
            </label>
            <div class="mt-1">
              <vee-field
                id="trackingOrderNumber"
                name="trackingOrderNumber"
                v-model="trackingOrder.trackingOrderNumber"
                type="text"
                autocomplete="email"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <ErrorMessage
                name="trackingOrderNumber"
                class="text-red-500 text-xs capitalize font-archivo"
              />
            </div>
          </div>

          <div>
            <label
              for="courierName"
              class="block text-sm font-medium text-gray-700"
            >
              Courier Name
            </label>
            <div class="mt-1">
              <vee-field
                id="courierName"
                name="courierName"
                v-model="trackingOrder.courierName"
                type="text"
                autocomplete="current-password"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <ErrorMessage
                name="courierName"
                class="text-red-500 text-xs capitalize font-archivo"
              />
            </div>
          </div>
        </div>

        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row">
          <button
            type="submit"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 mr-4 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Submit
          </button>
          <button
            type="button"
            @click.prevent="closeModal"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </vee-form>
    </div>
  </div>
</template>

<script>
import { COURIER_SEARCH_SITE_LINK } from "@/assets/Constants";
export default {
  data() {
    return {
      orderShippedSchema: {
        trackingOrderNumber: "required|min:8|max:15",
        courierName: "required|min:2|max:50",
      },
      COURIER_SEARCH_SITE_LINK,
      trackingOrder: {
        trackingOrderNumber: "",
        courierName: "",
        courierTrackingLink: COURIER_SEARCH_SITE_LINK,
      },
    };
  },
  methods: {
    handleOrderShippedStatus(values) {
      this.$emit("handleFormOrderSubmission", this.trackingOrder);
      this.trackingOrder = {
        trackingOrderNumber: "",
        courierName: "",
        courierTrackingLink: COURIER_SEARCH_SITE_LINK,
      };
    },
    closeModal() {
      this.$parent.$emit("OverlayOutsideClicked", true);
      this.trackingOrder = {
        trackingOrderNumber: null,
        courierName: null,
        courierTrackingLink: COURIER_SEARCH_SITE_LINK,
      };
    },
  },
  created() {},
};
</script>

<style></style>
