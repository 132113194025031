<template>
  <tr class="animate-pulse" v-for="n in 10" :key="n">
    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
      <!-- Selected row marker, only show when row is selected. -->
      <div
        class="absolute inset-y-0 left-0 w-0.5 bg-transparent focus:bg-gray-600"
      ></div>

      <input
        type="checkbox"
        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500 sm:left-6"
      />
    </td>
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
      <div class="flex items-center">
        <div class="h-10 w-10 flex-shrink-0">
          <div class="h-10 w-10 bg-slate-500" alt=""></div>
        </div>
        <div class="ml-4">
          <div
            class="font-medium h-2 w-20 mb-2 text-gray-900 text-md bg-slate-500"
          ></div>
          <div class="">
            <div>
              <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <div class="font-medium h-2 w-20 mb-2 bg-gray-500"></div>
      <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
    </td>
    <td
      class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
    >
      <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
      <div class="font-medium h-2 w-8 mb-2 bg-gray-500"></div>
    </td>
  </tr>
</template>

<script>
export default {};
</script>

<style></style>
