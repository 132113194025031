export const HEADER_SEARCH_INPUT_PLACEHOLDER = "Search Games";

// ADD PRODUCT FORM

export const ADD_PRODUCT_NAME_PLACEHOLDER =
  "Eg: Grand Theft Auto 5, XBOX 360, Playstation 5";
export const ADD_PRODUCT_FORM_PRICE_PLACEHOLDER = "100";
export const ADD_PRODUCT_FORM_IMAGE_URL = "https://reygamescastle.jpg";
export const PRODUCT_MANUFACTURERS = "Sony India Pvt Ltd";
export const PRODUCT_WARRANTY = "2-Years Warranty";
export const PRODUCT_DIMENSIONS = "13.8 x 1.5 x 17.9 cm; 80 Grams";
export const PRODUCT_LANGUAGE = "English";
export const PRODUCT_RELEASE_DATE = "21st Sept 2023";

//
export const NO_SIMILAR_PRODUCTS_FOUND = "Cannot Find Similar Products!!!";

export const bannerData = [
  {
    bannerTitle: "Buy",
    bannerDescription:
      "Buy any New or Preowned games,Consoles,Accessories at low prices",
  },
  {
    bannerTitle: "Play",
    bannerDescription: "Enjoy the purchased product as long as you wish.",
  },
  {
    bannerTitle: "Swap",
    bannerDescription:
      "Swap Any Games with other titles and get 70% off while swaping",
    bannerIcon: ``,
  },
];

export const NO_USER_ADDRESS_FOUND_MESSAGE =
  "You havent added any addresses. Go to profile section and add new address";
export const NO_REFUND_POLICY_MESSAGE =
  "Once The Order has been placed. Their will be no order cancellation policy.";
export const COURIER_SEARCH_SITE_LINK = "https://trackcourier.io/";

export const PRODUCT_SELLAR_WARRANTY =
  "This product's warranty is given by sellar. i.e Reygamescastle for further warranty details contact store for further information.";
export const PRODUCT_COMPANY_WARRANTY =
  "This product's warranty is given by company. contact store for further information";
export const PRODUCT_NO_WARRANTY = "No warranty is given on this product.";
