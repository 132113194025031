import {
  brandsCollection,
  productCollection,
} from "@/firebase/firebase.config";

export const updateBrandDetailsByBrandId = async (brandId, brandDetails) => {
  let isBrandNameTakenByOtherBrand = false;
  if (brandDetails.brandName) {
    let brandNamesList = await brandsCollection
      .where("brandName", "==", brandDetails.brandName)
      .get();
    if (!brandNamesList.empty) {
      brandNamesList.forEach((brand) => {
        let b = { brandId: brand.id, ...brand.data() };
        if (b.brandId !== brandDetails.brandId) {
          isBrandNameTakenByOtherBrand = true;
        }
      });
    }
  }
  if (isBrandNameTakenByOtherBrand) {
    throw new Error(
      `Brand Name is Already Taken by other brand. choose other name`
    );
  } else {
    let response = await brandsCollection.doc(brandId).get();
    if (response.exists) {
      return await brandsCollection.doc(brandId).set(brandDetails);
    } else {
      throw new Error("Couldnt Find Brand by id " + brandId);
    }
  }
};

export const getBrandByBrandId = async (brandId) => {
  return await brandsCollection.doc(brandId).get();
};

export const createNewBrandDetails = async (brandDetails) => {
  let isBrandNameTaken = await brandsCollection
    .where("brandName", "==", brandDetails.brandName)
    .get();
  if (!isBrandNameTaken.empty) {
    throw new Error("Brand Name Already Taken  Please Chose Other Name");
  } else {
    return await brandsCollection.add(brandDetails);
  }
};

export const fetchAllSavedBrandDetails = (sort = "asc") => {
  return brandsCollection.orderBy("brandName", sort);
};

export const deleteBrandByBrandId = async (brandId) => {
  let productRef = await productCollection
    .where("brandId", "==", brandId)
    .get();

  if (!productRef.empty) {
    let productNames = "";
    productRef.forEach((product) => {
      let prod = { productId: product.id, ...product.data() };
      productNames += " " + prod.productName;
    });
    throw new Error(
      `Cannot Delete Brand as its used by product ${productNames} `
    );
  } else {
    let brandRef = await brandsCollection.doc(brandId).get();

    if (brandRef.exists) {
      await brandsCollection.doc(brandId).delete();
    } else {
      throw new Error("Unable to delete brand by brandId ", brandId);
    }
  }
};
