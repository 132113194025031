import { statusCollection } from "@/firebase/firebase.config";

export const fetchAllStatus = async () => {
  return await statusCollection.orderBy("statusName", "asc").get();
};

export const saveNewStatusDetails = async (status) => {
  let isStatusTaken = await statusCollection
    .where("statusName", "==", status.statusName)
    .get();
  if (isStatusTaken.empty) {
    return await statusCollection
      .doc(status.statusName.toUpperCase())
      .set(status);
  }
  throw new Error(`Status Name ${status.statusName} already exists `);
};

export const getStatusByStatusId = async (statusId) => {
  let status = {};
  let response = await statusCollection.doc(statusId).get();
  status = { ...response.data() };

  return status;
};

export const deleteStatusByStatusIdApi = async (statusId) => {
  return await statusCollection.doc(statusId).delete();
};

export const updateStatusByStatusIdApi = async (statusId, status) => {
  return await statusCollection.doc(statusId.replace(/ /g, "_")).set(status);
};
