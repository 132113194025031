<template>
  <div class="space-y-6 mb-24">
    <vee-form
      :validation-schema="productValidationSchema"
      @submit="saveNewProductDetails"
    >
      <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="mainImageUrl"
                  class="block text-sm font-medium text-gray-700"
                  >Product ID</label
                >
                <vee-field
                  type="text"
                  name="productId"
                  id="productId"
                  autocomplete="family-name"
                  readonly="readonly"
                  :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                  v-model="product.productId"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="mainImageUrl"
                  class="block text-sm font-medium text-gray-700"
                  >Product Main Cover Image
                  <span class="text-xs text-yellow-600"
                    >Note: Best Image dimensions are 400(w)X500(h)</span
                  >
                </label>
                <vee-field
                  type="text"
                  name="mainImageUrl"
                  id="mainImageUrl"
                  autocomplete="family-name"
                  :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                  v-model="product.mainImageUrl"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="mainImageUrl"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="productName"
                  class="block text-sm font-medium text-gray-700"
                  >Product Name</label
                >
                <vee-field
                  type="text"
                  name="productName"
                  id="productName"
                  autocomplete="family-name"
                  :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                  v-model="product.productName"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="productName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="productPrice"
                  class="block text-sm font-medium text-gray-700"
                  >Product Price<span class="text-xs ml-4"
                    >product price after discount
                    {{
                      isNaN(
                        Math.floor(
                          product.productPrice -
                            (product.productDiscount / 100) *
                              product.productPrice
                        )
                      )
                        ? 0
                        : Math.floor(
                            product.productPrice -
                              (product.productDiscount / 100) *
                                product.productPrice
                          )
                    }}</span
                  ></label
                >
                <vee-field
                  type="text"
                  name="productPrice"
                  id="productPrice"
                  autocomplete="family-name"
                  v-model="product.productPrice"
                  :placeholder="ADD_PRODUCT_FORM_PRICE_PLACEHOLDER"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="productPrice"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="productPriceDiscount"
                  class="block text-sm font-medium text-gray-700"
                  >Product Price Discount
                </label>
                <vee-field
                  type="text"
                  name="productPriceDiscount"
                  id="productPriceDiscount"
                  autocomplete="family-name"
                  v-model="product.productDiscount"
                  :placeholder="ADD_PRODUCT_FORM_PRICE_PLACEHOLDER"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="productPriceDiscount"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="productPriceDiscount"
                  class="block text-sm font-medium text-gray-700"
                  >Quantity
                </label>
                <vee-field
                  type="text"
                  name="quantity"
                  id="quantity"
                  autocomplete="family-name"
                  v-model="product.quantity"
                  :placeholder="ADD_PRODUCT_FORM_PRICE_PLACEHOLDER"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="quantity" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="productType"
                  class="block text-sm font-medium text-gray-700"
                  >Product Type</label
                >
                <vee-field
                  as="select"
                  name="productType"
                  id="productType"
                  v-model="productType"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="Games">Games</option>
                  <option value="Consoles">Consoles</option>
                  <option value="Accessories">Accessories</option></vee-field
                >
                <ErrorMessage name="productType" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                  >Product Category</label
                >
                <select
                  id="categoryId"
                  name="categoryId"
                  v-model="product.categoryId"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="category in categories"
                    :key="category"
                    :value="category.categoryId"
                    v-show="category.categoryName.includes(productType)"
                  >
                    {{ category.categoryName }}
                  </option>
                  <ErrorMessage
                    name="categoryId"
                    class="text-red-500 text-xs"
                  />
                </select>
              </div>
              <div class="col-span-6">
                <label
                  for="brandId"
                  class="block text-sm font-medium text-gray-700"
                  >Product Brand</label
                >
                <vee-field
                  as="select"
                  id="brand"
                  name="brand"
                  v-model="product.brandId"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="brand in brands"
                    :key="brand.brandId"
                    :value="brand.brandId"
                  >
                    {{ brand.brandName }}
                  </option>
                </vee-field>
                <ErrorMessage name="brand" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="status"
                  class="block text-sm font-medium text-gray-700"
                  >Status</label
                >
                <vee-field
                  as="select"
                  id="status"
                  name="status"
                  v-model="product.status"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="stat in status"
                    :key="stat.statusId"
                    :value="stat.statusId"
                  >
                    {{ stat.statusName }}
                  </option>
                </vee-field>
                <ErrorMessage name="status" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="genre"
                  class="block text-sm font-medium text-gray-700"
                  >Genre</label
                >
                <vee-field
                  as="select"
                  id="genre"
                  name="genre"
                  v-model="product.genre"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="gen in genre"
                    :key="gen.genreId"
                    :value="gen.genreId"
                  >
                    {{ gen.genreName }}
                  </option>
                </vee-field>
                <ErrorMessage name="genre" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="condition"
                  class="block text-sm font-medium text-gray-700"
                  >Product Condition</label
                >
                <vee-field
                  as="select"
                  name="condition"
                  id="condition"
                  v-model="product.condition"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="NEW">New</option>
                  <option value="PREOWNED">Preowned</option>
                  <option value="MINT">Mint</option>
                  <option value="PREORDER">Preorder</option></vee-field
                >
                <ErrorMessage name="condition" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="note"
                  class="block text-sm font-medium text-gray-700"
                  >Note
                </label>
                <vee-field
                  type="text"
                  name="note"
                  id="note"
                  autocomplete="family-name"
                  v-model="product.note"
                  :placeholder="ADD_PRODUCT_FORM_PRICE_PLACEHOLDER"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="note" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="shortDescription"
                  class="block text-sm font-medium text-gray-700"
                  >Short Description
                  <span class="ml-2 text-xs text-yellow-500">
                    You Have Entered
                    {{ product.shortDescription.length }} Characters.</span
                  ></label
                >
                <div class="mt-1">
                  <vee-field
                    as="textarea"
                    rows="4"
                    v-model="product.shortDescription"
                    name="shortDescription"
                    id="shortDescription"
                    class="shadow-sm border py-2 px-3 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md"
                  />

                  <ErrorMessage
                    name="shortDescription"
                    class="text-red-500 text-xs"
                  />
                </div>
              </div>

              <div class="col-span-6">
                <label
                  for="comment"
                  class="block text-sm font-medium text-gray-700"
                  >Full Description<span class="ml-2 text-xs text-yellow-500">
                    You Have Entered
                    {{ product.fullDescription.length }} Characters.</span
                  ></label
                >
                <div class="mt-1">
                  <vee-field
                    as="textarea"
                    rows="4"
                    v-model="product.fullDescription"
                    name="fullDescription"
                    id="fullDescription"
                    class="shadow-sm border py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="fullDescription"
                    class="text-red-500 text-xs"
                  />
                </div>
                <div class="col-span-6">
                  <label
                    for="galleryImage1Url"
                    class="block text-sm font-medium text-gray-700"
                    >Gallery Image 1</label
                  >
                  <vee-field
                    type="text"
                    name="galleryImage1Url"
                    id="galleryImage1Url"
                    autocomplete="family-name"
                    :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                    v-model="product.galleryImage1Url"
                    class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="galleryImage1Url"
                    class="text-red-500 text-xs"
                  />
                </div>
                <div class="col-span-6">
                  <label
                    for="galleryImage1Url"
                    class="block text-sm font-medium text-gray-700"
                    >Gallery Image 2</label
                  >
                  <vee-field
                    type="text"
                    name="galleryImage2Url"
                    id="galleryImage2Url"
                    autocomplete="family-name"
                    :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                    v-model="product.galleryImage2Url"
                    class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="galleryImage2Url"
                    class="text-red-500 text-xs"
                  />
                </div>
                <div class="col-span-6">
                  <label
                    for="galleryImage1Url"
                    class="block text-sm font-medium text-gray-700"
                    >Gallery Image 3</label
                  >
                  <vee-field
                    type="text"
                    name="galleryImage3Url"
                    id="galleryImage3Url"
                    autocomplete="family-name"
                    :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                    v-model="product.galleryImage3Url"
                    class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="galleryImage3Url"
                    class="text-red-500 text-xs"
                  />
                </div>
                <div class="col-span-6">
                  <label
                    for="galleryImage4Url"
                    class="block text-sm font-medium text-gray-700"
                    >Gallery Image 4</label
                  >
                  <vee-field
                    type="text"
                    name="galleryImage4Url"
                    id="galleryImage4Url"
                    autocomplete="family-name"
                    :placeholder="ADD_PRODUCT_NAME_PLACEHOLDER"
                    v-model="product.galleryImage4Url"
                    class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="galleryImage4Url"
                    class="text-red-500 text-xs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          :disabled="isSubmitBtnDisabled"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSubmitBtnDisabled: false,
      product: {
        productCoverImageUrl: "",
        productId: "",
        productName: "",
        productPrice: 0,
        status: null,
        genre: null,
        productPriceDiscount: 0,
        fullDescription: "",
        shortDescription: "",
        mainImageUrl: "",
        allImagesUrls: [],
        categoryId: "",
        brandId: "",
        quantity: 0,
        note: "",
        galleryImage1Url: "",
        galleryImage2Url: "",
        galleryImage3Url: "",
        galleryImage4Url: "",
        createdAt: "",
        createdBy: "",
        updatedBy:
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null,
        updatedAt: new Date(),
        details: {
          dimensions: "",
          Manufacturer: "",
          Language: "",
          releasedate: "",
        },
      },
    };
  },
};
</script>

<style></style>
