<template>
  <div class="mt-1 text-white bg-indigo-500 2xl:max-w-7xl 2xl:mx-auto">
    <div class="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8">
      <ul
        class="list-style-none mr-auto flex flex-row pl-0 xs:justify-evenly xs:items-center xs:mt-64 text-sm"
      >
        <!-- Dashboard link -->
        <li
          class="my-0 pr-2 relative"
          v-for="item in sidebarCategories"
          :key="item.categoryId"
          :submenu="item.submenu"
          :label="item.categoryName"
          :menu="item"
        >
          <a
            class="text-white font-medium font-poppins flex items-center text-sm capitalize disabled:text-black/30 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
            href="javascript:void(0)"
            data-te-nav-link-ref
            ><img
              :src="GamingPNG"
              v-if="item.icon === 'Games'"
              class="h-6 w-6 mr-2"
              alt=""
            />
            <img
              :src="ConsolesPNG"
              v-if="item.icon === 'Consoles'"
              class="h-5 w-5 mr-2"
              alt=""
            />
            <img
              :src="AccessoriesPNG"
              v-if="item.icon === 'Accessories'"
              class="h-5 w-5 mr-2"
              alt=""
            />{{ item.categoryName }}</a
          >
          <SidebarItem :items="item.subCategories" />
        </li>
        <!-- Team link -->
      </ul>
    </div>
  </div>
</template>

<script>
import GamingPNG from "@/assets/icons/gaming.png";
import ConsolesPNG from "@/assets/icons/console-white.png";
import BrandPNG from "@/assets/icons/brands.png";
import CategoryPNG from "@/assets/icons/categories.png";
import GamesPNG from "@/assets/icons/games.png";
import AccessoriesPNG from "@/assets/icons/accessories-white.png";
import StatusPNG from "@/assets/icons/status.png";
import useCartegoryStore from "@/store/categories.store";
import useAuthStore from "@/store/auth.store";
import { mapState } from "pinia";
import SidebarItem from "@/components/Sidebar/Sidebar.item.vue";
export default {
  computed: {
    ...mapState(useCartegoryStore, ["categories", "sidebarCategories"]),
  },
  methods: {
    ...mapState(useCartegoryStore, [
      "getCategoriesGetter",
      "sidebarMenuGetter",
    ]),
  },
  data() {
    return {
      BrandPNG,
      CategoryPNG,
      GamesPNG,
      StatusPNG,
      ConsolesPNG,
      GamingPNG,
      AccessoriesPNG,
    };
  },
  components: {
    SidebarItem,
  },
};
</script>

<style></style>
