<template>
  <appOverlay
    :isOverlayActive="toggleOptions"
    @overlayClicked="handleOverlayClick"
  />
  <div
    class="relative inline-block text-left"
    :class="{ 'z-50': toggleOptions }"
  >
    <div>
      <button
        type="button"
        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-slate-500"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        @click.prevent="toggleOptions = !toggleOptions"
      >
        {{ label }}
        <!-- Heroicon name: solid/chevron-down -->
        <svg
          class="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
    <div
      class="origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      v-if="toggleOptions && options.length > 0"
    >
      <div class="py-1" role="none">
        <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
        <a
          v-for="(option, idx) in options"
          :key="option.value"
          href="javascript:void(0)"
          @click.prevent="handleSelectedOptions($event, option, idx)"
          class="text-gray-700 block px-4 py-2 text-sm"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          >{{ option.label }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleOptions: false,
      optionsIndex: -1,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
      default: "Select",
    },
    left: {
      type: Number,
      required: false,
      default: 0,
    },
    right: {
      type: Number,
      required: false,
      default: 0,
    },
    options: {
      type: Array,
      required: true,
      default: [],
    },
  },
  methods: {
    handleOverlayClick() {
      this.$emit("handleSelectDropdownOverlayClick", true);
      this.toggleOptions = false;
    },
    handleSelectedOptions($event, option, idx) {
      this.toggleOptions = false;
      this.$emit("handleSelectedOptionValue", option, idx);
    },
  },
};
</script>

<style></style>
