<template>
  <div class="">
    <div class="">
      <ul
        role="list"
        class="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1"
      >
        <UserAddressCard
          v-for="(address, idx) in userdetails.addresses"
          :address="{ position: idx, ...address }"
          :key="idx"
          :id="'address' + idx"
        />

        <!-- More people... -->
      </ul>
    </div>
  </div>
</template>

<script>
import UserAddressCard from "@/components/Users/User.address.card.new.vue";
import useAuthStore from "@/store/auth.store";
import { mapState } from "pinia";
export default {
  name: "User Profile Address",
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
  },
  props: {
    profileDetails: {
      default: {
        addresses: [],
      },
    },
  },
  components: {
    UserAddressCard,
  },
};
</script>

<style></style>
