<template>
  <div class="max-w-7xl mx-auto">
    <div class="font-roboto">
      <!-- <div>
        <img
          class="h-32 w-full object-cover lg:h-48"
          src="https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          alt=""
        />
      </div> -->
      <div class="p-6">
        <div class="sm:flex sm:items-end sm:space-x-5">
          <div class="flex">
            <img
              v-if="this.userdetails && this.userdetails.profileImageUrl"
              class="h-24 w-24 object-cover sm:h-32 sm:w-32"
              :src="this.userdetails.profileImageUrl"
              alt=""
            />
            <img
              v-else
              :src="defaultProfileImage"
              alt="default user profile "
              class="h-24 w-24 object-cover sm:h-32 sm:w-32"
            />
          </div>
          <div
            class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1"
          >
            <div class="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 class="text-2xl font-bold text-gray-900 truncate">
                {{ profileDetails.username }}
              </h1>
            </div>
            <div
              class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4"
            >
              <button
                tag="button"
                @click.prevent="
                  () => {
                    $router.push({
                      name: 'registration-details',
                      params: {
                        profileId: userdetails.userId ? userdetails.userId : '',
                      },
                    });
                  }
                "
                class="inline-flex justify-center px-4 py-2 border border-indigo-600 shadow-sm text-sm font-bold uppercase rounded-md text-indigo-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              >
                <!-- Heroicon name: solid/mail -->

                <svg
                  class="-ml-1 mr-2 h-5 w-5 text-indigo-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>Update Profile Details</span>
              </button>
              <button
                type="button"
                @click.prevent="
                  () => {
                    $router.push({
                      name: 'UsER_ADDRESS_FORM',
                    });
                  }
                "
                class="inline-flex justify-center px-4 py-2 border border-indigo-600 uppercase font-bold shadow-sm text-sm rounded-md text-indigo-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <!-- Heroicon name: solid/phone -->

                <svg
                  class="-ml-1 mr-2 h-5 w-5 text-indigo-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                  />
                </svg>
                <span>Add New Delivery Address</span>
              </button>
            </div>
          </div>
        </div>
        <div class="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
          <h1 class="text-2xl font-bold text-gray-900 truncate">
            {{ profileDetails.username }}
          </h1>
        </div>
      </div>
    </div>

    <div class="font-roboto max-w-7xl mx-auto mt-6 px-6 lg:px-0">
      <div class="sm:block">
        <div class="">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200" -->
            <a
              href="javascript:void(0)"
              @click="tab = 'profile'"
              :class="[
                {
                  'border-black text-indigo-600  font-semibold':
                    tab === 'profile',
                },
                { 'border-transparent': tab !== 'profile' },
              ]"
              class="text-gray-500 uppercase hover:text-gray-700 hover:border-gray-200 whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
            >
              Profile
            </a>

            <a
              href="javascript:void(0)"
              @click="tab = 'address'"
              :class="[
                {
                  'border-black text-indigo-600 uppercase font-semibold':
                    tab === 'address',
                },
                { 'border-transparent': tab !== 'address' },
              ]"
              class="text-gray-500 uppercase hover:text-gray-700 hover:border-gray-200 whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
            >
              Address
            </a>
          </nav>
          <div class="py-6">
            <UserProfileInfo
              v-if="tab == 'profile'"
              :profileDetails="profileDetails"
            />
            <UserAddressDetails
              v-if="tab == 'address'"
              :profileDetails="profileDetails"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultProfileImage from "@/assets/img/defaultprofile.png";
import UserProfileInfo from "./User.profile.info.vue";
import useAuthStore from "@/store/auth.store";
import { mapActions, mapState } from "pinia";
import UserAddressDetails from "./User.profile.address.vue";
import UserProfileSettings from "./User.settitngs.form.vue";
export default {
  data() {
    return {
      tab: "profile",
      profileDetails: null,
      defaultProfileImage,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
  },
  beforeRouteEnter(to, from, next) {
    const store = useAuthStore();
    if (store.isUserLoggedIn && store.userdetails) {
      next();
    } else {
      next("/");
    }
  },
  methods: {},
  created() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
    let details = this.userdetails;
    this.profileDetails = details;
  },
  components: {
    UserProfileInfo,
    UserAddressDetails,
    UserProfileSettings,
  },
};
</script>

<style></style>
