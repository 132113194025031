import { productCollection } from "@/firebase/firebase.config";

export const saveNewProductApi = async (product) => {
  let isProductNameTaken = await productCollection
    .where("productName", "==", product.productName)
    .get();
  if (isProductNameTaken.empty) {
    let searchTags = [];
    if (product.tags) {
      product.tags = product.tags + ",";
      let tags = [];
      product.tags.split(",").forEach((tag) => {
        if (tag.trim() !== "") {
          tags.push(tag.toLowerCase().trim());
        }
      });
      product.tags = tags;
    }
    if (product.quantity === "0") {
      product.condition = "OUT OF STOCK";
    }
    return (await productCollection.add(product)).get();
  } else {
    throw new Error(`product name ${product.productName} is already taken`);
  }
};

export const fetchAllProductsApi = async () => {
  return await productCollection.get();
};

export const getProductDetailsByProductIdAPI = async (productId) => {
  return await productCollection.doc(productId).get();
};

export const updateProductDetailsByProductIdAPI = async (productdetails) => {
  let isProductNameTaken = await productCollection
    .where("productName", "==", productdetails.productName)
    .get();
  let isProductNameTakenByOtherProductName = false;

  isProductNameTaken.forEach((product) => {
    if (
      product.productName === productdetails.productName &&
      product.productId !== productdetails.productId
    ) {
      isProductNameTakenByOtherProductName = true;
    }
  });

  if (isProductNameTakenByOtherProductName) {
    throw new Error(
      `Product name ${productdetails.productName} is alredy taken by other product`
    );
  } else {
    let product = await productCollection.doc(productdetails.productId).get();
    if (product.exists) {
      if (productdetails.tags) {
        if (Array.isArray(productdetails.tags)) {
          if (productdetails.tags.length) {
            productdetails.tags = productdetails.tags.join(",");
          }
        }

        if (productdetails.quantity === "0") {
          productdetails.condition = "OUT OF STOCK";
        }

        let tags = [];
        productdetails.tags.split(",").forEach((tag) => {
          tags.push(tag.toLowerCase().trim());
        });
        productdetails.tags = tags;
      }
      await productCollection.doc(productdetails.productId).set(productdetails);
    } else {
      throw new Error(
        "Product Doesnt by product id ",
        productdetails.productId
      );
    }
  }
};

export const getPreorderGames = async (limit = 5) => {
  return await productCollection
    .where("condition", "==", "PREORDER")
    .limit(limit)
    .get();
};

export const getGamesByCategoryId = async (categoryId, limit = 5) => {
  return await productCollection
    .where("categoryId", "==", categoryId)
    .limit(limit)
    .get();
};

export const getConsolesApi = async (limit = 5) => {
  return await productCollection
    .where("type", "==", "Consoles")
    .limit(limit)
    .get();
};

export const sortProductsByProductProperyAPI = async (
  propertyName,
  order = "asc"
) => {
  return await productCollection.orderBy(propertyName, order).get();
};

export const getSimilarProductsAPI = async (currentId, genreId, categoryId) => {
  let query = productCollection;
  query = query.where("categoryId", "==", categoryId);
  query = query.where("genre", "==", genreId);
  query = query.limit(5);
  return await query.get();
};

export const deleteProductByProductIdAPI = async (productId) => {
  return await productCollection.doc(productId).delete();
};

export const fetchPaginatedProductsAPI = async (
  limit = 10,
  type,
  lastDoc,
  sortBy = "createdAt",
  sortOrder = "asc",
  condition,
  categoryId,
  brandId,
  genre,
  globalSearchText
) => {
  let query = productCollection;

  if (sortBy && sortOrder) {
    query = query.orderBy(sortBy, sortOrder);
  }
  if (lastDoc) {
    query = query.startAfter(lastDoc);
  }
  if (type) {
    query = query.where("type", "==", type);
  }
  if (condition) {
    query = query.where("condition", "==", condition);
  }
  if (categoryId) {
    query = query.where("categoryId", "==", categoryId);
  }
  if (brandId) {
    query = query.where("brandId", "==", brandId);
  }
  if (genre) {
    query = query.where("genre", "==", genre);
  }
  if (globalSearchText) {
    globalSearchText = globalSearchText.toLowerCase();
    query = query.where("tags", "array-contains-any", [globalSearchText]);
  }
  if (limit) {
    query = query.limit(limit);
  }

  return await query.get();
};

export const searchProductAPI = (
  categoryId,
  brandId,
  type,
  genre,
  condition,
  discount,
  searchText,
  limit = 2,
  sortBy,
  sortOrder,
  lastProductId
) => {
  let query = productCollection;
  if (categoryId) {
    query = query.where("categoryId", "==", categoryId);
  }

  if (condition) {
    query = query.where("condition", "==", condition);
  }

  if (discount) {
    query = query.where("productPriceDiscount", "<=", discount);
  }

  if (brandId) {
    query = query.where("brandId", "==", brandId);
  }

  if (type) {
    query = query.where("type", "==", type);
  }
  if (genre) {
    query = query.where("genre", "==", genre);
  }
  if (searchText) {
    searchText = searchText.toLowerCase();
    query = query.where("tags", "array-contains-any", [searchText]);
  }
  if (limit) {
    query = query.limit(limit);
  }

  if (sortBy && sortOrder) {
    query = query.orderBy(sortBy, sortOrder);
  }

  if (lastProductId) {
    query = query.startAt(lastProductId);
    query = query.orderBy("createdAt");
  }

  return query.get();
};

export const getLatestProductDocAPI = async (docId) => {
  return await productCollection.doc(docId).get();
};

export const globalProductSearch = async (searchText) => {
  if (searchText) {
    searchText = searchText.toLowerCase();
    return await productCollection.get();
  } else {
    return [];
  }
};

export const searchProductByProductTypeAPI = async (type, limit = 5) => {
  return await productCollection.where("type", "==", type).limit(limit).get();
};

export const searchProductsByMultipleFiltersAPI = async (filters = []) => {
  let categoryIds = [];
  let brandIds = [];
  let genreIds = [];

  let query = productCollection;
  filters.forEach((filter) => {
    if (filter.typeId === "categoryId") {
      categoryIds.push(filter.id);
    } else if (filter.typeId === "brandId") {
      brandIds.push(filter.id);
    } else if (filter.typeId === "genreId") {
      genreIds.push(filter.id);
    }
  });

  if (categoryIds.length) {
    query = query.where("categoryId", "in", categoryIds);
  }

  if (brandIds.length) {
    query = query.where("brandId", "in", brandIds);
  }

  if (genreIds.length) {
    query = query.where("genre", "in", genreIds);
  }

  return await query.get();
};

export const getProductByQuantity = async (quantity = 0) => {
  return await productCollection
    .where("quantity", "==", quantity.toString())
    .get();
};
