export const CREATE_USER = "WRITE_USER";
export const READ_USER = "READ_USER";
export const CREATE_BRAND = "WRITE_BRAND";
export const READ_BRAND = "READ_BRAND";
export const READ_ORDERS = "READ_ORDERS";
export const CREATE_CATEGORY = "WRITE_CATEGORY";
export const CREATE_ORDERS = "WRITE_ORDERS";
export const CREATE_PRODUCT = "WRITE_PRODUCT";
export const CREATE_STATUS = "WRITE_STATUS";
export const CREATE_GENRE = "WRITE_GENRE";
export const CREATE_COMPANY_DETAILS = "WRITE_COMPANY_DETAILS";
export const CREATE_INVOICE = "WRITE_INVOICE";
export const CREATE_ONLY_INVOICE = "CREATE_INVOICE";
export const CREATE_ONLY_BRAND = "CREATE_BRAND";
export const CREATE_ONLY_CATEGORY = "CREATE_CATEGORY";
export const CREATE_ONLY_ORDERS = "CREATE_ORDERS";
export const CREATE_ONLY_PRODUCT = "CREATE_PRODUCT";
export const CREATE_ONLY_GENRE = "CREATE_GENRE";
