import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import useAuthStore from "@/store/auth.store";
import Homepage from "../views/Layouts/Home.layout.vue";
import Checkout from "../views/Pages/Checkout.page.vue";
import UserRegisterationForm from "@/components/Users/User.registration.vue";
import UserRegisterationDetailsForm from "@/components/Users/User.registration.details.vue";
import UserLoginForm from "@/components/Users/User.login.vue";
import BrandAddForm from "@/components/Brands/Brand.add.form.vue";
import BrandsList from "@/components/Brands/Brand.lists.vue";
import BrandEditForm from "@/components/Brands/Brand.edit.form.vue";
import ErrorPage from "@/components/Commons/Errors/PageNotFound.vue";
import CategoryAddForm from "@/components/Categories/Category.add.form.vue";
import CategoryList from "@/components/Categories/Category.list.vue";
import CategoryEditForm from "@/components/Categories/Category.edit.form.vue";
import AddProductForm from "@/components/Products/Product.add.form.vue";
import AddStatusForm from "@/components/Status/Status.add.form.vue";
import StatusList from "@/components/Status/Status.list.vue";
import EditStatusForm from "@/components/Status/Status.edit.form.vue";
import AddGenreForm from "@/components/Genre/Genre.add.form.vue";
import GenreList from "@/components/Genre/Genre.list.vue";
import EditGenreForm from "@/components/Genre/Genre.edit.form.vue";
import UserProfileComponent from "@/components/Users/User.profile.vue";
import UserAddressForm from "@/components/Users/User.address.form.vue";
import ProductList from "@/components/Products/Products.list.vue";
import ProductEditForm from "@/components/Products/Product.edit.form.vue";
import ProductDetails from "@/components/Products/Product.details.vue";
import LandingPage from "@/components/Homepage/Landingpage.vue";
import ProductsSearchResults from "@/views/Layouts/Product.search.results.vue";
import ProductDetailsForm from "@/components/Products/Product.details.form.vue";
import EditUserAddressForm from "@/components/Users/User.address.edit.form.vue";
import { hasUserPermissions } from "@/utils/auth.util";
import AddCompanyDetailsForm from "@/components/Company/Company.details.add.form.vue";
import FaqHelpComponent from "@/components/Commons/Faq/Faq.vue";
import CartLayout from "@/views/Layouts/Cart.layout.vue";
import OrderLayout from "@/components/Orders/Orders.layout.vue";
import InvoiceLayout from "@/components/Invoices/Invoice.layout.vue";
import OrderEditForm from "@/components/Orders/Order.edit.form.vue";
import UserEditAddressForm from "@/components/Users/User.address.edit.form.vue";
import OrderDetailsComponent from "@/components/Orders/Order.details.vue";
import CustomInvoiceGenerateLayout from "@/components/Invoices/Invoice.custom.layout.vue";
import EditInvoiceForm from "@/components/Invoices/Invoice.edit.vue";
import InvoiceList from "@/components/Invoices/Invoice.list.vue";
import InvoiceDetails from "@/components/Invoices/Invoice.details.vue";
import UserListLayout from "@/components/Users/Users.list.vue";
import InvoiceCustomTemplate from "@/components/Invoices/Invoice.template.vue";

import {
  CREATE_PRODUCT,
  CREATE_BRAND,
  CREATE_ORDERS,
  CREATE_USER,
  CREATE_ONLY_BRAND,
  CREATE_ONLY_CATEGORY,
  CREATE_ONLY_ORDERS,
  CREATE_ONLY_INVOICE,
  CREATE_ONLY_PRODUCT,
  CREATE_COMPANY_DETAILS,
  CREATE_ONLY_GENRE,
  CREATE_GENRE,
  CREATE_STATUS,
  CREATE_CATEGORY,
  CREATE_INVOICE,
} from "@/assets/Permissions";

const routes = [
  {
    path: "/",
    name: "home",
    component: Homepage,
    children: [
      {
        name: "ADD_COMPANY_DETAILS_FORM",
        component: AddCompanyDetailsForm,
        path: "/company/addDetails",
        beforeEnter(to, from, next) {
          if (hasUserPermissions(CREATE_COMPANY_DETAILS)) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "HELP",
        component: FaqHelpComponent,
        path: "help",
        meta: {
          title: "Help | ReyGamesCastle",
        },
      },
      {
        name: "welcome",
        component: LandingPage,
        path: "",
        meta: {
          title: "ReyGamesCastle | Homepage",
        },
      },
      {
        name: "SEARCH_RESULTS",
        component: ProductsSearchResults,
        path: "search",
        meta: {
          title: "Search.... | ReyGamesCastle",
        },
      },
      {
        name: "checkout",
        path: "/checkout",
        component: Checkout,
        meta: {
          requiredAuth: true,
          title: "Checkout",
        },
      },
      {
        path: "notifications",
        name: "notifications",
        component: UserRegisterationForm,
        meta: {
          title: "Notifications | ReyGamesCastle",
        },
      },
      {
        path: "register",
        name: "registration",
        component: UserRegisterationForm,
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (
            !store.isUserLoggedIn &&
            store.userdetails &&
            !store.userdetails.userId &&
            !store.isLogginOrSignupInProgress
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Registration | ReyGamesCastle",
        },
      },
      {
        path: "/profile/address",
        component: UserAddressForm,
        name: "UsER_ADDRESS_FORM",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "My Profile | ReyGamesCastle",
        },
      },
      {
        path: "/profile/address/edit",
        component: UserEditAddressForm,
        name: "USER_EDIT_ADDRESS_FORM",
        meta: {
          title: "Edit My Profile | ReyGamesCastle",
        },
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        path: "/profile/edit/address",
        component: EditUserAddressForm,
        name: "USER_EDIT_ADDRESS_FORM",
        meta: {
          title: "Edit Address | ReyGamesCastle",
        },
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        path: "login",
        name: "login",
        component: UserLoginForm,
        meta: {
          title: "Login | ReyGamesCastle",
        },
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (
            !store.isUserLoggedIn &&
            store.userdetails &&
            !store.userdetails.userId &&
            !store.isLogginOrSignupInProgress
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        path: "profile/details",
        name: "registration-details",
        component: UserRegisterationDetailsForm,
        meta: {
          isAuthRequired: true,
          title: "My Profile Details | ReyGamesCastle",
        },
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (!store.isUserLoggedIn && store.isLogginOrSignupInProgress) {
            next("/");
          } else {
            next();
          }
        },
      },
      {
        name: "Add-Brand",
        path: "brands/add",
        component: BrandAddForm,
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_BRAND) ||
            hasUserPermissions(CREATE_ONLY_BRAND)
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Add Brand | Reygamescastle",
        },
      },
      {
        name: "Saved-Brands",
        path: "brands",
        component: BrandsList,
        meta: {
          title: "Brands | Reygamescastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_BRAND) ||
            hasUserPermissions(CREATE_ONLY_BRAND)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "edit-brand",
        path: "brands/edit/:brandId",
        component: BrandEditForm,
        meta: {
          title: "Edit Brand | Reygamescastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_BRAND) ||
            hasUserPermissions(CREATE_ONLY_BRAND)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "ADD_CATEGORY_FORM",
        path: "categories/add",
        component: CategoryAddForm,
        meta: {
          title: "Add Category | Reygamescastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_CATEGORY) ||
            hasUserPermissions(CREATE_ONLY_CATEGORY)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "CATEGORIES_LIST",
        path: "categories",
        component: CategoryList,
        meta: {
          title: "Categories | Reygamescastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_CATEGORY) ||
            hasUserPermissions(CREATE_ONLY_CATEGORY)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "GET_CATEGORY_BY_ID",
        path: "categories/edit/:categoryId",
        component: CategoryEditForm,
        meta: {
          title: "Category | Reygamescastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_CATEGORY) ||
            hasUserPermissions(CREATE_ONLY_CATEGORY)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "PRODUCTS_LIST",
        path: "products",
        component: ProductList,
        meta: {
          title: "Products | Reygamescastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_PRODUCT) ||
            hasUserPermissions(CREATE_ONLY_PRODUCT)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "ADD_PRODUCT_FORM",
        path: "products/add",
        component: AddProductForm,
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_PRODUCT) ||
            hasUserPermissions(CREATE_ONLY_PRODUCT)
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Add Product | Reygamescastle",
        },
      },
      {
        name: "EDIT_PRODUCT_FORM",
        path: "products/edit/:productId",
        component: ProductEditForm,
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_PRODUCT) ||
            hasUserPermissions(CREATE_ONLY_PRODUCT)
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Edit Product | Reygamescastle",
        },
      },
      {
        name: "PRODUCT_DETAILS",
        path: "products/:productId",
        component: ProductDetails,
      },
      {
        name: "PRODUCT_DETAILS_FORM",
        path: "products/details/add",
        component: ProductDetailsForm,
        meta: {
          title: "Add Product Details | ReyGamesCastle",
        },
      },
      {
        name: "ADD_STATUS_FORM",
        component: AddStatusForm,
        path: "status/add",
        beforeEnter(to, from, next) {
          if (hasUserPermissions(CREATE_STATUS)) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "EDIT_STATUS_FORM",
        component: EditStatusForm,
        path: "status/:statusId",
        beforeEnter(to, from, next) {
          if (hasUserPermissions(CREATE_STATUS)) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "STATUS_LIST",
        component: StatusList,
        path: "status",
        beforeEnter(to, from, next) {
          if (hasUserPermissions(CREATE_STATUS)) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "ADD_GENRE_FORM",
        component: AddGenreForm,
        path: "genre/add",
        meta: {
          title: "Add Genre | ReyGamesCastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_GENRE) ||
            hasUserPermissions(CREATE_ONLY_GENRE)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "GENRE_LIST",
        component: GenreList,
        path: "genre",
        meta: {
          title: "Genres | ReyGamesCastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_GENRE) ||
            hasUserPermissions(CREATE_ONLY_GENRE)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        name: "EDIT_GENRE_FORM",
        component: EditGenreForm,
        path: "genre/:genreId",
        meta: {
          title: "Edit Genre | ReyGamesCastle",
        },
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_GENRE) ||
            hasUserPermissions(CREATE_ONLY_GENRE)
          ) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        component: UserProfileComponent,
        name: "USER_PROFILE",
        path: "profile/:profileId",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (!store.isUserLoggedIn && store.isLogginOrSignupInProgress) {
            next("/");
          } else {
            next();
          }
        },
        meta: {
          title: "My Profile | ReyGamesCastle",
        },
      },
      {
        component: CartLayout,
        name: "CART",
        path: "cart",
      },
      {
        component: OrderLayout,
        path: "myorders",
        name: "MY_ORDERS",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "My Orders | ReyGamesCastle",
        },
      },
      {
        component: OrderEditForm,
        path: "myorders/:orderId",
        name: "EDIT_ORDER",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Order | ReyGamesCastle",
        },
      },
      {
        component: OrderDetailsComponent,
        path: "myorders/details",
        name: "ORDER_DETAILS",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Order Details | ReyGamesCastle",
        },
      },
      {
        component: InvoiceDetails,
        path: "invoices/:orderId",
        name: "INVOICES",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Invoice Details | ReyGamesCastle",
        },
      },
      {
        component: InvoiceCustomTemplate,
        path: "orders/invoices/:orderId",
        name: "ORDER_INVOICE_ID",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Invoice Details | ReyGamesCastle",
        },
      },
      {
        component: CustomInvoiceGenerateLayout,
        path: "invoices/generate",
        name: "GENERATE_INVOICES",
        beforeEnter(to, from, next) {
          if (
            hasUserPermissions(CREATE_INVOICE) ||
            hasUserPermissions(CREATE_ONLY_INVOICE)
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Generate Invoice | ReyGamesCastle",
        },
      },
      {
        component: InvoiceDetails,
        path: "invoices/:invoiceId/details",
        name: "INVOICE_DETAILS",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (
            store.isUserLoggedIn &&
            !store.isLogginOrSignupInProgress &&
            (hasUserPermissions(CREATE_INVOICE) ||
              hasUserPermissions(CREATE_ONLY_INVOICE))
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Invoice Details | ReyGamesCastle",
        },
      },
      {
        component: InvoiceList,
        path: "invoices",
        name: "INVOICE_LIST",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Invoices | ReyGamesCastle",
        },
      },
      {
        component: InvoiceLayout,
        path: "orders/invoice",
        name: "ORDER_INVOICE",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Order Invoice | ReyGamesCastle",
        },
      },
      {
        component: InvoiceLayout,
        path: "invoices",
        name: "INVOICE_BY_ID",
        beforeEnter(to, from, next) {
          let store = useAuthStore();
          if (store.isUserLoggedIn && !store.isLogginOrSignupInProgress) {
            next();
          } else {
            next("/");
          }
        },
        meta: {
          title: "Invoices | ReyGamesCastle",
        },
      },
      {
        component: UserListLayout,
        path: "users",
        name: "USERS_LIST",
      },
      {
        path: "/:catchAll(.*)*",
        component: ErrorPage,
        meta: {
          title: "Page Not Found | ReyGamesCastle",
        },
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   let authStore = useAuthStore();
//   if (to.meta.isAuthRequired && authStore.userdetails.userId !== null) {
//     next();
//   } else {
//     next("/login");
//   }
// });

export default router;
