<template>
  <div class="space-y-6 bg-white px-4 py-5">
    <vee-form :validation-schema="schema" @submit="handleFormSubmission">
      <div class="sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="brandName"
                  class="block text-sm font-medium text-gray-700"
                  >Brand Name</label
                >
                <vee-field
                  type="text"
                  name="brandName"
                  id="brandName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="brandName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="brandLogoURL"
                  class="block text-sm font-medium text-gray-700"
                  >Brand Logo URL</label
                >
                <vee-field
                  type="text"
                  name="brandLogoURL"
                  id="brandLogoURL"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="brandLogoURL"
                  class="text-red-500 text-xs"
                />
              </div>

              <!-- <div class="col-span-6 sm:col-span-3">
              <label
                for="country"
                class="block text-sm font-medium text-gray-700"
                >Status</label
              >
              <select
                id="country"
                name="country"
                autocomplete="country-name"
                v-model="brand.brandStatus"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
              </select>
            </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          :disabled="requestInProgress"
          :class="{ 'bg-red-500': requestInProgress }"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create Brand
        </button>
        <button
          type="button"
          @click.prevent="handleCancelSaveBrand"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import useToastStore from "@/store/toast.store";
import useAuthStore from "@/store/auth.store";
import useBrandStore from "@/store/brands.store";
import { mapState, mapActions } from "pinia";
import { hasUserPermissions } from "@/utils/auth.util";
import { CREATE_BRAND, READ_BRAND } from "@/assets/Permissions";
export default {
  computed: {
    ...mapState(useAuthStore, ["userdetails"]),
  },
  data() {
    return {
      CREATE_BRAND,
      READ_BRAND,
      isUserDataValid: false,
      brand: {
        brandName: "",
        brandLogoURL: "",
      },
      requestInProgress: false,
      schema: {
        brandName: "required|max:50|min:2",
        brandLogoURL: "required|url|min:1",
      },
    };
  },
  methods: {
    hasPermission(permission) {
      return hasUserPermissions(permission);
    },
    ...mapActions(useToastStore, ["success", "error"]),
    ...mapActions(useBrandStore, [
      "saveNewBrandDetailsAction",
      "fetchAllBrands",
    ]),

    handleCancelSaveBrand() {
      if (!this.requestInProgress) {
        this.$router.push("/brands");
      }
    },
    async handleFormSubmission(values) {
      this.brand.brandName = values.brandName;
      this.brand.brandLogoURL = values.brandLogoURL;
      try {
        if (!this.requestInProgress) {
          this.requestInProgress = true;
          this.brand = {
            ...this.brand,
            createdAt: new Date(),
            createdBy: this.userdetails.userId,
            updatedBy: null,
            updatedAt: null,
          };
          let response = await this.saveNewBrandDetailsAction(this.brand);

          this.success(
            `Successfully Added New Brand Details ${this.brand.brandName}`
          );
          this.$router.push("/brands");
        }

        this.requestInProgress = false;
      } catch (error) {
        this.error(error.message);
        this.requestInProgress = false;
      }
    },
  },
};
</script>

<style></style>
