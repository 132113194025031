<template>
  <div class="space-y-6">
    <vee-form :validation-schema="addressSchema" @submit="handleFormSubmission">
      <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6 border shadow-md">
        <h2 class="text-center text-xl font-archivo font-bold my-8">
          Add New Address
        </h2>
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="firstName"
                  class="block text-sm font-medium text-gray-700"
                  >First Name</label
                >
                <vee-field
                  type="text"
                  name="firstName"
                  id="firstName"
                  v-model="address.firstName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="firstName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="lastName"
                  class="block text-sm font-medium text-gray-700"
                  >Last Name</label
                >
                <vee-field
                  type="text"
                  name="lastName"
                  id="lastName"
                  v-model="address.lastName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="lastName" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="addressLine1"
                  class="block text-sm font-medium text-gray-700"
                  >Address Line 1</label
                >
                <vee-field
                  type="text"
                  name="addressLine1"
                  id="addressLine1"
                  v-model="address.addressLine1"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="addressLine1"
                  class="text-red-500 text-xs"
                />
              </div>

              <div class="col-span-6">
                <label
                  for="addressLine2"
                  class="block text-sm font-medium text-gray-700"
                  >Address Line 2</label
                >
                <vee-field
                  type="text"
                  name="addressLine2"
                  id="addressLine2"
                  v-model="address.addressLine2"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="addressLine2"
                  class="text-red-500 text-xs"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                  >Country</label
                >
                <vee-field
                  id="country"
                  name="country"
                  autocomplete="country-name"
                  v-model="address.country"
                  :isDisabled="true"
                  readonly="true"
                  as="select"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <!-- <option
                    v-for="country in countries"
                    :key="country.isoCode"
                    :value="country.isoCode"
                  >
                    {{ country.name }}
                  </option> -->
                  <option value="IN">India</option>
                </vee-field>
                <ErrorMessage name="country" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                  >State</label
                >
                <vee-field
                  id="state"
                  name="state"
                  autocomplete="country-name"
                  as="select"
                  v-model="address.state"
                  @change="getAllCitiesOfState"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option
                    v-for="state in states"
                    :key="state.isoCode"
                    :value="state.isoCode"
                  >
                    {{ state.name }}
                  </option>
                  <ErrorMessage name="state" class="text-red-500 text-xs" />
                </vee-field>
              </div>
              <div class="col-span-6">
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700"
                  >City</label
                >
                <vee-field
                  type="text"
                  name="city"
                  id="city"
                  v-model="address.city"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="city"
                  class="text-red-500 text-xs capitalize"
                />
              </div>

              <div class="col-span-6">
                <label
                  for="pinCode"
                  class="block text-sm font-medium text-gray-700"
                  >Pincode / ZipCode</label
                >
                <vee-field
                  type="text"
                  name="pincode"
                  id="pincode"
                  v-model="address.pincode"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="pincode" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="type"
                  class="block text-sm font-medium text-gray-700"
                  >Type</label
                >
                <vee-field
                  name="type"
                  id="type"
                  as="select"
                  v-model="address.type"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="HOME">Home</option>
                  <option value="WORK">Work</option>
                  <option value="OTHER">Other</option>
                </vee-field>
                <ErrorMessage name="type" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="note"
                  class="block text-sm font-medium text-gray-700"
                  >Note</label
                >
                <vee-field
                  type="text"
                  name="note"
                  id="note"
                  v-model="address.note"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="note" class="text-red-500 text-xs" />
              </div>
              <div class="col-span-6">
                <label
                  for="note"
                  class="block text-sm font-medium text-gray-700"
                  >Contact / Mobile Number</label
                >
                <vee-field
                  type="text"
                  name="contact"
                  id="contact"
                  v-model="address.contact"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage name="contact" class="text-red-500 text-xs" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-12">
          <button
            type="submit"
            :disabled="requestInProgress"
            :class="{
              'bg-red-800': requestInProgress,
              'cursor-default': !requestInProgress,
              'cursor-not-allowed': requestInProgress,
            }"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
          <button
            type="submit"
            @click.prevent="handleCancelSaveBrand"
            class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        </div>
      </div>
    </vee-form>
  </div>
</template>

<script>
import useToastStore from "@/store/toast.store";
import { State, City, Country } from "country-state-city";
import useAuthStore from "@/store/auth.store";
import { mapActions, mapState } from "pinia";
export default {
  computed: {
    ...mapState(useAuthStore, ["countries", "userdetails"]),
  },
  data() {
    return {
      states: [],
      cities: [],
      requestInProgress: false,
      addressSchema: {
        firstName: "required|min:3|max:50",
        lastName: "required|min:3|max:50",
        addressLine1: "required|min:5|max:150",
        addressLine2: "required|min:5|max:150",
        city: "required|min:5|max:50",
        state: "required",
        country: "required",
        pincode: "required|numeric|min:6|max:6",
        type: "required",
        contact: "required|numeric|min:10|max:10",
      },
      fullCountryName: "",
      fullStateName: "",
      address: {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        country: "IN",
        pincode: "",
        note: "",
        type: "",
        contact: "",
      },
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["updateUserAddressDetails"]),
    ...mapActions(useToastStore, ["success", "error"]),
    async handleFormSubmission(values) {
      try {
        this.requestInProgress = true;
        //  this.address.state = Country.getStateByCode(this.address.state);
        this.address.country = Country.getCountryByCode(this.address.country);
        await this.updateUserAddressDetails(
          this.address,
          this.userdetails.userId
        );

        (this.address = {
          firstName: "",
          lastName: "",
          addressLine1: "",
          addressLine2: "",
          state: "",
          city: "",
          country: "IN",
          pincode: "",
          note: "",
          type: "",
        }),
          (this.address.state = State.getStateByCode(this.address.state));
        this.success(`Successfully saved new address details`);
        this.requestInProgress = false;
        this.$router.push({
          name: "USER_PROFILE",
          query: { tab: "address" },
          params: { profileId: this.userdetails.userId },
        });
      } catch (err) {
        this.error(`${err.message}`);
      }
    },
    getAllStatesByCountryCode() {
      this.states = State.getStatesOfCountry(this.address.country);
    },
    getAllCitiesOfState() {
      this.cities = City.getCitiesOfState(
        this.address.country,
        this.address.state
      );
    },
  },
  watch: {
    "this.address.country"() {},
  },
  created() {
    this.getAllStatesByCountryCode();
  },
};
</script>

<style></style>
