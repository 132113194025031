<template>
  <vee-form
    :validation-schema="validationSchema"
    @submit="handleEditCategoryFormDetails"
  >
    <div class="space-y-6 bg-white px-4 py-5">
      <div class="sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-1 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6">
                <label
                  for="categoryId"
                  class="block text-sm font-medium text-gray-700"
                  >Category Id</label
                >
                <vee-field
                  type="text"
                  name="categoryId"
                  id="categoryId"
                  v-model="category.categoryId"
                  readonly
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="categoryId"
                  class="text-xs text-red-600"
                  id="errorMessageCategoryName"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="categoryName"
                  class="block text-sm font-medium text-gray-700"
                  >Category Name</label
                >
                <vee-field
                  type="text"
                  name="categoryName"
                  id="categoryName"
                  v-model="category.categoryName"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="categoryName"
                  class="text-xs text-red-600"
                  id="errorMessageCategoryName"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="categoryLogoURL"
                  class="block text-sm font-medium text-gray-700"
                  >Category Logo URL</label
                >
                <vee-field
                  type="text"
                  name="categoryLogoURL"
                  id="categoryLogoURL"
                  v-model="category.categoryLogoURL"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="categoryLogoURL"
                  class="text-xs text-red-600"
                  id="categoryLogoURL"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="updatedBy"
                  class="block text-sm font-medium text-gray-700"
                  >Updated By</label
                >
                <vee-field
                  type="text"
                  name="updatedBy"
                  id="updatedBy"
                  readonly
                  v-model="category.updatedBy"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="updatedAt"
                  class="block text-sm font-medium text-gray-700"
                  >Updated At</label
                >
                <input
                  type="text"
                  name="updatedAt"
                  id="updatedAt"
                  readonly
                  :value="
                    category.updatedAt
                      ? moment(category.updatedAt.toDate()).format(
                          `MMMM Do YYYY, h:mm:ss a`
                        )
                      : ''
                  "
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="createdBy"
                  class="block text-sm font-medium text-gray-700"
                  >Created By</label
                >
                <vee-field
                  type="text"
                  name="createdBy"
                  id="createdBy"
                  readonly
                  v-model="category.createdBy"
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div class="col-span-6">
                <label
                  for="createdAt"
                  class="block text-sm font-medium text-gray-700"
                  >Created At</label
                >
                <input
                  type="text"
                  name="createdAt"
                  id="createdAt"
                  readonly
                  :value="
                    category.createdAt
                      ? moment(category.createdAt.toDate()).format(
                          `MMMM Do YYYY, h:mm:ss a`
                        )
                      : ''
                  "
                  autocomplete="family-name"
                  class="mt-1 border focus:ring-indigo-500 py-2 px-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <button
          type="submit"
          id="saveCategoryBtn"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Update
        </button>
        <button
          type="button"
          @click.prevent="$router.push('/categories')"
          class="bg-white ml-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </div>
  </vee-form>
</template>

<script>
import { useToast } from "vue-toastification";
import useAuthStore from "@/store/auth.store";
import useCategoryStore from "@/store/categories.store";
import { mapState, mapActions } from "pinia";
import moment from "moment";
let toast = useToast();
export default {
  data() {
    return {
      validationSchema: {
        categoryName: "required|min:3|max:50",
        categoryLogoURL: "required|min:2|url",
      },
      moment,
      category: {
        categoryId: null,
        categoryLogoURL: "",
        categoryName: "",
        createdBy: null,
        updatedBy: null,
        createdAt: null,
        updatedAt: null,
      },
    };
  },
  methods: {
    ...mapActions(useCategoryStore, [
      "getACategoryByCategoryId",
      "getAllSavedCategoriesAction",
      "updatedCategoryDetailsByCategoryId",
      "mapAllCategoriesInOneListAction",
    ]),
    async handleEditCategoryFormDetails() {
      try {
        this.category.updatedAt = new Date();
        this.category.updatedBy =
          this.userdetails && this.userdetails.userId
            ? this.userdetails.userId
            : null;
        await this.updatedCategoryDetailsByCategoryId(
          this.category.categoryId,
          this.category
        );
        toast.success(
          `Successfully Updated Details by category name ${this.category.categoryName}`
        );
        this.$router.push("/categories");
      } catch (e) {
        toast.error(e.messsage);
      }
    },
  },
  computed: {
    ...mapState(useCategoryStore, ["categories", "categoriesList"]),
    ...mapState(useAuthStore, ["userdetails"]),
  },
  async created() {
    try {
      let categoryId = this.$route.params.categoryId;
      let category = await this.getACategoryByCategoryId(categoryId);
      this.category = { categoryId: categoryId, ...category.data() };
    } catch (error) {}
  },
  watch: {},
};
</script>

<style></style>
